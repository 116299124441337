/*
 * This component will list down all the disputes
 */
import { useState } from 'react';
import moment from 'moment';
import { Wrapper, DisputeListItem } from './disputes.styled';
import Loader from 'components/Loader';
import BackButton from 'components/ui/BackButton';
import { StatusBadge } from 'components/styled/Badges';
import NoDataFound from 'components/ui/NoDataFound';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import PaginationComponent from 'components/ui/Pagination';
import useDisputes from './hooks/useDisputes';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import DisputeDetails from './DisputeDetails';
import { convertToTitleCase } from 'helpers/utils/misc';

const DISPUTE_STATUSES = {
  closed: {
    color: 'green',
    label: 'Closed',
  },
  opened: {
    color: 'pinkTint',
    label: 'Open',
  },
};

const RECORDS_PER_PAGE = 10;

const Disputes = () => {
  useStartPageFromTop();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [detailsModalState, setDetailsModalState] = useState<{
    show: boolean;
    disputeId: string;
  }>({
    show: false,
    disputeId: '',
  });

  const { data, isLoading, isRefetching, totalResults } = useDisputes({
    page: currentPage,
    limit: RECORDS_PER_PAGE,
  });

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data */
    setCurrentPage(page?.selected + 1);
  };

  const showDisputeDetails = (disputeId: string) => () => {
    setDetailsModalState({
      show: true,
      disputeId,
    });
  };

  const onCloseDetailsModal = () => {
    setDetailsModalState({
      show: false,
      disputeId: '',
    });
  };

  return (
    <Wrapper className="mt-5 content-hfill">
      {/* Back button */}
      <BackButton />

      {/* Page Title */}

      <h1 className="title text-center fw-400">Dispute History</h1>

      {/* Disputes list */}
      {isLoading && <Loader />}

      {!isLoading && !isRefetching && data?.length == 0 && (
        <NoDataFound className="py-5" />
      )}

      {!isLoading &&
        data?.length > 0 &&
        data.map((item: any) => (
          <DisputeListItem
            key={item?.dispute_id}
            className="d-flex justify-content-between flex-wrap gap-3 pointer"
            onClick={showDisputeDetails(item?.dispute_id)}
          >
            <div className="dispute-content">
              <div className="d-flex dispute-row flex-wrap">
                <div className="label fs-20 fw-400">Project Name:</div>
                <div className="value fs-20 fw-400">
                  {convertToTitleCase(item?.job_title)}
                </div>
              </div>
              <div className="d-flex mt-2 dispute-row flex-wrap">
                <div className="label fs-20 fw-400">Milestone:</div>
                <div className="value fs-20 fw-400">
                  <StyledHtmlText
                    htmlString={item?.milestone?.title}
                    id={`dispute_${item?.milestone?.title}`}
                    needToBeShorten={true}
                  />
                </div>
              </div>
              <div className="d-flex mt-2 dispute-row flex-wrap">
                <div className="label fs-20 fw-400">Reason:</div>
                <div className="value fs-20 fw-400">
                  <StyledHtmlText
                    htmlString={item?.description}
                    id={`dispute_${item.dispute_id}`}
                    needToBeShorten={true}
                  />
                </div>
              </div>
              <div className="d-flex mt-2 dispute-row flex-wrap">
                <div className="label fs-20 fw-400">Submitted By:</div>
                <div className="value fs-20 fw-400 text-capitalize">
                  {item?.submitted_by === 'CLIENT'
                    ? `${item?.clientdata?.first_name} ${item?.clientdata?.last_name}`
                    : `${item?.freelancerdata?.first_name} ${item?.freelancerdata?.last_name}`}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-md-end">
              <div>
                <StatusBadge
                  color={DISPUTE_STATUSES[item?.dispute_status].color}
                >
                  {DISPUTE_STATUSES[item?.dispute_status].label}
                </StatusBadge>
              </div>
              <div className="created-date fs-20 fw-400 mt-4">
                Submitted On:{' '}
                {item.date_created &&
                  moment(item.date_created).format('MMM DD, YYYY')}
              </div>
              <div className="created-date fs-20 fw-400 mt-2">
                Closed On:{' '}
                {item.date_modified &&
                  moment(item.date_modified).format('MMM DD, YYYY')}
              </div>
            </div>
          </DisputeListItem>
        ))}

      {/* Pagination */}
      {data?.length > 0 && (
        <div className="d-flex justify-content-center mt-3 align-items-center">
          <PaginationComponent
            total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        </div>
      )}

      <DisputeDetails
        show={detailsModalState.show}
        dispute_id={detailsModalState.disputeId}
        onCloseModal={onCloseDetailsModal}
      />
    </Wrapper>
  );
};

export default Disputes;
