/*
 * This is the main component of this route
 */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Wrapper } from './offer-details.styled';
import Loader from 'components/Loader';
import BackButton from 'components/ui/BackButton';
import OfferDetailsBanner from './OfferDetailsBanner';
import OfferOtherDetails from './OfferOtherDetails';
import { getJobDetails } from 'helpers/http/jobs';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { archiveUnarchiveProposal } from 'helpers/http/proposals';

const OfferDetails = () => {
  useStartPageFromTop();
  const { id } = useParams<{ id: string }>();
  const [isProposalSubmitted, setIsProposalSubmitted] =
    useState<boolean>(false);

  const onProposalSubmitted = () => {
    setIsProposalSubmitted(true);
  };

  /* This will fetch the Offer details */
  const { data, isLoading } = useQuery(
    ['job-details', id],
    () =>
      getJobDetails(id).catch((err) => {
        throw err;
      }),
    { enabled: !!id }
  );

  useEffect(() => {
    if (data?.data?.invite_status === 'pending') {
      archiveUnarchiveProposal(data?.data?.job_post_id, false, 'read').then(
        () => { }
      );
    }
  }, [data]);

  if (!isLoading && data?.data?.status === 'active')
    return (
      <Wrapper className="my-5 content-hfill">
        <BackButton route="/dashboard" />
        <div className="text-center mt-5">
          <p className="fs-18">
            The client has accepted another freelancer's proposal for this
            project.
          </p>
        </div>
      </Wrapper>
    );

  return (
    <>
      <Wrapper className="my-5 content-hfill">
        {/* Back button header */}
        <BackButton />

        {isLoading ? (
          <Loader />
        ) : (
          data?.data && (
            <>
              {/* Offer details banner */}
              <OfferDetailsBanner
                data={data?.data}
                updateProposalSubmitted={onProposalSubmitted}
              />

              {/* Offer other details */}
              <OfferOtherDetails
                data={data?.data}
                isProposalSubmitted={isProposalSubmitted}
              />
            </>
          )
        )}
      </Wrapper>
    </>
  );
};

export default OfferDetails;
