import { Navigate } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/auth-context';
import { getToken } from 'helpers/services/auth';

function AuthRoute({ children }: { children: JSX.Element }) {
  const token = getToken();
  const auth = useAuth();

  if (auth?.user && token) {
    return <Navigate to="/search" />;
  }
  return children;
}

export default AuthRoute;
