/*
 * This is the main component that lists all the components of jobs list - client side
 */
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import ListingFooter from './ListingFooter';
import { ListingItem } from './listings.styled';
import SelectJobModal from 'components/invite-flow-modals/SelectJobModal';
import InviteFreelancerMessageModal from 'components/invite-flow-modals/InviteFreelancerMessageModal';
import NoDataFound from 'components/ui/NoDataFound';
import StatusAndDateSection from './StatusAndDateSection';
import { inviteFreelancer } from 'helpers/http/jobs';
import { convertToTitleCase } from 'helpers/utils/misc';
import { Dropdown } from 'react-bootstrap';
import ProposalExistsModal from 'components/invite-flow-modals/ProposalExistsModa';
import { getJobExpirationInDays } from 'helpers/utils/helper';

const closeStatuses = {
  All: 'all',
  Closed: 'closed',
  Closed_before_hiring_freelancer: 'deleted',
};

interface Props {
  data: any;
  listingType: string;
  toggleReset: string;
  sortFilter: (value: string) => void;
}

const Listings = ({ data, listingType, sortFilter, toggleReset }: Props) => {
  const [selectedFreelancer, setSelectedFreelancer] = useState<any>(null);
  const [showJobsModal, setShowJobsModal] = useState<boolean>(false);
  const [proposalExistModal, setProposalExistModal] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<string>('');
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);
  const [showInviteMessageModal, setShowInviteMessageModal] = useState<boolean>(false);
  const [toggleName, setToggleName] = useState('Filter by');

  const onInviteClick = (userData: any) => () => {
    // This will open the select jobs modal, whem invite button is clicked form the closed job list

    setSelectedFreelancer(userData);
    toggleJobsModal();
  };

  const toggleJobsModal = () => {
    // This will toggle select jobs modal
    setShowJobsModal(!showJobsModal);
  };

  const toggleInviteMessageModal = () => {
    // This will toggle invite message modal

    if (showInviteMessageModal) {
      setSelectedFreelancer(null);
      setSelectedJobId('');
    }
    setShowInviteMessageModal(!showInviteMessageModal);
  };

  const onSelectJobAndContinue = (jobId, proposalExists: boolean) => {
    // When a job is selected, on continue this will be called and it will open the invite message modal
    toggleJobsModal();
    setSelectedJobId(jobId);
    if (!proposalExists) toggleInviteMessageModal();
    else setProposalExistModal(true);
  };

  const onInvite = (msg: string) => {
    // Invite api call

    const body: any = {
      job_post_id: selectedJobId,
      freelancer_user_id: [selectedFreelancer?.user_id],
    };
    setSendingInvite(true);
    if (msg !== '') {
      body.message = msg;
    }
    inviteFreelancer(body)
      .then((res) => {
        setSendingInvite(false);
        if (res.message === 'PROPOSAL_EXIST') {
          toggleInviteMessageModal();
          setProposalExistModal(true);
          setSelectedJobId(body.job_post_id);
          return;
        }
        if (res.status) {
          if (res.alreadyInvited.includes(selectedFreelancer?.user_id)) {
            toast.success('Freelancer is already invited');
          } else {
            toast.success(
              `Invitation to ${selectedFreelancer?.first_name + ' ' + selectedFreelancer?.last_name} sent successfully!`
            );
          }
          toggleInviteMessageModal();
          setSelectedJobId('');
        } else {
          toast.error(res?.message ? res?.message : 'Invitation not sent successfully!');
        }
      })
      .catch(() => {
        setSendingInvite(false);
      });
  };

  const toggleResetValue = useMemo(() => {
    switch (toggleReset) {
      case 'closed':
        return 'Closed';
      case 'deleted':
        return 'Closed before Hiring Freelancer';
      default:
        return toggleReset;
    }
  }, [toggleReset]);

  const filterJobProposals = (status: string) => () => {
    sortFilter(closeStatuses[status]);
    setToggleName(closeStatuses[status]);
  };

  return (
    <div>
      {listingType === 'closed' && (
        <>
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              {toggleResetValue ?? toggleName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={filterJobProposals('All')}>All</Dropdown.Item>
              <Dropdown.Item onClick={filterJobProposals('Closed')}>Closed</Dropdown.Item>
              <Dropdown.Item onClick={filterJobProposals('Closed_before_hiring_freelancer')}>
                Closed before Hiring Freelancer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <br />
        </>
      )}
      {/* Listings of Jobs */}
      {data && data?.length > 0 ? (
        data?.map((item: any) => {
          const expirationDays = getJobExpirationInDays(item);

          return (
            <>
              <ListingItem
                key={item.job_post_id}
                className="pointer d-flex flex-column flex-lg-row align-lg-items-center gap-3 justify-content-between no-hover-effect"
                to={
                  ['prospects', 'deleted'].includes(item?.status)
                    ? `/client-job-details/${item.job_post_id}/applicants`
                    : `/client-job-details/${item.job_post_id}`
                }
              >
                {/* Left section of the Job card */}

                <div className="job-item-content flex-1">
                  <div className="title fs-24 fw-400">{convertToTitleCase(item.job_title)}</div>
                  <ListingFooter item={item} listingType={listingType} />
                </div>

                {/* Right section of the Job card */}

                <div>
                  {item.status === 'prospects' && <div className="text-end mb-3 text-danger">{expirationDays}</div>}
                  <StatusAndDateSection item={item} onInvite={onInviteClick(item?.userdata)} />
                </div>
              </ListingItem>
            </>
          );
        })
      ) : (
        <NoDataFound className="py-5" />
      )}

      {/* Select Job modal */}

      <SelectJobModal
        show={showJobsModal}
        toggle={toggleJobsModal}
        onNext={onSelectJobAndContinue}
        freelancerName={selectedFreelancer?.first_name + ' ' + selectedFreelancer?.last_name}
        freelancerId={selectedFreelancer?.user_id}
      />

      {/* Invite message modal */}

      <InviteFreelancerMessageModal
        show={showInviteMessageModal}
        toggle={toggleInviteMessageModal}
        freelancerName={selectedFreelancer?.first_name + ' ' + selectedFreelancer?.last_name}
        onInvite={onInvite}
        loading={sendingInvite}
      />

      {/* Proposal Exists Modal */}
      <ProposalExistsModal
        job_post_id={selectedJobId}
        show={proposalExistModal}
        toggle={() => {
          setSelectedJobId('');
          setProposalExistModal((prev) => !prev);
        }}
      />
    </div>
  );
};

export default Listings;
