/*
 * This is the main component of this file - Client Dashboard
 */
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { StyledButton } from 'components/forms/Buttons';
import Tooltip from 'components/ui/Tooltip';
import PageTitle from 'components/styled/PageTitle';
import { Wrapper } from './client-dashboard.styled';
import DashboardStats from './dashboard-stats/DashboardStats';
import Proposals from './proposals';
import Jobs from './jobs';
import Freelancers from './freelancers';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { useAuth } from 'helpers/contexts/auth-context';
import { useIsAllowedToPostProject } from 'helpers/hooks/useIsAllowedToPostProject';

const ClientDashboard = () => {
  useStartPageFromTop();

  const { isAllowedToPostProject } = useIsAllowedToPostProject();

  const { user } = useAuth();

  return (
    <Wrapper className="m-auto my-5">
      {/* Title and post a job button */}
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
        <PageTitle>{user?.first_name}’s Dashboard</PageTitle>

        {!isAllowedToPostProject ? (
          <Tooltip
            customTrigger={
              <StyledButton className="btn-primary" disabled>
                Post New Project
              </StyledButton>
            }
          >
            <p>
              In order to post a project, add a credit card or verified bank
              account to your profile.
            </p>
            <Link to="/client/account/payments">
              <u>Update my profile</u>
            </Link>
          </Tooltip>
        ) : (
          <Link to="/post-new-job">
            <StyledButton className="btn-primary">
              Post New Project
            </StyledButton>
          </Link>
        )}
      </div>

      {/* Dashboard Stats */}

      {/* Dashboard stats */}
      <DashboardStats />

      <div className="jobs-and-proposals">
        {/* Jobs and proposals */}
        <Row>
          <Col md={6} className="jobs-proposal-col">
            <Jobs />
          </Col>
          <Col md={6} className="jobs-proposal-col">
            <Proposals />
          </Col>
        </Row>

        {/* Freelancers */}
        <Row>
          <Col>
            <Freelancers />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default ClientDashboard;
