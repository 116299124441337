import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import cns from 'classnames';
import { useNavigate, Link } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import ReactOtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledButton } from 'components/forms/Buttons';
import AuthLayout from 'components/Layout/AuthLayout';
import { LimitedH2 } from 'components/styled/Auth.styled';
import { useAuth } from 'helpers/contexts/auth-context';
import LoadingButtons from 'components/LoadingButtons';
import ErrorMessage from 'components/ui/ErrorMessage';
import { apiClient } from 'helpers/http';
import { showErr, showMsg } from 'helpers/utils/misc';
import auth from 'helpers/http/auth';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';

const Wrapper = styled.div`
  .otp-input {
    input {
      border: 0 !important;
      outline: 0 !important;
      background: ${(props) => props.theme.colors.lightGray};
      font-family: ${(props) => props.theme.font.primary};
      font-size: 1.5rem;
    }
  }
`;

YupPassword(yup);

const passwordError =
  'Every password must include at least: 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol, and at least 8 characters';

export default function ResetPassword() {
  useStartPageFromTop();

  const [isPasswordPreview, setIsPasswordPreview] = React.useState(false);
  const togglePasswordPreview = () => setIsPasswordPreview(!isPasswordPreview);

  const { isLoading, user, twoFactor } = useAuth();
  const [otpId, setOtpId] = React.useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');

  const onChange = (value) => {
    setOtp(value);
  };
  const navigate = useNavigate();

  const schema = yup
    .object({
      password: yup
        .string()
        .required('Password is required.')
        .min(8, passwordError)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*-?])[A-Za-z\d#$@!%&*-?]{8,30}$/,
          passwordError
        ),
      confirm: yup
        .string()
        .required('Password is required.')
        .oneOf([yup.ref('password'), null], "Passwords don't match."),
    })
    .required();

  useEffect(() => {
    if (!user?.email_id) {
      navigate('/forgot-password');
      return;
    }
  });

  const handleOTP = (e: any) => {
    e.preventDefault();
    if (otp === '') {
      showErr('Please enter a valid OTP');
      return;
    }
    const formdata = {
      action: 'verify_otp',
      email_id: user?.email_id,
      user_otp: otp,
      type: 'forgot_password',
    };

    setLoading(true);

    apiClient
      .post('/auth/otp', formdata)
      .then((res) => {
        setLoading(false);
        if (!res.data.status) {
          showErr(res.data.message);
          return;
        }
        showMsg(res.data.message);
        setOtpId(res.data?.data?.otp_id);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = ({ password }: { password: string }) => {
    const payload = {
      email_id: user?.email_id,
      otp_id: otpId,
      new_password: password,
    };
    setLoading(true);
    toast.promise(auth.resetPassword(payload), {
      loading: 'Resetting password...',
      success: (res) => {
        setLoading(false);
        navigate('/login');
        return res.message;
      },
      error: (err) => {
        setLoading(false);
        return err?.response?.data?.message || err.toString() || 'Error';
      },
    });
  };

  const [timer, setTimer] = React.useState(30);

  const timeOutCallback = React.useCallback(() => {
    setTimer((currTimer) => currTimer - 1);
  }, []);

  useEffect(() => {
    let otpTimer;
    if (timer > 0) {
      otpTimer = setTimeout(timeOutCallback, 1000);
    }

    return () => {
      clearTimeout(otpTimer);
    };
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  const onResend = () => {
    const formdata = {
      action: 'resend_otp',
      email_id: user.email_id,
      type: 'forgot_password',
    };
    twoFactor(formdata, resetTimer);
  };

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  if (otpId) {
    return (
      <AuthLayout center showNavigationHeader>
        <h1>Reset Password</h1>
        <LimitedH2>
          Your OTP is verified. Please set your new password.
        </LimitedH2>

        <Form className="mt-4 px-2" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="Submit a new password"
              className="password-input"
            >
              <span className="pointer" onClick={togglePasswordPreview}>
                <Eye
                  className={cns('input-icon', { active: isPasswordPreview })}
                />
              </span>
              <Form.Control
                type={!isPasswordPreview ? 'password' : 'text'}
                placeholder="Submit a new password"
                {...register('password')}
              />
            </FloatingLabel>
            <ErrorMessage className="text-start my-2">
              {errors.password?.message}
            </ErrorMessage>
          </div>
          <div className="mt-4">
            <FloatingLabel
              controlId="floatingInput"
              label="Confirm new password"
              className="password-input"
            >
              <span className="pointer" onClick={togglePasswordPreview}>
                <Eye
                  className={cns('input-icon', {
                    active: isPasswordPreview,
                  })}
                />
              </span>
              <Form.Control
                type={!isPasswordPreview ? 'password' : 'text'}
                placeholder="Confirm new password"
                {...register('confirm')}
              />
            </FloatingLabel>
            <ErrorMessage className="text-start my-2">
              {errors.confirm?.message}
            </ErrorMessage>
          </div>
          <StyledButton
            className="mt-4"
            width={200}
            height={56}
            type="submit"
            padding="0"
            disabled={loading}
          >
            {loading ? <LoadingButtons /> : 'Reset'}
          </StyledButton>
          <h4 className="align-self-center mt-4">
            <Link to="/login" className="yellow-link">
              Back to Login
            </Link>
          </h4>
        </Form>
      </AuthLayout>
    );
  }
  return (
    <AuthLayout center>
      <h1>Enter Code</h1>
      <h2>Enter 6-digit code sent to ‘{user?.email_id}’</h2>

      <Form
        className="mt-4"
        // onChange={handleAutoNext}
        onSubmit={handleOTP}
        // onKeyDown={onKeydown}
      >
        <Wrapper className="d-flex justify-content-center">
          <ReactOtpInput
            value={otp}
            onChange={onChange}
            separator={<span style={{ color: '#909090' }}>-</span>}
            numInputs={6}
            className="otp-input"
            // containerStyle="flex flex-row justify-center mt-8 md:gap-3 gap-2"
            inputStyle={{
              maxWidth: '3.5rem',
              width: '100%',
              height: '3.5rem',
              borderRadius: 7,
              margin: 8,
            }}
            isInputNum={true}
          />
        </Wrapper>

        {timer > 0 ? (
          <h4 className="mt-5 d-flex align-items-center justify-content-center">
            You can resend a new OTP in&nbsp;
            <span className="fw-700">00:{timer > 9 ? timer : `0${timer}`}</span>
          </h4>
        ) : (
          <h4 className="mt-4 d-flex align-items-center justify-content-center g-1">
            Didn't receive code?{' '}
            <StyledButton
              onClick={onResend}
              variant="link"
              className="m-0 p-0"
              padding="0"
              disabled={isLoading}
            >
              <div className="yellow-link">Resend</div>
            </StyledButton>
          </h4>
        )}

        <StyledButton
          className="mt-3"
          type="submit"
          disabled={isLoading || loading}
          width={200}
          padding="0"
          height={56}
        >
          {isLoading || loading ? <LoadingButtons /> : 'Submit'}
        </StyledButton>
        <h4 className="align-self-center mt-4">
          <Link to="/login" className="yellow-link">
            Back to Login
          </Link>
        </h4>
      </Form>
    </AuthLayout>
  );
}
