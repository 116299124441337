import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FooterMain, FooterWrapper, GradientBorder, BottomLinks } from './styled';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import FreelancerLaunchFooter from './FooterFreelancerLaunch';
import { PRIVACY_POLICY_LINK, COOKIES_POLICY_LINK } from 'helpers/const/CONFIG';
import packageJson from '../../../package.json';

const CompanyLinks = [
  {
    name: 'About Us',
    path: '/about-us',
  },
];

const ResourcesLink = [
  {
    name: 'Contact Us',
    path: '/customer-support',
  },
];

const FindingUsLinks = [
  {
    name: 'Finding Us',
    path: '/finding-us',
  },
];

const isForFreelancerLaunch = false;

function SiteFooter() {
  /* This means for freelancer launch, FreelancerLaunchFooter will be served as footer
   * and for main website the below should be displayed for the actual footer  */
  if (isForFreelancerLaunch) return <FreelancerLaunchFooter />;
  return (
    <FooterMain>
      <FooterWrapper showTopBorder={!window.location.pathname.includes('home')}>
        {
          <Container className="content d-flex flex-column flex-md-row justify-content-between w-100">
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-5">
              <div>
                {CompanyLinks.map((item: any, index: number) => (
                  <Link to={item.path} key={index}>
                    <span role="button" className="nav-link px-0" tabIndex={0}>
                      {item.name}
                    </span>
                  </Link>
                ))}
              </div>
              <div>
                {ResourcesLink.map((item: any, index: number) => (
                  <Link to={item.path} key={index}>
                    <div role="button" className="nav-link px-0" tabIndex={0}>
                      {item.name}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </Container>
        }
        <GradientBorder />
        <BottomLinks className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center justify-content-center gap-4">
            <Link to="/terms-of-service" className="bottom-link">
              Terms of Service
            </Link>
            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer" className="bottom-link">
              Privacy Policy
            </a>
            <a href={COOKIES_POLICY_LINK} target="_blank" rel="noreferrer" className="bottom-link">
              Cookies
            </a>
            {FindingUsLinks.map((item: any, index: number) => (
              <a href={item.path} target="_blank" key={index} rel="noreferrer" className="bottom-link">
                {item.name}
              </a>
            ))}
          </div>
          <div className="bottom-link mx-0">
            {new Date().getFullYear()} ZehMizeh {packageJson.version}. All Rights Reserved.
          </div>
        </BottomLinks>
      </FooterWrapper>
    </FooterMain>
  );
}

export default SiteFooter;
