import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  h1.title {
    font-size: 2rem;
    font-weight: 700;
  }
  .heading {
    font-size: 1.25rem;
    font-weight: 700;
  }
  .reg-link {
    color: ${(props) => props.theme.colors.yellow};
  }
  .img-wrapper {
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 1rem;
  }
  .img {
    max-width: 50%;
  }
`;

const HeaderWrapper = styled.div`
  padding: 1rem 1rem 5rem 1rem;
  min-height: 70vh;
`;

function AboutUs() {
  useStartPageFromTop();
  return (
    <HeaderWrapper>
      <Wrapper>
        <div className="mt-5">
          <h1 className="title mb-5">About Us</h1>
          <div className="d-flex justify-content-center img-wrapper">
            <img src="/images/about-us.svg" className="img" alt="about-us" />
          </div>
          <div className="mt-5">
            <h2 className="heading">About Us</h2>
            <p className="fs-18 fw-400">
              When a kid says he wants to be an astronaut, he’s thinking about
              soaring through the stars… not fundraising for rocket fuel and
              managing the spaceship’s social media.
            </p>
            <p className="fs-18 fw-400">
              We all want to love our work, but even people with dream jobs have
              tasks they don’t want to do. If only we could have some
              specialized staff who <i>want </i>
              to do those tasks, who are experts in those pesky projects that
              only slow us down.
            </p>
            <p className="fs-18 fw-400">
              What if we were to tell you that those experts are not only out
              there, but they’re just waiting for you to find them?
            </p>
          </div>
        </div>
        <div className="mt-4">
          <h3 className="heading">The World of Freelancing</h3>
          <p className="fs-18 fw-400">
            There’s a whole world of professionals who make their own hours, who
            don’t have a set office, who sell their services to different
            clients every month.
          </p>
          <p className="fs-18 fw-400">
            They’re called “freelancers” and their numbers are growing all the
            time. The modern world allows these incredibly talented workers to
            take their services to whoever wants them, getting paid on a
            project-by-project basis.
          </p>
          <p className="fs-18 fw-400">
            While this style of work can be very rewarding, there is one
            consistent challenge freelancers face - finding the next client.
            Many freelancers do specialized work, which makes project
            opportunities less common and harder to find.
          </p>
        </div>
        <div className="mt-4">
          <h2 className="heading">Enter ZehMizeh</h2>
          <p className="fs-18 fw-400">
            This website is designed to make that connection, between the Jewish
            professional looking for specialists and the Jewish specialists
            looking for their next gig.
          </p>
          <p className="fs-18 fw-400">
            When you need an expert for your business, you shouldn’t have to ask
            around and hope your cousin’s recommendation is good. Now, you can
            find top-rated experts from around the globe, in all manner of
            industries!
          </p>
          <p className="fs-18 fw-400">
            And for the Jewish freelancers out there - you shouldn’t have to
            exhaust yourself looking for clients. You <i>know</i> there are
            amazing opportunities out there, if only you could find them. How
            much could your business grow if those projects were delivered right
            to your laptop? How would your career change if you could just
            focus, not on promoting yourself, but on doing what you’re great at?
          </p>
        </div>
        <div className="mt-4">
          <h3 className="heading">So are you ready?</h3>
          <p className="fs-18 fw-400">
            You could get your projects done the old way: ask the receptionist
            who did a single WordPress class to build your website, or ask the
            rabbi’s son who likes writing if he can do your ad copy.
          </p>
          <p className="fs-18 fw-400">
            But you shouldn’t have to settle when there are professionals
            waiting for you, professionals who love to do the projects you need
            to get done.
          </p>
        </div>
        <div className="mt-4">
          <div className="fs-20 fw-400">
            Join our growing community.&nbsp;
            <Link to="/register/employer" className="reg-link">
              Register as a client or freelancer - today!
            </Link>
          </div>
        </div>
      </Wrapper>
    </HeaderWrapper>
  );
}

export default AboutUs;
