import { Form } from 'react-bootstrap';

interface Props {
  label?: string;
  className?: string;
  toggle?: any;
  checked?: boolean;
  value?: string;
}
function Radio(props: Props) {
  const { label = '', className, toggle, checked, value } = props;
  return (
    <Form.Check
      type="radio"
      className={`fs-1rem ${className}`}
      label={label}
      checked={checked}
      onChange={toggle}
      value={value}
      {...props}
    />
  );
}

export default Radio;
