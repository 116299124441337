/*
 * This component is the main component of this route
 */

import { Row, Col } from 'react-bootstrap';
import DashboardStats from './DashboardStats';
import Proposals from './proposals';
import Jobs from './jobs';
import useDashboardStats from './use-dashboard-stats';
import { MainContainer, Wrapper } from './freelancerDashboard.styled';
import { ReactComponent as TopRatedIcon } from 'assets/icons/top-rated.svg';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { useAuth } from 'helpers/contexts/auth-context';
import PageTitle from 'components/styled/PageTitle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FreelancerDashboard = () => {
  useStartPageFromTop();
  const navigate = useNavigate();

  const { user } = useAuth();

  const { dashboardStats, isLoading } = useDashboardStats();

  // If freelancer's account isnt approved then default page should be profile page
  useEffect(() => {
    if (user?.is_account_approved === null || typeof user?.is_account_approved === 'undefined') {
      navigate('/freelancer/account/Profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.is_account_approved]);

  // useEffect(() => {
  //   statusHandler();
  // }, []);

  // const statusHandler = async () => {
  //   const resp = await getStripeAccountStatus();
  //   console.log(resp);
  // };
  return (
    <MainContainer>
      <Wrapper>
        {/* Title and top rated badge -
         *If user's average ratings is more than 4.5 then only top rated badge will be displayed */}
        <div
          className={`d-flex justify-content-between align-items-center ${
            dashboardStats?.ratings?.average <= 4.5 ? 'mt-5' : ''
          }`}
        >
          <PageTitle>{user?.first_name}’s Dashboard</PageTitle>
          {dashboardStats?.ratings?.average > 4.5 && (
            <div className="top-rated-badge d-flex flex-column">
              <div className="top-rated__label fs-sm fw-400">Top Rated</div>
              <TopRatedIcon />
            </div>
          )}
        </div>

        {/* Freelancer's stats like jobs completed, earnings etc.. */}

        <DashboardStats dashboardStats={dashboardStats} isLoading={isLoading} />

        {/* Proposal and jobs */}

        <div className="proposals-and-jobs">
          <Row>
            <Col lg={6} className="mb-5">
              <Proposals />
            </Col>
            <Col lg={6} className="mb-5">
              <Jobs />
            </Col>
          </Row>
        </div>
      </Wrapper>
    </MainContainer>
  );
};

export default FreelancerDashboard;
