import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabTitle, TabWrapper } from 'styles/TabStyle';
import { useQueryData } from 'helpers/hooks/useQueryData';
import { TViewFreelancerProfilePathParams } from 'helpers/types/pathParams.type';
import { VIEW_FREELANCER_PROFILE_TABS } from 'helpers/const/tabs';
import { queryKeys } from 'helpers/const/queryKeys';
import { IFreelancerDetails } from 'helpers/types/freelancer.type';

const Tabs = () => {
  const navigate = useNavigate();
  const { freelancerId, tabkey } = useParams<TViewFreelancerProfilePathParams>();

  const { data: freelancerData } = useQueryData<IFreelancerDetails>(queryKeys.getFreelancerDetails(freelancerId)) || {};
  const { data: portfolioData } =
    useQueryData<Record<string, unknown>[]>(['get-freelancer-portfolio', freelancerId]) || {};

  // const tabs = Object.values(VIEW_FREELANCER_PROFILE_TABS).filter((tab) => {
  //   // should show portfolio tab or not
  //   if (VIEW_FREELANCER_PROFILE_TABS.PORTFOLIO === tab) {
  //     return portfolioData && portfolioData?.length > 0;
  //   }
  //   // should show ratings tab or not
  //   if (VIEW_FREELANCER_PROFILE_TABS.RATINGS === tab) {
  //     return freelancerData?.completedJobDetail && freelancerData?.completedJobDetail?.length > 0;
  //   }
  //   return true;
  // });

  // if (tabs.findIndex((tab) => tab === tabkey) === -1) {
  //   navigate(`/freelancer/${freelancerId}/${VIEW_FREELANCER_PROFILE_TABS.PROFILE}`, { replace: true });
  //   return <></>;
  // }

  const tabs = Object.values(VIEW_FREELANCER_PROFILE_TABS);
  if (tabs.length === 1) {
    return <></>;
  }

  const scrollToView = (tab: string) => {
    const element = document.getElementById(`profile-${tab.toLocaleLowerCase()}`);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <TabWrapper className="tab-wrapper">
      <Tab>
        {tabs.map((tab) => (
          <TabTitle onClick={() => scrollToView(tab)} active={tabkey === tab} key={tab}>
            {tab}
          </TabTitle>
        ))}
      </Tab>
    </TabWrapper>
  );
};
export default Tabs;
