export const VIEW_FREELANCER_PROFILE_TABS = {
  PROFILE: 'Profile',
  PORTFOLIO: 'Portfolio',
  RATINGS: 'Ratings',
};

export const FREELANCER_PROFILE_TABS = {
  PROFILE: 'Profile',
  PORTFOLIO: 'Portfolio',
  RATINGS: 'Ratings',
  PAYMENT_DETAILS: 'Payment Details',
  ACCOUNT_SETTINGS: 'Account Settings',
};
