import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 1rem;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
  margin: auto;
  max-width: 1170px;
`;

export const ChatBoxWrapper = styled.div`
  height: 80vh;
  max-width: 900px;
  width: 90%;
  margin: auto;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ChatHeader = styled.div`
  width: 100%;
  min-height: 4rem;
  border-bottom: 1px solid #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  flex-wrap: wrap;
  p {
    font-weight: 600;
    font-size: 1.2rem;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    margin-bottom: 0;
    color: #696969;
  }
  .actions {
    display: flex;
    cursor: pointer;
    font-weight: 600;
    p {
      color: #ffb504;
      font-size: 1rem;
      padding: 0.3rem 1rem;
      border-radius: 4px;
      :hover {
        background: #ffc33547;
      }
    }
  }
`;

export const MessageWrapper = styled.div`
  width: 100%;
  background: lightyellow;
  flex-grow: 1;
  overflow: auto;
  padding: 0 2rem;
`;

export const ChatInputWrapper = styled.div`
  width: 100%;
  min-height: 5rem;
  background: #fff;
  border-top: 1px solid #c5c5c5;
  position: relative;
  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
    position: absolute;
    top: 50%;
    left: 95%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 0.2s;
    :hover {
      width: 28px;
      height: 28px;
    }
  }
  input {
    border: none;
    height: 100%;
    outline: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 0 2rem;
    font-size: 18px;
    padding-right: 5rem;
    :focus {
      border: 1px solid #737373;
      box-shadow: none;
    }
  }
`;
