import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevronDown.svg';

const TypeDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    color: inherit;
    &::after {
      display: none;
    }
  }
  .mainDropdown {
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    div {
      align-items: center;
      display: flex;
      padding: 5px;
      border: 1px solid #aaa;
      border-radius: 4px;
      background-color: white;
      // box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    }
  }
  .dropdown-menu {
    border: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    padding: 5px;
    border-radius: 0.5rem;
    .dropdown-item {
      margin-top: 0.5rem;
      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: transparent !important;
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;
const TypeDropdownToggle = styled.div``;

interface Props {
  sortTypes: any;
  sorting: any;
  setSorting: any;
}

const Sorting = ({ sortTypes, sorting, setSorting }: Props) => {
  const [findSorts] = sortTypes.filter(function (type) {
    return type.key === sorting;
  });
  return (
    <TypeDropdown>
      <Dropdown.Toggle as={TypeDropdownToggle} className="pointer">
        <div className="d-flex align-items-center mainDropdown">
          <span className="fs-16 mx-2"> Sort By :</span>
          <div>
            <span className="fs-16 mx-2">{findSorts.label}</span>
            <DownArrowIcon className="dropdown-arrow" />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {sortTypes.map((item: any) => (
          <Dropdown.Item key={item.key} className={`fs-16`} onClick={() => setSorting(item.key)}>
            <div>{item.label}</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </TypeDropdown>
  );
};

export default Sorting;
