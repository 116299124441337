import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import 'rc-tooltip/assets/bootstrap.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { appRoutes } from 'app-router/appRoutes';

// queryclient wrapper
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      suspense: false,
    },
    mutations: {
      retry: 1,
    },
  },
});

// useBlocker wasn't working in normal <BrowserRouter/> because data api doesn't work in that
// so converted <BrowserRouter/> to createBrowserRouter and converted existing routes to object using createRoutesFromElements
const router = createBrowserRouter(appRoutes());

// eslint-disable-next-line react/no-deprecated
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </QueryClientProvider>
    <Toaster
      containerStyle={{
        zIndex: '99999',
      }}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
