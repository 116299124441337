/*
 * This is the main component of template details page.
 * This imports all the components of template details page
 */

import { useParams } from 'react-router-dom';
import { Wrapper } from './templates-details.styled';
import BackButton from 'components/ui/BackButton';
import Loader from 'components/Loader';
import TemplateBanner from './TemplateBanner';
import QuickOptions from './QuickOptions';
import Details from './Details';
import useTemplate from './use-template';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';

const TemplateDetails = () => {
  useStartPageFromTop();
  const { id } = useParams<{ id: string }>();

  // This will load the details of the template
  const { details, isLoading } = useTemplate(id);

  return (
    <Wrapper className="mt-5 content-hfill">
      {/* Back button */}
      <BackButton route="/client/dashboard" />

      {/* Loader */}
      {isLoading && <Loader />}

      {!isLoading && details && (
        <div className="mt-4">
          {/* Banner */}
          <TemplateBanner data={details} />

          {/* Edit delete options */}
          <QuickOptions id={id} />

          {/* Template Details */}
          <Details data={details} />
        </div>
      )}
    </Wrapper>
  );
};

export default TemplateDetails;
