import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as IntercomIcon } from 'assets/icons/intercom.svg';

type Props = {
  adBlockDetected: boolean;
};

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      Please disable <strong>Ad Blocker</strong> and reload the page to access
      support
    </Popover.Body>
  </Popover>
);

const AdBlockePopOver = ({ adBlockDetected }: Props) => {
  return (
    adBlockDetected && (
      <OverlayTrigger trigger="click" placement="left" overlay={popover}>
        <div className="intercom">
          <IntercomIcon className="intercom-icon" />
        </div>
      </OverlayTrigger>
    )
  );
};

export default AdBlockePopOver;
