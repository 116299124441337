import PostJob from './PostJob';
import { PostJobContextProvider } from './context';

const Index = () => (
  <PostJobContextProvider>
    <PostJob />
  </PostJobContextProvider>
);

export default Index;
