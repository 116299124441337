import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevronDown.svg';
import styled from 'styled-components';

interface MenuItem {
  link: string;
  text: string;
}

interface User {
  user_image?: string;
}

interface Auth {
  user?: User;
  signout: () => void;
}

interface UserDropdownProps {
  displayUserName: () => string;
  profileDropdownMenuItem: MenuItem[];
  toggleNavbar: () => void;
  auth: Auth;
}

export default function UserDropdownComp({
  displayUserName,
  profileDropdownMenuItem,
  toggleNavbar,
  auth,
}: UserDropdownProps): ReactElement {
  return (
    <UserDropdown>
      <Dropdown.Toggle as={UserDropdownToggle}>
        <div className="d-flex align-items-center">
          <img
            src={auth?.user?.user_image || '/images/default_avatar.png'}
            className="profile-img"
            alt="profile-image"
          />
          <div className="loggedin-username fs-18 text-capitalize">{displayUserName()}</div>
          <DownArrowIcon className="dropdown-arrow" />
        </div>{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" onClick={toggleNavbar}>
        {profileDropdownMenuItem.map(({ link, text }) => {
          return (
            <Dropdown.Item key={`${text}-${link}`}>
              <Link to={link} className="d-flex menu-item">
                {text}
              </Link>
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item className="d-flex" onClick={auth.signout}>
          <div className="logout menu-item">Logout</div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </UserDropdown>
  );
}

const UserDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    color: inherit;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    border: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    border-radius: 0.5rem;
    .dropdown-item {
      padding: 0;
      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: transparent !important;
        color: ${({ theme }) => theme.colors.blue};
      }
      .logout {
        /* margin-top: 0.5rem; */
        color: ${({ theme }) => theme.statusColors?.darkPink?.color};
      }
    }
    .menu-item {
      padding: 10px 20px;
    }
  }
`;

const UserDropdownToggle = styled.div``;
