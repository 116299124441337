/*
 * This is the card that displays the details of Hired / Saved freelancer
 */
import { useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import BlurredImage from 'components/ui/BlurredImage';
import { transition } from 'styles/transitions';
import { toggleBookmarkUser } from 'helpers/http/search';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-yellow.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/saved.svg';
import { numberWithCommas } from 'helpers/utils/misc';

const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  .freelancer-context {
    padding: 1.25rem;
  }
  .designation {
    color: #999999;
    word-break: break-word;
  }
  .light-text {
    opacity: 0.5;
  }
  .budget {
    background-color: #fbf5e8;
    border-radius: 1rem;
    padding: 0.375rem 0.75rem;
    min-width: 4.5rem;
  }
  .details {
    margin-top: 0.75rem;
  }
  ${() => transition()};
`;

export const Bookmark = styled.div`
  height: 43px;
  width: 43px;
  border-radius: 2rem;
  background: ${(props) => props.theme.colors.yellow};
  color: #fff;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
`;

const WorkInProgressJobCard = ({
  data,
  activeTabKey,
  refetch,
}: {
  data: any;
  activeTabKey: string;
  refetch: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onBookmark = (e: any) => {
    // This will unsave the saved freelancer
    e.stopPropagation();
    setLoading(true);
    const promise = toggleBookmarkUser(data?._bm_user_id);
    toast.promise(promise, {
      loading: 'Please wait...',
      success: (res) => {
        setLoading(false);
        refetch();
        return res.message;
      },
      error: (err) => {
        setLoading(false);
        return err?.message || 'error';
      },
    });
  };

  const profileHandler = () =>
    navigate(
      `/freelancer/${
        activeTabKey === 'saved' ? data?._bm_user_id : data?._freelancer_user_id
      }`
    );

  return (
    <Wrapper
      className="pointer d-flex flex-wrap gap-4 mt-3 justify-content-between no-hover-effect"
      // to={`/freelancer/${
      //   activeTabKey === 'saved' ? data?._bm_user_id : data?._freelancer_user_id
      // }`}
      // onClick={goToFreelancerProfile}
    >
      <div
        className="d-flex gap-4 w-100 freelancer-context"
        onClick={() => profileHandler()}
      >
        {/* Profile picture */}
        <BlurredImage
          src={data?.user_image || '/images/default_avatar.png'}
          height="67px"
          width="67px"
          // allowToUnblur={false}
        />

        <div>
          {/* Name and designation */}
          <div className="fs-1rem fw-400 title text-capitalize">
            {data?.first_name} {data?.last_name}
          </div>
          <div className="fs-1rem fw-400 mt-1 designation capital-first-ltr">
            {data?.job_title}
          </div>

          {/* Hourly rate and ratings */}
          <div className="d-flex gap-2 align-items-center mt-2 flex-wrap">
            <div className="budget fs-1rem fw-400 d-flex align-items-center">
              <DollarCircleIcon className="me-1" />
              {data?.hourly_rate ? (
                <>
                  {numberWithCommas(data?.hourly_rate, 'USD')}{' '}
                  <span className="light-text">/hr</span>
                </>
              ) : (
                <span className="light-text">n/a</span>
              )}
            </div>

            <div className="budget fs-1rem fw-400 d-flex align-items-center gap-1">
              <StarIcon /> {data?.avg_rate?.toFixed(1)}
            </div>
          </div>
        </div>
      </div>

      {/* Saved Icon */}
      {activeTabKey == 'saved' && (
        <Bookmark
          className="d-flex justify-content-center align-items-center pointer"
          onClick={onBookmark}
        >
          {loading ? <Spinner animation="border" /> : <BookmarkIcon />}
        </Bookmark>
      )}
    </Wrapper>
  );
};

export default WorkInProgressJobCard;
