import { ZEHMIZEH_LINK } from 'helpers/const/CONFIG';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const FindingUs = () => {
  const Wrapper = styled(Container)`
    max-width: 770px;
    line-height: 1.5;
    letter-spacing: 0.25px;
    h1.title {
      font-size: 2rem;
      font-weight: 700;
    }
    .heading {
      font-size: 1.5rem;
      font-weight: 700;
    }
  `;

  const HeaderWrapper = styled.div`
    padding: 1rem;
    min-height: 70vh;
  `;
  return (
    <>
      <HeaderWrapper>
        <Wrapper>
          <div className="mt-5 pb-5">
            <h1 className="title mb-5">The Many Ways to Spell "ZehMiZeh"</h1>
            <p>
              Finding "ZehMiZeh" online can sometimes be a challenge due to the
              variety of ways people spell our name. To ensure everyone can
              reach us easily, we've compiled a list of the common variations
              you might encounter or use:
            </p>
            <ul>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zehmezeh</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zeh mezeh</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zemeze</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zemize</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zehmize</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zeh mize</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zeh mi zeh</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>ze mi ze</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>ze me ze</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zeh mizeh</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zemizeh</strong>
                </a>
              </li>
              <li className="mt-3">
                <a href={ZEHMIZEH_LINK} rel="noreferrer" target="_blank">
                  <strong>zemezeh</strong>
                </a>
              </li>
            </ul>
            <p>
              While "ZehMiZeh" is the correct way to spell our name, we
              understand that typos happen! No matter how you type it, we hope
              this guide helps you find us without any hassle. Whether you're
              looking for information, services, or just want to connect, we're
              just a few keystrokes away. Remember, no matter the spelling, it
              still means us!
            </p>
          </div>
        </Wrapper>
      </HeaderWrapper>
    </>
  );
};

export default FindingUs;
