import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { StyledButton } from 'components/forms/Buttons';
import GradientText from 'components/styled/GradientText';
import { Card } from 'components/styled/Auth.styled';
import { ReactComponent as CheckMark } from 'assets/icons/check-mark.svg';
import logo from 'assets/icons/logo.svg';

const Wrapper = styled.div`
  height: 100vh;
  .success-icon {
    background: #34a853;
    box-shadow: 0px 13px 26px -4px rgba(52, 168, 83, 0.31);
    width: 104px;
    height: 104px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    opacity: 1;
  }
`;

export default function Terms() {
  const [isAccepted, setIsAccepted] = React.useState(true);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!user?.email_id) {
  //     navigate('/register');
  //     return;
  //   }
  // });
  const onAccept = () => setIsAccepted(true);
  const onLeave = () => navigate('/');
  if (isAccepted) {
    return (
      <Wrapper className="d-flex justify-content-center align-items-center">
        <Card small={true}>
          <div className="text-center">
            <span className="success-icon">
              <CheckMark />
            </span>
            <h2 className="mt-4">
              Mazal tov - your account has been created successfully!
            </h2>
            <Link to={'/complete-profile'} state={{ fromRegister: true }}>
              <StyledButton className="mt-4" padding="0" height={56}>
                {/* {user.user_type == 'client'
                  ? 'Go to Profile'
                  : 'Start Setting Profile'} */}
                Continue to Set Profile
              </StyledButton>
            </Link>
          </div>
        </Card>
      </Wrapper>
    );
  }
  return (
    <Wrapper className="d-flex justify-content-center align-items-center">
      <Card small={true}>
        <div className="logo">
          <img src={logo} alt="logo" width={70} />
        </div>
        <h1>Accept Terms</h1>
        <p className="terms-description">
          You understand that it is against Jewish law and our{' '}
          <GradientText>terms and conditions</GradientText> to leave outside the
          ZehMizeh platform to acquire a freelancer whom you found on the
          platform.
        </p>
        <footer className="d-flex justify-content-end g-1 mt-4">
          <StyledButton
            padding="0 2rem"
            variant="outline-primary"
            height={56}
            onClick={onLeave}
          >
            Leave Platform
          </StyledButton>
          <StyledButton padding="0 2rem" height={56} onClick={onAccept}>
            Accept
          </StyledButton>
        </footer>
      </Card>
    </Wrapper>
  );
}
