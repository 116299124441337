import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { breakpoints } from 'helpers/hooks/useResponsive';

export const Wrapper = styled(Container)`
  max-width: 1170px;
  .title {
    font-size: 3.25rem;
    @media ${breakpoints.mobile} {
      font-size: 2rem;
    }
  }
  .back-btn-devider {
    color: ${(props) => props.theme.colors.gray6};
    border: 1px solid ${(props) => props.theme.colors.gray6};
  }
  .searchbox {
    height: 60px;
    background-color: ${(props) => props.theme.colors.white};
    border: none;
    box-shadow: 0px 6px 29px rgba(155, 155, 155, 0.09);
  }
`;
