/*
 * This is the banner of template details page
 */

import moment from 'moment';
import { BannerWrapper } from './templates-details.styled';
import { convertToTitleCase, showFormattedBudget } from 'helpers/utils/misc';

const TemplateBanner = ({ data }: any) => {
  return (
    <BannerWrapper>
      <div className="fs-24 fw-400">{convertToTitleCase(data?.job_title)}</div>

      {/* Date started */}
      <div className="d-flex align-items-center mt-3 flex-wrap details gap-3">
        <div className="fs-20 fw-400">
          <span className="label">Date Started:</span>&nbsp;
          {data?.date_created
            ? moment(data?.paid_date).format('MMM DD, YYYY')
            : ' -'}
        </div>

        {/* Divider */}
        <div className="divider mx-4 d-none d-md-block " />

        {/*
          Budget:
          This will display a budget based on budget type: if fixed then only amount will be displayed 
          and if type is hourly: it will display min and max rate,
          and if unsure then it could be either fixed amount or min | max 
        */}
        <div className="fs-20 fw-400">
          <span className="label">Budget:</span>&nbsp;
          {data?.budget?.isProposal
            ? 'Open To Proposals'
            : showFormattedBudget(data.budget)}
        </div>
      </div>
    </BannerWrapper>
  );
};

export default TemplateBanner;
