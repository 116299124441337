import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  FooterMain,
  FooterWrapper,
  GradientBorder,
  BottomLinks,
} from './styled';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

const CompanyLinks = [
  {
    name: 'About us',
    path: '/about-us',
  },
];

const ResourcesLink = [
  {
    name: 'Customer support',
    path: '/customer-support',
  },
];

function FreelancerLaunchFooter() {
  return (
    <FooterMain>
      <FooterWrapper>
        {
          <Container className="content d-flex justify-content-between w-100 flex-wrap">
            <div className="d-flex justify-content-between flex-wrap flex-1 gap-2">
              <div className="mt-2">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="d-flex align-items-center gap-5">
                {CompanyLinks.map((item: any, index: number) => (
                  <Link to={item.path} key={index}>
                    <a role="button" className="nav-link px-0" tabIndex={0}>
                      {item.name}
                    </a>
                  </Link>
                ))}
                {ResourcesLink.map((item: any, index: number) => (
                  <Link to={item.path} key={index}>
                    <div role="button" className="nav-link px-0" tabIndex={0}>
                      {item.name}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </Container>
        }
        <GradientBorder />
        <BottomLinks className="d-flex align-items-center justify-content-center flex-wrap gap-2">
          <div className="bottom-link mx-0">
            2022 ZehMizeh. All Rights Reserved.
          </div>
        </BottomLinks>
      </FooterWrapper>
    </FooterMain>
  );
}

export default FreelancerLaunchFooter;
