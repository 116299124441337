import React from 'react';

import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import SuspenseWrapper from './SuspenseWrapper';
import WebsiteLayout from 'components/Layout/PublicLayout';
import Page404 from 'components/Page404';
import Register from 'pages/authentication/register/index';
import TwoFactor from 'pages/authentication/TwoFactor';
import Login from 'pages/authentication/Login';
import PostJob from 'pages/post-job';
import Jobs from 'pages/jobs';
import ClientJobs from 'pages/client-jobs';
import Search from 'pages/search';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import RequireAuth from './RequireAuth';
import AuthTerms from 'pages/authentication/Terms';
import ResetPassword from 'pages/authentication/ResetPassword';
import ViewFreelancerProfile from 'pages/view-freelancer-profile';
import ClientProfile from 'pages/client-profile';
import FreelacerDashboard from 'pages/freelancer-dashboard';
import OfferDetails from 'pages/offer-details';
import FreelancerProfileSettings from 'pages/freelancer-profile-settings';
import ChangePassword from 'pages/change-password';
import Support from 'pages/support';
import Disputes from 'pages/disputes';
import Messaging from 'pages/messaging';
import Terms from 'pages/terms';
import Privacy from 'pages/privacy';
import Cookies from 'pages/cookies';
import AboutUs from 'pages/about-us';
import CompleteProfile from 'pages/complete-profile';
import CustomerSupport from 'pages/customer-support';
import AuthRoute from './AuthRoute';
import TemplateDetails from 'pages/template-details';
import ClientDashboard from 'pages/client-dashboard';
import Payments from 'pages/payments';
import Proposals from 'pages/proposals';
import InvoiceGenerater from 'pages/payments/InvoiceGenerater';
import PortfolioDetails from 'pages/portfolio-details';

import DocumentTitle from 'components/DocumentTitle';
import JobDetails from 'pages/job-details';
import ClientJobDetails from 'pages/client-job-details';
import ProposalDiscussion from 'pages/proposal-discussion';
import FindingUs from 'pages/finding-us';
import App from 'App';
import TalkJS from 'pages/talk-js';

const HomePage = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/home')
);

const publicRoutes = [
  {
    path: '/home',
    component: <HomePage />,
    title: 'ZehMizeh | The Jewish Freelancing Platform - Post projects, find talent, pay simply.',
    metaDescription:
      'Now hire the best talent in the world to get your project done and also find the best freelancing projects around the wold',
  },
  {
    path: '/404',
    component: <Page404 />,
  },
  {
    path: '/terms',
    component: <AuthTerms />,
    title: 'ZehMizeh | Terms and Conditions',
  },
  {
    path: '/terms-of-service',
    component: <Terms />,
    title: 'ZehMizeh | Register terms and conditions',
  },
  {
    path: '/privacy',
    component: <Privacy />,
    title: 'ZehMizeh | Privacy Policy',
  },
  {
    path: '/cookies',
    component: <Cookies />,
    title: 'ZehMizeh | Cookies',
  },
  {
    path: '/about-us',
    component: <AboutUs />,
    title: 'ZehMizeh | About Zehmizeh and how it works',
    metaDescription:
      'Know about ZehMizeh, how the platform works, how the hiring process is, learn about the infrastructure that we have created for Jewish businesses',
  },
  {
    path: '/customer-support',
    component: <CustomerSupport />,
    title: 'ZehMizeh | Customer Support to contact us for any query',
    metaDescription:
      'ZehMizeh | Customer Support, that helps you for all your queries, feel free to contact us for all your queries.',
  },
  {
    path: '/finding-us',
    component: <FindingUs />,
    title: 'ZehMizeh | Find ZehMiZeh Online: A Guide to Common Spelling Variations',
    metaDescription:
      'ZehMizeh | Discover the various spelling variations of "ZehMiZeh" to find us online without any hassle. Explore our guide for different spellings of our name to reach us easily for information, services, or to connect with us.',
  },
];
const authRoutes = [
  {
    path: '/login',
    component: <Login />,
    title: 'ZehMizeh | Login to the ZehMizeh platform',
    metaDescription:
      'ZehMizeh Login | Login to the ZehMizeh platform and get started today to find best talents or the best projects for you around the world.',
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
    title: 'ZehMizeh | Reset Password',
    metaDescription:
      'ZehMizeh | Reset your password from here by just verifying your email and entering a new password.',
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
    title: 'ZehMizeh | Forgot your password?',
    metaDescription:
      'ZehMizeh | forgot your passowrd? No worries, just verify your email with the otp and you will be able to set a new password.',
  },
  {
    path: '/register/:userType',
    component: <Register />,
    title: 'ZehMizeh | Register to ZehMizeh platform',
    metaDescription:
      'ZehMizeh Registration | Get registered today to find best talents or the best projects for you around the world.',
  },
  {
    path: '/2fa',
    component: <TwoFactor />,
    title: 'ZehMizeh | Two factor verification',
  },
];
const privateRoutes = [
  {
    path: '/dashboard',
    component: <FreelacerDashboard />,
    title: 'ZehMizeh | Freelancer Dashboard - Get all your summary here',
    metaDescription:
      'ZehMizeh Dashboard - A summary of your completed projects, your earnings, ratings & reviews, the invites you have received, your ongoing projects and many more',
  },
  {
    path: '/payments',
    component: <Payments />,
    title: 'ZehMizeh | Payments',
    metaDescription: 'ZehMizeh | Get a list of all the payment history of all your projects',
  },
  {
    path: 'client/dashboard',
    component: <ClientDashboard />,
    title: 'ZehMizeh | Client Dashboard - Get all your summary here',
    metaDescription:
      'ZehMizeh Dashboard - A summary of your completed projects, your hires, ratings & reviews, the proposals you have received, your ongoing projects and many more',
  },
  {
    path: '/post-new-job',
    component: <PostJob />,
    title: 'ZehMizeh | Post a Project with all your requirements',
    metaDescription:
      'ZehMizeh | Post a Project with all your requirements that will help to find the best freelancer for your project.',
  },
  {
    path: '/jobs',
    component: <Jobs />,
    title: 'ZehMizeh | My Projects - Freelancer',
    metaDescription:
      'ZehMizeh | Get a list of all the projects that you have applied on, the projects that you are working on, your closed projects and your saved projects too.',
  },
  {
    path: '/client-jobs',
    component: <ClientJobs />,
    title: 'ZehMizeh | My Projects - Client',
    metaDescription:
      'ZehMizeh | Get a list of all the projects those are in your draft, the projects that you have posted, your ongoing projects, your closed projects and your saved projects too.',
  },
  {
    path: '/edit/:type/:id',
    component: <PostJob />,
    title: 'ZehMizeh | Edit Project',
    metaDescription: 'ZehMizeh | Edit project with all the requirements changes that you want to do.',
  },
  {
    path: '/template/:type/',
    component: <PostJob />,
    title: 'ZehMizeh | Edit Template',
    metaDescription: 'ZehMizeh | Edit the template with all the changes that you want to do.',
  },
  {
    path: '/client-job-details/:id',
    component: <ClientJobDetails />,
    title: 'ZehMizeh | Project Details - get more details about the project',
    metaDescription:
      'ZehMizeh | Get all the details of the project that you have in your draft, your posted projects, your on going projects and the closed projects as well.',
    nestedRoutes: [
      {
        path: '/gen_details',
        component: <ClientJobDetails />,
      },
      {
        path: '/messages',
        component: <ClientJobDetails />,
      },
      {
        path: '/m_stone',
        component: <ClientJobDetails />,
      },
      {
        path: '/applicants',
        component: <ClientJobDetails />,
        nestedRoutes: [
          {
            path: '/:proposalId',
            component: <ClientJobDetails />,
          },
        ],
      },
      {
        path: '/invitees',
        component: <ClientJobDetails />,
        nestedRoutes: [
          {
            path: '/:inviteeId',
            component: <ClientJobDetails />,
          },
        ],
      },
      {
        path: '/feedback',
        component: <ClientJobDetails />,
      },
    ],
  },
  {
    path: '/offer-details/:id',
    component: <OfferDetails />,
    title: 'ZehMizeh | Offer Details - get more details about the offer',
    metaDescription:
      'ZehMizeh | Get all the details of the offer that you have received and then apply if it comes under your expertise.',
  },
  {
    path: '/freelancer/account/:tabkey',
    component: <FreelancerProfileSettings />,
    title: 'ZehMizeh | Freelancer Profile',
    metaDescription:
      'ZehMizeh | View or edit your profile details, education details, skills, languages, description, certificates, bank accounts',
  },
  {
    path: '/client/account/:clientId',
    component: <ClientProfile />,
    title: 'ZehMizeh | Client Profile',
    metaDescription:
      'ZehMizeh | View or edit your profile details, add or delete new bank accounts and credit cards for further use.',
  },
  {
    path: '/change-password',
    component: <ChangePassword />,
    title: 'ZehMizeh | Change Password',
    metaDescription:
      'ZehMizeh | Do you want to change your passowrd? Just enter your current password once for us to verify and you can change your password then.',
  },
  {
    path: '/support/:type?/:sectionId?',
    component: <Support />,
    title: 'ZehMizeh | Support - Submit inquiry or create dispute',
    metaDescription:
      'ZehMizeh | Support that allows you to contact the admin for any inquiries you have and also allows you to create a dispute on the projects you are working on',
  },
  {
    path: '/disputes',
    component: <Disputes />,
    title: 'ZehMizeh | My Disputes List',
    metaDescription: 'ZehMizeh | Your all disputes list with current status whether it is resolved or not.',
  },
  {
    path: '/messages',
    component: <Messaging />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages/:conversationId',
    component: <Messaging />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages-new',
    component: <TalkJS />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages-new/:conversationId',
    component: <TalkJS />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/template-details/:id',
    component: <TemplateDetails />,
    title: 'ZehMizeh | Template Details',
  },
  {
    path: '/client/proposals',
    component: <Proposals />,
    title: 'ZehMizeh | All Proposals',
    metaDescription:
      'ZehMizeh | Get your all proposals that you have recieved for all the projects that you have posted so far.',
  },
  {
    path: '/freelancer/portfolio/:id',
    component: <PortfolioDetails />,
    title: 'ZehMizeh | Portfolio details',
    metaDescription: 'ZehMizeh | Get all the details of the portfolio that a freelancer has created',
  },
  {
    path: '/proposal/discussion/:proposal_id',
    component: <ProposalDiscussion />,
    title: 'ZehMizeh | Portfolio details',
    metaDescription: 'ZehMizeh | Get all the details of the portfolio that a freelancer has created',
  },
  {
    path: '/search',
    component: <Search />,
    title: 'ZehMizeh | Search the best talents or best projects for you',
    metaDescription:
      'ZehMizeh | Find the best talents to get your project done or the best freelancing projects for you around the world with our filters that make it very easy',
  },
  {
    path: '/invoice/:id',
    component: <InvoiceGenerater />,
    title: 'ZehMizeh | Generate Invoice',
    metaDescription: 'ZehMizeh | Now you can genarate the invoices for your payments at anytime.',
  },
  {
    path: '/freelancer/:freelancerId',
    component: <ViewFreelancerProfile />,
    title: 'ZehMizeh | Freelancer profile that may fit to your project',
    metaDescription:
      'ZehMizeh | Know more about the freelancer before giving him a project to find the best one for you who can deliver the best service to you.',
    nestedRoutes: [
      {
        path: '/:tabkey',
        component: <ViewFreelancerProfile />,
      },
    ],
  },
  {
    path: '/job-details/:id',
    component: <JobDetails />,
    title: 'ZehMizeh | Project Details - get more details about the project',
    metaDescription:
      'ZehMizeh | Get all the details of the project that you want to apply or already applied, the project you are working on, the projects that you have already completed',
    nestedRoutes: [
      {
        path: '/gen_details',
        component: <JobDetails />,
      },
      {
        path: '/messages',
        component: <JobDetails />,
      },
      {
        path: '/m_stone',
        component: <JobDetails />,
      },
      {
        path: '/proposal_sent',
        component: <JobDetails />,
      },
      {
        path: '/feedback',
        component: <JobDetails />,
      },
    ],
  },
  {
    path: '/complete-profile',
    component: <CompleteProfile />,
    title: 'ZehMizeh | Complete my profile',
  },
];

export const appRoutes = () => {
  const privateRoutesWithNestedRoutes = [];

  // recursive function to get all nestedRoutes array and push it to
  // privateRoutesWithNestedRoutes
  const recursiveRoutes = (routes, path = '') => {
    routes.forEach((route) => {
      privateRoutesWithNestedRoutes.push({
        ...route,
        path: `${path}${route.path}`,
      });
      if (route?.nestedRoutes) {
        recursiveRoutes(route.nestedRoutes, path.concat(route.path));
      }
    });
  };
  recursiveRoutes(privateRoutes);

  const routes = createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route element={<WebsiteLayout />} path="/">
        {authRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <AuthRoute>
                <>
                  {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </>
              </AuthRoute>
            }
          />
        ))}
        {publicRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                <SuspenseWrapper>{component}</SuspenseWrapper>
              </>
            }
          />
        ))}
        {privateRoutesWithNestedRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAuth>
                <>
                  {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </>
              </RequireAuth>
            }
          />
        ))}
        <Route path="" element={<Navigate to="/home" />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
    </Route>
  );

  return routes;
};
