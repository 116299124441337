import { StyledButton } from 'components/forms/Buttons';
import { FormWrapper } from './support.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { FAQ_QUESTIONS } from 'helpers/const/faqQuestions';
import BackButton from 'components/ui/BackButton';
import styled from 'styled-components';
import { breakpoints } from 'helpers/hooks/useResponsive';
import { Accordion } from 'react-bootstrap';
import { useAuth } from 'helpers/contexts/auth-context';

const FaqTopicContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  .faqBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
    border: 1px solid #aaa;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.statusColors.yellow.bg};
      color: ${(props) => props.theme.statusColors.yellow.color};
      border-color: ${(props) => props.theme.statusColors.yellow.color};
    }
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const QuestionAnswerContainer = styled.div`
  .accordion-button: not(.collapsed) {
    background-color: ${(props) => props.theme.statusColors.yellow.bg};
    color: ${(props) => props.theme.statusColors.yellow.color};
  }
  p {
    margin: 0px 0px;
  }
`;

const FaqForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { sectionId, type } = useParams();

  const contentList = [
    { text: 'ZMZ Basics', id: 'basics' },
    { text: 'Signing Up as a Freelancer', id: 'signing_up_as_a_freelancer' },
    { text: 'Stripe Questions', id: 'stripe' },
    { text: 'Getting Hired', id: 'getting_hired' },
    { text: 'Working a Project', id: 'working_a_project' },
  ];

  const HaventSelectedAnySection = (
    <>
      <p>
        Are you confused about something on ZehMizeh? We've collected all the answers to our most frequently asked
        questions below. Click the topic you'd like to learn more about
      </p>
      <FaqTopicContainer>
        {contentList.map(({ id, text }) => {
          return (
            <div className="faqBox" key={id} onClick={() => navigate(`/support/faq/${id}`)}>
              <b className="my-0">{text}</b>
            </div>
          );
        })}
      </FaqTopicContainer>
    </>
  );

  const data = type === 'faq' && FAQ_QUESTIONS[sectionId];
  const SelectedSection = data && (
    <QuestionAnswerContainer>
      <BackButton className="mb-3" onBack={() => navigate('/support/faq')} />
      <Accordion>
        {data.map((item) => {
          return (
            <Accordion.Item eventKey={item.question} key={item.question}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>{item.answer}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </QuestionAnswerContainer>
  );

  if (user.user_type === 'freelancer')
    return (
      <FormWrapper>
        <h4 className="text-center mb-3">Frequently Asked Questions</h4>
        <div>
          {sectionId ? SelectedSection : HaventSelectedAnySection}
          <div className="my-4 text-center">
            <p>
              If you don't see an answer to your question here, click the button below to see our ZMZ Help Center, where
              we keep the rest of our answers. (You can also can also directly submit questions to ZMZ staff there!)
            </p>
            <StyledButton onClick={() => window.open('https://intercom.help/zehmizehfaq/en', '_blank')}>
              Take me to the Help Center!
            </StyledButton>
          </div>
        </div>
      </FormWrapper>
    );

  return (
    <FormWrapper>
      <h4 className="text-center mb-3">Frequently Asked Questions</h4>
      <div>
        <p>
          Are you confused about something you see on ZehMizeh? Would you like to learn more about how the site works?
          Click the button below to see our ZMZ Help Center, where we've collected answers to all of your most
          frequently asked questions!
        </p>
        <div className="my-4 text-center">
          <StyledButton onClick={() => window.open('https://intercom.help/zehmizehfaq/en', '_blank')}>
            Take me to the Help Center!
          </StyledButton>
        </div>
      </div>
    </FormWrapper>
  );
};

export default FaqForm;
