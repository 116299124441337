/*
 * This is the main component of this file
 */
import React, { useState } from 'react';
import cns from 'classnames';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import YupPassword from 'yup-password';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Col, Row, FloatingLabel, Form } from 'react-bootstrap';
import { StyledButton } from 'components/forms/Buttons';
import AuthLayout from 'components/Layout/AuthLayout';
import ErrorMessage from 'components/ui/ErrorMessage';
import LoadingButtons from 'components/LoadingButtons';
import { changePassword } from 'helpers/http/auth';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { goBack } from 'helpers/utils/goBack';
import { useAuth } from 'helpers/contexts/auth-context';

YupPassword(yup);

const passwordError =
  'Every password must include at least: 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol, and at least 8 characters';

export default function ChangePassword() {
  useStartPageFromTop();
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const [isExstPasswordPreview, setIsExstPasswordPreview] =
    React.useState(false);
  const [isNewPasswordPreview, setIsNewPasswordPreview] = React.useState(false);

  const toggleExstPasswordPreview = () =>
    setIsExstPasswordPreview(!isExstPasswordPreview);
  const toggleNewPasswordPreview = () =>
    setIsNewPasswordPreview(!isNewPasswordPreview);

  // modal flag function
  // const toggleConfirmPasswordPreview = () =>
  //   setIsConfirmPasswordPreview(!isConfirmPasswordPreview);

  const schema = yup.object({
    existingPassword: yup.string().required('Existing password is required.'),
    newPassword: yup
      .string()
      .required('New password is required.')
      .min(8, passwordError)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*-?])[A-Za-z\d#$@!%&*-?]{8,30}$/,
        passwordError
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        'New password and confirm password must match'
      ),
  });

  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    onChangePassword(data);
  };

  const onChangePassword = (data) => {
    // Change password api call
    setLoading(true);
    const body = {
      old_password: data?.existingPassword,
      new_password: data?.newPassword,
    };
    const promise = changePassword(body);
    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        setLoading(false);
        goBack(
          navigate,
          auth?.user?.user_type == 'freelancer'
            ? '/freelancer/account/profile'
            : '/client/account/profile'
        );
        return res.message;
      },
      error: (err) => {
        setLoading(false);
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const { errors } = formState;

  return (
    <AuthLayout center small>
      <h1>Reset Password</h1>

      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel
              controlId="floatingInput"
              label="Enter current password"
              className="password-input"
            >
              <span className="pointer" onClick={toggleExstPasswordPreview}>
                <Eye
                  className={cns('input-icon', {
                    active: isExstPasswordPreview,
                  })}
                />
              </span>
              <Form.Control
                type={!isExstPasswordPreview ? 'password' : 'text'}
                placeholder="Enter current password"
                {...register('existingPassword')}
              />
            </FloatingLabel>
            <ErrorMessage>{errors.existingPassword?.message}</ErrorMessage>
          </Col>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel
              controlId="floatingInput"
              label="Create a new password"
              className="password-input"
            >
              <span className="pointer" onClick={toggleNewPasswordPreview}>
                <Eye
                  className={cns('input-icon', {
                    active: isNewPasswordPreview,
                  })}
                />
              </span>
              <Form.Control
                type={!isNewPasswordPreview ? 'password' : 'text'}
                placeholder="Create a new password"
                {...register('newPassword')}
              />
            </FloatingLabel>
            <ErrorMessage>{errors.newPassword?.message}</ErrorMessage>
          </Col>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel
              controlId="floatingInput"
              label="Confirm password"
              className="password-input"
            >
              <span className="pointer" onClick={toggleNewPasswordPreview}>
                <Eye
                  className={cns('input-icon', {
                    active: isNewPasswordPreview,
                  })}
                />
              </span>
              <Form.Control
                type={!isNewPasswordPreview ? 'password' : 'text'}
                placeholder="Confirm password"
                {...register('confirmPassword')}
              />
            </FloatingLabel>
            <ErrorMessage>{errors.confirmPassword?.message}</ErrorMessage>
          </Col>
        </Row>

        <StyledButton
          className="mt-5"
          padding="1.125rem 5rem"
          type="submit"
          disabled={loading}
        >
          {loading ? <LoadingButtons /> : 'Reset'}
        </StyledButton>
        <h4 className="align-self-center mt-4">
          <div
            className="yellow-link pointer"
            onClick={() =>
              goBack(
                navigate,
                auth?.user?.user_type == 'freelancer'
                  ? '/freelancer/account/profile'
                  : '/client/account/profile'
              )
            }
          >
            Go back
          </div>
        </h4>
      </Form>
    </AuthLayout>
  );
}
