import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  h1.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
  min-height: 70vh;
`;

function Cookies() {
  useStartPageFromTop();
  return (
    <HeaderWrapper>
      <Wrapper>
        <div className="mt-5">
          <h1 className="title">Cookies</h1>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Imperdiet habitant consequat felis dui, hac amet risus adipiscing.
            Viverra in a massa in.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque
          </p>
        </div>
        <div className="mt-4">
          <h2 className="heading">Heading</h2>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Imperdiet habitant consequat felis dui, hac amet risus adipiscing.
            Viverra in a massa in.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque
          </p>
        </div>
        <div className="mt-4">
          <h3 className="heading">Heading</h3>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Imperdiet habitant consequat felis dui, hac amet risus adipiscing.
            Viverra in a massa in.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque
          </p>
        </div>
        <div className="mt-4">
          <h3 className="heading">Heading</h3>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque faucibus eros orci adipiscing porttitor elit
            leo. Commodo sed amet, sollicitudin sed suspendisse proin nibh
            nullam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Imperdiet habitant consequat felis dui, hac amet risus adipiscing.
            Viverra in a massa in.
          </p>
          <p className="fs-18 fw-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet
            habitant consequat felis dui, hac amet risus adipiscing. Viverra in
            a massa in. Quisque
          </p>
        </div>
      </Wrapper>
    </HeaderWrapper>
  );
}

export default Cookies;
