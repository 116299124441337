import { useNavigate, useParams } from 'react-router-dom';
import {
  ProfilePortfolioWrapper,
  ViewFreelancerContent,
  ViewFreelancerProfileWrapper,
  Wrapper,
} from './ViewFreelancerProfile.styled';
import Loader from 'components/Loader';
import BackButton from 'components/ui/BackButton';
import { useAuth } from 'helpers/contexts/auth-context';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { ReactComponent as LeftBgImage } from 'assets/icons/freelancer-profile-left-bg.svg';
import { ReactComponent as RightBgImage } from 'assets/icons/search-banner-right.svg';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { goBack } from 'helpers/utils/goBack';
import { Profile } from './Tabs/Profile';
import { JobRatings } from './Tabs/JobRatings';
import { Portfolio } from './Tabs/Portfolio';
import Tabs from './Tabs';
import { VIEW_FREELANCER_PROFILE_TABS } from 'helpers/const/tabs';
import { usePortfolioList } from 'controllers/usePortfolioList';
import { useFreelancerDetails } from 'controllers/useFreelancerDetails';
import { hasClientAddedPaymentDetails } from 'helpers/utils/helper';

let timeoutRef;
const ViewFreelancerProfile = () => {
  const { tabkey, freelancerId } = useParams();

  useStartPageFromTop();
  const navigate = useNavigate();
  const { user } = useAuth();
  const freelancerQuery = useFreelancerDetails(freelancerId);
  const portfolioQuery = usePortfolioList(freelancerId);

  /* START ----------------------------------------- Checking client added payment details or not. If not,then navigating to profile page */
  useEffect(() => {
    if (!hasClientAddedPaymentDetails(user)) {
      timeoutRef = setTimeout(() => {
        clearTimeout(timeoutRef);
        toast.error("To access ZMZ's freelancers, add at least one payment method to your profile.");
      }, 500);
      goBack(navigate, '/client/account/settings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* END ------------------------------------------- Checking client added payment details or not. If not,then navigating to profile page */

  useEffect(() => {
    /* TODO: Here the #element_id was not working proeprly, I tried lot for that but taking too much time
     * so for now I have added this thing, and working perfectly, if this is not correct will see in e2e testing
     */
    if (!freelancerQuery.isLoading && !freelancerQuery.isRefetching) {
      const currentLocation = window.location.href;
      const hasCommentAnchor = currentLocation.includes('/#');
      if (hasCommentAnchor) {
        const anchorCommentId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`;
        const anchorComment = document.getElementById(anchorCommentId);
        if (anchorComment) {
          anchorComment.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [freelancerQuery.isLoading, freelancerQuery.isRefetching]);

  const tabUI = useMemo(() => {
    switch (tabkey) {
      /* START ----------------------------------------- Profile */
      case VIEW_FREELANCER_PROFILE_TABS.PROFILE:
        return <Profile />;
      /* END ------------------------------------------- Profile */

      /* START ----------------------------------------- Portfolio */
      case VIEW_FREELANCER_PROFILE_TABS.PORTFOLIO: {
        if (portfolioQuery?.portfolioData && portfolioQuery?.portfolioData?.length > 0) {
          return <Portfolio allowEdit={false} />;
        }
        return <></>;
      }
      /* END ------------------------------------------- Portfolio */

      /* START ----------------------------------------- Ratings */
      case VIEW_FREELANCER_PROFILE_TABS.RATINGS:
        if (
          freelancerQuery.freelancerData?.completedJobDetail &&
          freelancerQuery.freelancerData?.completedJobDetail?.length > 0
        )
          return <JobRatings />;
        return <></>;
      /* END ------------------------------------------- Ratings */
      default:
        return <></>;
    }
  }, [freelancerQuery.freelancerData?.completedJobDetail, portfolioQuery?.portfolioData, tabkey]);

  return (
    <ViewFreelancerProfileWrapper id="profile-profile">
      <Tabs />
      <ViewFreelancerContent>
        <Wrapper className="content-hfill">
          {/* background spiral images */}
          <LeftBgImage className="banner-left-bg" />
          <RightBgImage className="banner-right-bg" />

          <div className="content">
            {/* Back buttton */}
            <BackButton route={user && user.user_type == 'client' ? '/client/dashboard' : '/dashboard'} />

            {freelancerQuery.isLoading ? (
              <Loader />
            ) : (
              freelancerQuery.freelancerData && (
                <>
                  {/* {tabUI} */}
                  <Profile />
                  <ProfilePortfolioWrapper id="profile-portfolio">
                    <Portfolio allowEdit={false} />
                  </ProfilePortfolioWrapper>
                  <JobRatings />
                </>
              )
            )}
          </div>
        </Wrapper>
      </ViewFreelancerContent>
    </ViewFreelancerProfileWrapper>
  );
};

export default ViewFreelancerProfile;
