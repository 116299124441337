/*
 * This is the main component of "All proposals" route that imports all the componnets
 */

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Loader from 'components/Loader';
import ProposalDetailsModal from 'components/jobs/ProposalDetailsModal';
import BackButton from 'components/ui/BackButton';
import NoDataFound from 'components/ui/NoDataFound';
import { Wrapper } from './proposals.styled';
import PaginationComponent from 'components/ui/Pagination';
import ProposalCard from './ProposalCard';
import useProposals from './use-proposals';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { useAuth } from 'helpers/contexts/auth-context';
import { convertToTitleCase } from 'helpers/utils/misc';
import Search from 'components/forms/Search';
import useDebounce from 'helpers/hooks/useDebounce';

const RECORDS_PER_PAGE = 10;

const Proposals = () => {
  useStartPageFromTop();
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedQuery = useDebounce(searchTerm, 500);

  /*
   * This will fetch all the proposals received for all the jobs
   */
  const { proposals, totalResults, isLoading, isRefetching, refetch } = useProposals({
    page: currentPage,
    limit: RECORDS_PER_PAGE,
    keyword: debouncedQuery,
  });

  const [showProposalDetails, setShowProposalDetails] = useState<boolean>(false);
  const [selectedProposalId, setSelectedProposalId] = useState<string>('');

  const toggleProposalDetailsModal = () => {
    /* This function will toggle the proposal details modal */
    setShowProposalDetails(!showProposalDetails);
  };

  const onSelect = (id: string) => () => {
    /*
     * This function will save selected propoasal id and open the proposal details modal, with details of the selected proposal
     */
    setSelectedProposalId(id);
    toggleProposalDetailsModal();
  };

  const onRefetch = (shouldToggleModal = true) => {
    /* After accepting the proposal this funciton will be called
     * and this will close the modal and refetch the proposals
     */
    if (shouldToggleModal) toggleProposalDetailsModal();
    setSelectedProposalId('');
    refetch();
  };

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data */
    setCurrentPage(page?.selected + 1);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setCurrentPage(1);
    refetch();
  }, [debouncedQuery, refetch]);

  return (
    <>
      <Wrapper className="mb-5 content-hfill">
        {/* Back button*/}
        <BackButton className="mt-4" route={user.user_type === 'freelancer' ? '/dashboard' : '/client/dashboard'}>
          <div className="fs-18 fw-400">
            {' '}
            <span className="back-btn-devider mx-3 fw-400" />
            <span className="fw-700">Dashboard</span> / Proposals
          </div>
        </BackButton>

        <div className="d-flex align-items-center mt-4 justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h1 className="fw-400 me-2">{convertToTitleCase(`Proposals for ${user?.first_name}'s Projects`)}</h1>
            {isRefetching && <Spinner animation="border" size="sm" />}
          </div>
          <Search
            containerClassName="searchbox w-100"
            placeholder="Search by project title or freelancer’s name"
            onChange={onSearch}
            value={searchTerm}
          />
        </div>

        {isLoading && <Loader height={450} />}

        {/* Proposals Listings */}
        {!isLoading && (
          <div className="listings mt-4">
            {proposals.length > 0 ? (
              proposals.map((item: any) => (
                <ProposalCard
                  data={item}
                  key={item?.proposal_id}
                  onSelect={onSelect(item?.proposal_id)}
                  refetch={refetch}
                />
              ))
            ) : (
              <NoDataFound className="pb-5" />
            )}
          </div>
        )}

        {proposals?.length > 0 && (
          <div className="d-flex justify-content-center mt-4 align-items-center">
            <PaginationComponent
              total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
              onPageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </Wrapper>

      {/* Proposal details modal */}
      <ProposalDetailsModal
        show={showProposalDetails}
        toggle={toggleProposalDetailsModal}
        selectedProposalId={selectedProposalId}
        refetch={onRefetch}
        replyOnProjectPageBtn
      />
    </>
  );
};

export default Proposals;
