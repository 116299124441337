import { ChipContainer } from './style';
import { MdCancel } from 'react-icons/md';
import { MdOutlineCheckCircle } from 'react-icons/md';

type Props = {
  isActive?: boolean;
  label: string;
  className?: string;
  onDelete?: () => void;
  onSelect?: () => void;
};

export const Chip = ({
  className,
  isActive,
  label,
  onDelete,
  onSelect,
}: Props) => {
  return (
    <ChipContainer
      className={className}
      $active={isActive}
      $showCursor={!!onSelect}
      onClick={() => onSelect && onSelect()}
    >
      {onSelect && isActive && <MdOutlineCheckCircle className="select-btn" />}
      <span className="label-text">{label}</span>
      {onDelete && <MdCancel className="delete-btn" onClick={onDelete} />}
    </ChipContainer>
  );
};
