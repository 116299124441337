import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { breakpoints } from 'helpers/hooks/useResponsive';

export const HeaderWrapper = styled.div`
  padding: 1.5rem 0rem 1.5rem 0rem;
  @media ${breakpoints.mobile} {
    .navbar-collapse {
      background: #fefbf4;
      position: fixed;
      top: 100px;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      padding: 1rem;
      z-index: 1000;
      overflow-y: auto;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }

    .navbar-collapse.show {
      transform: translateX(0);
    }

    /* Navigation links */
    .navbar-nav {
      margin: 0 !important;
      padding: 0;

      .nav-link {
        padding: 1rem 0;
        font-size: 1.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0;
      }
    }

    /* Close button */
    .navbar-toggler {
      position: absolute;
      right: 1rem;
      z-index: 1001;
    }

    /* Jewish text */
    .jewish-text {
      margin: 1rem 0;
      text-align: center;
    }
  }
  .divider {
    width: 1.37875rem;
    transform: rotate(90deg);
    border: 1px solid #d9d9d9;
  }
  .navbar-nav .nav-link {
    color: #000;
    margin: 0rem 1rem 0rem 0rem;
    @media (max-width: 1024px) {
      margin: 0;
    }
    @media ${breakpoints.desktop} {
      &:after {
        content: '';
        background: transparent;
        height: 2px;
        display: block;
        width: 0;
        transition: all 0.2s ease-in-out;
        margin: auto;
        position: relative;
        top: 3px;
      }
      :hover {
        &:after {
          width: 80%;
          background: currentColor;
        }
      }
    }
  }
  .search-link {
    margin: 0rem 1rem;
  }
  .search {
    color: #000;
    margin: 0rem 0.625rem;
    @media ${breakpoints.mobile} {
      font-size: 0.875rem;
    }
  }
  .searchLink {
    &:after {
      display: none !important;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .profile-img {
    height: 48px;
    width: 48px;
    border-radius: 100%;
    object-fit: cover;
  }
  .loggedin-username {
    line-height: 1.35rem;
    margin-left: 0.75rem;
    /* max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
  }
  .dropdown-arrow {
    margin-left: 0.625rem;
  }
  .msg-count {
    background-color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .notification-bell {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
`;

export const Main = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fefbf4;
  .header-bottom-border {
    background: #f2b420;
    height: 1px;
    width: 100%;
  }
  .account-not-approved {
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.yellow};
    border-top: none;
    background-color: rgba(251, 245, 232, 1);
    &.rejected {
      border: 1px solid ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.red};
    }
    .faq-link {
      &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const JewishText = styled.p`
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-weight: bold;
  font-size: 20px;
  text-wrap: nowrap;
  margin-right: 1.5rem;
  margin-left: 1rem;
`;
