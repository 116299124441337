import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { expectedHoursRemap, showFormattedBudget } from 'helpers/utils/misc';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import AttachmentPreview from 'components/ui/AttachmentPreview';
import { getCategories, getSkills } from 'helpers/utils/helper';
import { TJobDetails } from 'helpers/types/job.type';

type Props = {
  data: TJobDetails;
  isProposalSubmitted: boolean;
};

const DetailStyledItem = styled.div`
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 0.75rem;
  .job-detail-item-title {
    line-height: 2.1rem;
  }
  .description-text {
    opacity: 0.5;
  }
  .line-height-2rem {
    line-height: 2rem;
  }
  .job-detail-item-value {
    margin-top: 1.375rem;
  }
`;

const OfferOtherDetails = ({ data, isProposalSubmitted }: Props) => {
  return (
    <Row>
      {/* START ----------------------------------------- Client's invitation */}
      {!isProposalSubmitted && !!data?.invite_message && (
        <Col md={12}>
          <DetailsItem
            title="Client's Invitation"
            atributeValue={
              <div className="line-height-2rem fw-300 fs-18 mt-3">
                <StyledHtmlText
                  htmlString={data.invite_message}
                  id={`invite_msg_${data.invite_message}`}
                  needToBeShorten={true}
                />
              </div>
            }
          />
        </Col>
      )}
      {/* END ------------------------------------------- Client's invitation */}

      <Col md={12}>
        <DetailsItem
          title="Project Description"
          atributeValue={
            <div>
              <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
                {/* This will convert html to normal text */}
                <StyledHtmlText
                  htmlString={data.job_description}
                  id={`offer_${data.job_post_id}`}
                  needToBeShorten={true}
                />
              </div>
              {(data.attachments?.length > 0 ||
                data?.reference_links?.length > 0 ||
                data?.reference_attachments?.length > 0) && (
                <Row className="mt-4">
                  {/* START ----------------------------------------- Related Files */}
                  {data.attachments?.length > 0 && (
                    <Col>
                      <div className="fs-20 fw-400 mb-2">Related Files</div>
                      <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                        {data.attachments?.map((item: string) => (
                          <AttachmentPreview
                            key={item}
                            uploadedFile={item}
                            removable={false}
                            shouldShowFileNameAndExtension={false}
                          />
                        ))}
                      </div>
                    </Col>
                  )}
                  {/* END ------------------------------------------- Related Files */}

                  {(data?.reference_links?.length > 0 || data?.reference_attachments?.length > 0) && (
                    <Col>
                      <div className="fs-20 fw-400 mb-2">Style Samples</div>
                      {/* START ----------------------------------------- Style Samples Links */}
                      {data.reference_links?.length > 0 && (
                        <div className="mb-3">
                          {data.reference_links.map((referenceLink) => {
                            if (!referenceLink.includes('http')) {
                              referenceLink = `http://${referenceLink}`;
                            }
                            return (
                              <div key={referenceLink}>
                                <a className="text-primary" href={referenceLink} target="_blank" rel="noreferrer">
                                  {referenceLink}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {/* END ------------------------------------------- Style Samples Links */}

                      {/* START ----------------------------------------- Style Samples Attachments */}
                      {data?.reference_attachments?.length > 0 && (
                        <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                          {data?.reference_attachments?.map((item: string) => (
                            <AttachmentPreview
                              key={item}
                              uploadedFile={item}
                              removable={false}
                              shouldShowFileNameAndExtension={false}
                            />
                          ))}
                        </div>
                      )}
                      {/* END ------------------------------------------- Style Samples Attachments */}
                    </Col>
                  )}
                </Row>
              )}
            </div>
          }
        />
      </Col>

      {/* START ----------------------------------------- Budget */}
      <Col md={6}>
        <DetailsItem
          title="Budget"
          atributeValue={
            <div className="job-detail-item-value fw-400 fs-18 mt-3">
              {data.budget?.isProposal === true ? 'Open to Proposals' : showFormattedBudget(data.budget)}
            </div>
          }
        />
        {/* END ------------------------------------------- Budget */}

        {/* START ----------------------------------------- Duration */}
        {data?.expected_delivery_date && (
          <DetailsItem
            title="Delivery Time"
            atributeValue={
              <div className="job-detail-item-value">
                <div className="d-flex align-items-center g-1">
                  <div className="description-text fs-20 fw-400">Duration:</div>
                  <div className="fs-20 fw-400">{data.expected_delivery_date}</div>
                </div>
              </div>
            }
          />
        )}
        {/* END ------------------------------------------- Duration */}

        {/* START ----------------------------------------- Expected hours */}
        {expectedHoursRemap(data.time_scope) && (
          <DetailsItem
            title="Expected Hours Required"
            atributeValue={<div className="mt-3 fs-18 fw-400">{expectedHoursRemap(data.time_scope)}</div>}
          />
        )}
        {/* END ------------------------------------------- Expected hours */}

        {/* START ----------------------------------------- Preferred Location */}
        {Array.isArray(data?.preferred_location) && data?.preferred_location?.length > 0 && (
          <DetailsItem
            title="Preferred Freelancer Location"
            atributeValue={<div className="mt-3 fs-18 fw-400">{data.preferred_location.join(', ')}</div>}
          />
        )}
        {/* END ------------------------------------------- Preferred Location */}
      </Col>
      <Col md={6}>
        {/* START ----------------------------------------- Payment Structure */}
        <DetailsItem
          title="Payment Structure"
          atributeValue={
            <div className="job-detail-item-value fs-18 fw-400">
              {data.budget?.type == 'fixed' ? 'Project-Based' : data.budget?.type == 'hourly' ? 'Hourly' : 'Unsure'}
            </div>
          }
        />
        {/* END ------------------------------------------- Payment Structure */}

        {/* START ----------------------------------------- Categories */}
        {getCategories(data.skills).length > 0 && (
          <DetailsItem
            title="Skills Category"
            atributeValue={
              <div className="d-flex align-items-center mt-3 flex-wrap">
                <div className="description-text line-height-2rem fw-300 fs-18 text-capitalize">
                  {getCategories(data.skills)
                    .map((dt) => dt.category_name)
                    .join(', ')}
                </div>
              </div>
            }
          />
        )}
        {/* END ------------------------------------------- Categories */}

        {/* START ----------------------------------------- Skills */}
        {getSkills(data.skills)?.length > 0 && (
          <DetailsItem
            title="Skill(s)"
            atributeValue={
              <div className="d-flex align-items-center mt-3 flex-wrap">
                {getSkills(data.skills)?.map(
                  (item, index: number) =>
                    item.skill_id && (
                      <div
                        className="description-text line-height-2rem fw-300 fs-18 text-capitalize"
                        key={item.skill_id}
                      >
                        {item.skill_name}
                        {index < getSkills(data?.skills)?.length - 1 ? ',' : ''}
                        &nbsp;
                      </div>
                    )
                )}
              </div>
            }
          />
        )}
        {/* END ------------------------------------------- Skills */}

        {/* START ----------------------------------------- Language */}
        {data.languages?.length > 0 && (
          <DetailsItem
            title="Language"
            atributeValue={
              <div className="d-flex align-items-center mt-3 flex-wrap">
                {data.languages?.map((item, index: number) => (
                  <div className="description-text line-height-2rem fw-300 fs-18" key={item.id}>
                    {item.name}
                    {index < data?.languages?.length - 1 ? ',' : ''}&nbsp;
                  </div>
                ))}
              </div>
            }
          />
        )}
        {/* END ------------------------------------------- Language */}
      </Col>
    </Row>
  );
};

export default OfferOtherDetails;

const DetailsItem = ({ title, atributeValue }: { title: string; atributeValue: React.ReactNode }) => {
  return (
    <DetailStyledItem>
      <div className="job-detail-item-title fs-24 fw-400">{title}</div>
      {atributeValue}
    </DetailStyledItem>
  );
};
