import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StyledButton } from 'components/forms/Buttons';
import AuthLayout from 'components/Layout/AuthLayout';
import ErrorMessage from 'components/ui/ErrorMessage';
import { useAuth } from 'helpers/contexts/auth-context';
import { forgotPassword } from 'helpers/http/auth';
import { showErr, showMsg } from 'helpers/utils/misc';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';

export default function ForgotPassword() {
  useStartPageFromTop();
  const [loading, setLoading] = React.useState(false);
  const { setEmail } = useAuth();
  const navigate = useNavigate();
  const schema = yup.object({
    email_id: yup
      .string()
      .required('Email is required.')
      .email('Please enter a valid email.'),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);
    forgotPassword(data.email_id)
      .then((res) => {
        setLoading(false);
        if (!res.status) {
          showErr(res.message);
          return;
        }
        showMsg(res.message);
        setEmail(data.email_id);
        navigate('/reset-password');
      })
      .catch((err) => {
        showErr(err?.response?.data?.message || 'Something went wrong.');
        setLoading(false);
      });
  };

  const { errors } = formState;
  return (
    <AuthLayout center small showNavigationHeader>
      <h1 className="fs-32">Forgot Password</h1>
      <p className="fs-20 fw-300 text-muted my-2">
        Enter the email address attached to your account and we'll send you a
        code to reset your password.
      </p>
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <FloatingLabel
          controlId="floatingInput"
          label="Email Address"
          className="mb-3 text-start"
        >
          <Form.Control
            type="text"
            placeholder="Email Address"
            {...register('email_id')}
          />
        </FloatingLabel>
        <ErrorMessage className="text-start">
          {errors.email_id?.message}
        </ErrorMessage>

        <StyledButton
          className="mt-2"
          width={200}
          height={56}
          type="submit"
          padding="0"
          disabled={loading}
        >
          Submit
          {loading && <Spinner className="ms-2" animation="grow" size="sm" />}
        </StyledButton>
        <h4 className="align-self-center mt-4">
          <Link to="/login" className="yellow-link">
            Back to login
          </Link>
        </h4>
      </Form>
    </AuthLayout>
  );
}
