import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import BackButton from 'components/ui/BackButton';
import usePortfolioDetails from './controllers/usePortfolioDetails';
import PortfolioListItem from './partials/PortfolioListItem';
import { Wrapper } from './portfolio-details.styled';
import { capitalizeFirstLetter } from 'helpers/utils/misc';
import { isAudio, isPDF, isVideo } from 'helpers/utils/coverImgHandler';

const PortfolioDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { portfolioData, isLoading, isRefetching } = usePortfolioDetails(
    Number(id) || 0
  );

  const [previewURL, setPreviewURL] = useState<string>();

  const previewHandler = (val: string) => {
    setPreviewURL(val);
    document.body.style.overflow = val ? 'hidden' : 'unset';
  };

  return (
    <Wrapper className="mt-5 pb-5 content-hfill">
      <BackButton>
        {isLoading || isRefetching ? (
          <Spinner animation="border" size="sm" className="ms-1" />
        ) : null}
      </BackButton>

      <div className="mt-5">
        <h1 className="d-flex align-items-center justify-content-center fw-400 mb-5">
          {portfolioData?.project_name &&
            capitalizeFirstLetter(portfolioData?.project_name)}
        </h1>

        <Row>
          {!isLoading &&
            !isRefetching &&
            portfolioData?.image_urls?.map((item: any) => (
              <Col md={3} xs={12} key={item} className="mb-4">
                <PortfolioListItem
                  image={item}
                  onClick={() => previewHandler(item)}
                />
              </Col>
            ))}
        </Row>
      </div>

      {!!previewURL && (
        <div className="image-preview-wrapper">
          <div
            className="img-previewer-background"
            onClick={() => previewHandler('')}
          ></div>

          {isPDF(previewURL) && (
            <iframe src={previewURL} className="previewer-box-pdf" />
          )}

          {isVideo(previewURL) && (
            <video controls src={previewURL} className="previewer-box image">
              This is video
            </video>
          )}

          {isAudio(previewURL) && (
            <audio controls src={previewURL} className="previewer-box h-25">
              This is audio
            </audio>
          )}

          {!isVideo(previewURL) &&
            !isPDF(previewURL) &&
            !isAudio(previewURL) && (
              <img className="previewer-box image" src={previewURL} />
            )}

          <Button
            variant="transparent"
            className="close homepage-video-close-btn portfolio-close-btn"
            onClick={() => previewHandler('')}
          >
            &times;
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default PortfolioDetails;
