import { useEffect, useState } from 'react';
import * as C from './client-profile.styled';
import { useNavigate } from 'react-router-dom';

interface Prop {
  currentTab: string;
}
const ClientProfileTabs = ({ currentTab }: Prop) => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const [tabs] = useState([
    {
      id: 1,
      title: 'Profile',
      path: '/client/account/profile',
    },
    {
      id: 2,
      title: 'Ratings',
      path: '/client/account/ratings',
    },
    {
      id: 3,
      title: 'Payment Details',
      path: '/client/account/payments',
    },
    {
      id: 4,
      title: 'Account Settings',
      path: '/client/account/settings',
    },
  ]);

  useEffect(() => {
    if (currentTab === 'settings') setActiveTab(4);
    if (currentTab === 'payments') setActiveTab(3);
    if (currentTab === 'ratings') setActiveTab(2);
    if (currentTab === 'profile') setActiveTab(1);
  }, [currentTab]);

  return (
    <C.ClientTabWrapper className="tab-wrapper">
      <C.ClientTab>
        {tabs.map((tb) => (
          <C.ClientTitle
            onClick={() => {
              navigate(tb.path, { replace: true });
              setActiveTab(tb.id);
            }}
            active={tb.id === activeTab}
            key={tb.id}
          >
            {tb.title}
          </C.ClientTitle>
        ))}
      </C.ClientTab>
    </C.ClientTabWrapper>
  );
};

export default ClientProfileTabs;
