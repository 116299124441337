/*
 * This is the main component of this route
  Client side - Jobs list page
 */
import { useEffect, useState, useMemo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import cns from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Wrapper } from './client-jobs.styled';
import Loader from 'components/Loader';
import Tabs from 'components/ui/Tabs';
import NoDataFound from 'components/ui/NoDataFound';
import Tooltip from 'components/ui/Tooltip';
import Listings from './listings';
import { StyledButton } from 'components/forms/Buttons';
import PaginationComponent from 'components/ui/Pagination';
import JobAutoCompleteSearch from 'components/jobs/JobAutoCompleteSearch';
import PageTitle from 'components/styled/PageTitle';
import useOnClickOutside from 'helpers/hooks/useClickOutside';
import useDebounce from 'helpers/hooks/useDebounce';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import useResponsive from 'helpers/hooks/useResponsive';
import { useAuth } from 'helpers/contexts/auth-context';
import useClientJobs from './hooks/useClientJobs';
import useGetAllJobs from './hooks/useGetAllJobs';
import { TABS } from './consts';
import { useIsAllowedToPostProject } from 'helpers/hooks/useIsAllowedToPostProject';

const RECORDS_PER_PAGE = 10;

const ClientJobs = () => {
  useStartPageFromTop();

  const { isAllowedToPostProject } = useIsAllowedToPostProject();

  const { user } = useAuth();
  const navigate = useNavigate();

  const { isDesktop, isLaptop } = useResponsive();
  const [activeTab, setActiveTab] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [searchSubmitted, setSearchSubmitted] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState('');

  const location = useLocation();
  const ref = useRef(null);
  const onClose = () => {
    setSearchValue('');
  };
  useOnClickOutside(ref, onClose);

  const [query, setQuery] = useState('');
  const deboubcedSearch = useDebounce(searchValue, 500);

  const { data, isLoading, totalResults, isRefetching } = useClientJobs({
    query,
    limit: RECORDS_PER_PAGE,
  });
  const { jobs, fetchNextPage, hasNextPage, isFetchingNextPage, loading } =
    useGetAllJobs(deboubcedSearch);

  useEffect(() => {
    /* This will set the default or applied filter from the url and
    then will start fetching the data once the query is set */

    let NEW_ACTIVE_TAB = activeTab; // default active tab (number)
    let NEW_PAGE = 1; // default current page = 1
    let KEYWORD = ''; // default keyword = ''
    let QUERY = '';
    let FILTER = '';

    if (location.search) {
      const params: any = new URLSearchParams(location.search);
      const paramsActiveTab = params.get('filter');
      const pageNo = params.get('pg');
      const keyword = params.get('keyword');
      const filter = params.get('sort');

      if (paramsActiveTab) {
        setActiveTab(paramsActiveTab);
        NEW_ACTIVE_TAB = paramsActiveTab;
      }
      if (pageNo) {
        setCurrentPage(pageNo);
        NEW_PAGE = pageNo;
      }
      if (keyword) {
        KEYWORD = keyword;
        setSearchTerm(KEYWORD);
        setSearchValue(KEYWORD);
        setSearchSubmitted(true);
      }
      if (filter) {
        FILTER = filter;
        setSortValue(filter);
        NEW_ACTIVE_TAB = 'closed';
      }
    }

    QUERY = `filter=${NEW_ACTIVE_TAB}&pg=${NEW_PAGE}&keyword=${KEYWORD}&sort=${FILTER}`;
    setQuery(QUERY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onTabChange = (value) => {
    // This will remember the selected tab so that can be retrived when coming back from any other page

    setCurrentPage(1);
    setActiveTab(value);
    let searchQuery = `filter=${value}&pg=1&keyword=${searchTerm}`;
    if (value === 'closed') {
      searchQuery = searchQuery.concat(`&sort=${sortValue}`);
    }

    if (history.pushState) {
      changeWindowLocation(searchQuery);

      setQuery(searchQuery);
      setSearchValue(searchTerm);
    }
  };

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data - Pagination is implemented locally  */
    setCurrentPage(page?.selected + 1);
    let searchQuery = `filter=${activeTab}&pg=${
      page?.selected + 1
    }&keyword=${searchTerm}`;

    if (activeTab === 'closed') {
      searchQuery = searchQuery.concat(
        `&sort=${sortValue === 'all' ? '' : sortValue}`
      );
    }

    changeWindowLocation(searchQuery);

    setQuery(searchQuery);
    setSearchValue(searchTerm);
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);

    let NEW_PAGE = currentPage;
    if (currentPage !== 1) {
      setCurrentPage(1);
      NEW_PAGE = 1;
    }

    let searchQuery = `filter=${activeTab}&pg=${NEW_PAGE}&keyword=${value}`;
    if (activeTab === 'closed') {
      searchQuery = searchQuery.concat(
        `&sort=${sortValue === 'all' ? '' : sortValue}`
      );
    }

    changeWindowLocation(searchQuery);

    setQuery(searchQuery);
  };

  const changeWindowLocation = (path: string) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${path}`;

    window.history.pushState({ path: newurl }, '', newurl);
  };

  const onJobClick = (id: string) => () => {
    navigate(`/client-job-details/${id}`);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSearch(searchValue);
    setSearchSubmitted(true);
  };

  const onSearchValueChange = (val: string) => {
    if (val === '') {
      onSearch(val);
    }
    setSearchSubmitted(false);
    setSearchValue(val);
  };

  const sortBy = (value: string) => {
    setCurrentPage(1);
    setSortValue(value == 'all' ? '' : value);
    const searchQuery = `filter=${activeTab}&pg=1&keyword=${searchTerm}&sort=${
      value == 'all' ? '' : value
    }`;
    changeWindowLocation(searchQuery);

    setQuery(searchQuery);
  };

  const filteredJobs = useMemo(() => {
    return jobs?.pages || [];
  }, [jobs?.pages]);

  return (
    <Wrapper className="content-hfill px-4 px-lg-0">
      {/* Page title */}
      <PageTitle className="text-center mb-3">
        {user?.first_name}'s Projects
      </PageTitle>

      <Form onSubmit={handleSubmit} className="project-filter" ref={ref}>
        <JobAutoCompleteSearch
          jobs={jobs?.pages}
          filteredJobs={filteredJobs}
          onJobClick={onJobClick}
          onSearchValueChange={onSearchValueChange}
          searchValue={searchValue}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={loading}
          searchSubmitted={searchSubmitted}
        />
      </Form>

      {/* Tabs and post a job button */}

      <div
        className={cns('d-flex justify-content-between', {
          'flex-column g-2': !isDesktop && !isLaptop,
        })}
      >
        <Tabs
          className={cns({ 'order-2': !isDesktop && !isLaptop })}
          activeTab={activeTab}
          tabs={TABS}
          onTabChange={onTabChange}
          breakPoint="576px"
        />

        {isAllowedToPostProject ? (
          <Link
            to="/search?type=freelancers"
            className={cns({
              'mt-2 d-block order-1 my-2': !isDesktop && !isLaptop,
            })}
          >
            <StyledButton className={!isDesktop && !isLaptop ? 'w-100' : ''}>
              Find Freelancers
            </StyledButton>
          </Link>
        ) : (
          <Tooltip
            customTrigger={
              <StyledButton
                disabled
                width={200}
                className={!isDesktop && !isLaptop ? 'w-100' : ''}
              >
                <Link
                  to="/post-new-job"
                  className={cns({
                    'mt-2 d-block order-1 my-2': !isDesktop && !isLaptop,
                  })}
                >
                  Post New Project
                </Link>
              </StyledButton>
            }
          >
            <p>
              In order to post a project, add a credit card or verified bank
              account to your profile.
            </p>
            <Link to="/client/account/payments">
              <u>Update my profile</u>
            </Link>
          </Tooltip>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading && !isRefetching && data?.length == 0 && (
        <NoDataFound className="py-5" title="No jobs found" />
      )}

      {/* Jobs listings */}
      <div className="listings mt-4">
        {!isLoading && data?.length > 0 && (
          <Listings
            data={data}
            listingType={activeTab}
            sortFilter={sortBy}
            toggleReset={sortValue === '' ? 'Filter by' : sortValue}
          />
        )}
      </div>

      {/* Pagination */}
      {data?.length > 0 && (
        <div className="d-flex justify-content-center mt-3">
          <PaginationComponent
            total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ClientJobs;
