import styled from 'styled-components';
import cns from 'classnames';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import { transition } from 'styles/transitions';
import { convertToTitleCase } from 'helpers/utils/misc';

const DetailsWrapper = styled.div`
  padding: 3.25rem;
  border-radius: 0.875rem;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.04);
  .description {
    margin-top: 1.25rem;
    opacity: 0.8;
    line-height: 160%;
  }
  .skills {
    margin-top: 1.25rem;
    gap: 10px;
  }
  .education-details {
    gap: 22px;
    max-width: 100%;
    word-break: break-word;
  }
  .course-name {
    line-height: 1.6rem;
  }
  .education-description {
    opacity: 0.7;
    margin-top: 10px;
    line-height: 1.44rem;
  }
  .education-school-img {
    height: 92px;
    width: 92px;
  }
  .courrse-certi-details {
    padding: 1.5rem;
    border: 1px solid #dddddd;
    border-radius: 15px;
    margin-top: 1.25rem;
    word-wrap: break-word;
  }
  .box-shadow {
    ${() => transition()}
  }
  .portfolio {
    color: ${(props) => props.theme.colors.yellow};
  }
  .portfolio {
    color: ${(props) => props.theme.colors.yellow};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  border: 1px solid #000000;
  margin: 2.75rem 0rem;
`;

const SkillItem = styled.div`
  padding: 0.625rem 0.75rem;
  background: #f6f6f6;
  border-radius: 0.5rem;
  width: fit-content;
  text-transform: capitalize;
`;

const FreelancerOtherDetails = ({ data }: any) => {
  const openCertificate = (link: any) => {
    // This function will open the certificate link
    if (link) {
      window.open(link, 'blank');
    }
  };

  const AboutMe = data?.about_me && (
    <div className="profile-detail-block">
      <div className="title fs-28 fw-400">
        {data?.is_agency ? 'About the Agency' : 'About Me'}
      </div>
      <div className="description fs-18 fw-300">
        <StyledHtmlText
          htmlString={data?.about_me}
          id="freelancer-profile"
          needToBeShorten={true}
        />
      </div>
    </div>
  );

  const Categories = data?.skills && data?.skills?.length > 0 && (
    <div className="profile-detail-block">
      <div className="title fs-28 fw-400">Skill Categories</div>
      <div className="skills d-flex flex-wrap">
        {data?.skills?.map((skill: any) => {
          if (!skill.category_id) return null;
          return (
            <SkillItem key={skill.category_id}>
              <div>{skill.category_name}</div>
            </SkillItem>
          );
        })}
      </div>
    </div>
  );

  const Skills = data?.skills && data?.skills?.length > 0 && (
    <div className="profile-detail-block">
      <div className="title fs-28 fw-400">Skills</div>
      <div className="skills d-flex flex-wrap">
        {data?.skills?.map((skill: any) => {
          if (!skill.skill_id) return null;
          return (
            <SkillItem key={skill.skill_id}>
              <div>{skill.skill_name}</div>
            </SkillItem>
          );
        })}
      </div>
    </div>
  );

  const Languages = data?.languages && data?.languages?.length > 0 && (
    <div className="profile-detail-block">
      <div className="title fs-28 fw-400">Languages</div>
      <div className="skills d-flex flex-wrap">
        {data?.languages?.map((skill: any) => (
          <SkillItem key={skill.id}>
            <div>{skill.name}</div>
          </SkillItem>
        ))}
      </div>
    </div>
  );

  const Education = data?.is_agency === 0 &&
    data?.education &&
    data?.education?.length > 0 && (
      <div className="profile-detail-block">
        <div className="title fs-28 fw-400">Education</div>
        {data.education.map((eduItem) => (
          <div
            className="education-details d-flex mt-3"
            key={eduItem?.education_id}
          >
            <img
              src="/images/school.png"
              className="education-school-img"
              alt="education-img"
            />
            <div className="d-flex flex-column justify-content-center">
              <div className="course-name fs-20 fw-400">
                {convertToTitleCase(eduItem?.course_name)}
              </div>
              <div className="education-description fs-18 fw-400">
                {convertToTitleCase(eduItem?.school_name)}
              </div>
              {eduItem?.education_year && eduItem?.education_year !== '-' && (
                <div className="education-description fs-18 fw-400">
                  {eduItem?.education_year}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );

  const CoursesAndCertifications = data?.is_agency === 0 &&
    data?.certificate_course &&
    data?.certificate_course?.length > 0 && (
      <div className="course-certificate-block">
        <div className="title fs-28 fw-400">Courses and Certifications</div>
        {data.certificate_course?.map((courseItem: any) => (
          <div
            className={cns('courrse-certi-details d-flex flex-column', {
              'pointer box-shadow': courseItem?.certificate_link,
            })}
            key={courseItem?.course_id}
            onClick={() => openCertificate(courseItem?.certificate_link)}
          >
            <div className="course-name fs-20 fw-400">
              {convertToTitleCase(courseItem?.course_name)}
            </div>
            <div className="education-description fs-18 fw-400 mt-2">
              {convertToTitleCase(courseItem?.school_name)}
            </div>
          </div>
        ))}
      </div>
    );

  const UI = [
    AboutMe,
    Categories,
    Skills,
    Languages,
    Education,
    CoursesAndCertifications,
  ]
    .filter((uiElement) => uiElement)
    .map((uiElement, i, self) => (
      <>
        {uiElement}
        {self.length - 1 !== i && <Divider />}
      </>
    ));

  if (UI.length === 0) return <></>;

  return (
    <>
      <DetailsWrapper className="mt-4">{UI}</DetailsWrapper>
    </>
  );
};

export default FreelancerOtherDetails;
