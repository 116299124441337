import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  max-width: 1170px;
  .image-preview-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 20;
    .img-previewer-background {
      position: absolute;
      top: 0;
      left: 0;
    }

    .img-preview {
      width: 100%;
      height: 100%;
    }

    .img-previewer-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #000000ad;
    }
    audio.previewer-box {
      height: 50px !important;
      top: 40vh;
      background: none;
    }
    audio + .portfolio-close-btn {
      top: 36vh;
    }
    .previewer-box {
      position: fixed;
      top: 10vh;
      left: 10%;
      width: 80%;
      height: auto;
      background: #dfdfdf;
      border-radius: 6px;
      overflow: hidden;
      max-height: 80vh;
    }

    .previewer-box-pdf {
      position: fixed;
      top: 10vh;
      left: 10%;
      width: 80%;
      height: 100%;
      background: #dfdfdf;
      border-radius: 6px;
      overflow: hidden;
      max-height: 80vh;
    }

    .previewer-box.image {
      object-fit: contain;
    }

    .portfolio-close-btn {
      position: absolute;
      color: white;
      top: 6vh;
      left: 89.5%;
    }
  }
`;
