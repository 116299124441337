import React from 'react';
import cns from 'classnames';
import { Col, Row, FloatingLabel, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { StyledButton } from 'components/forms/Buttons';
import AuthLayout from 'components/Layout/AuthLayout';
import ErrorMessage from 'components/ui/ErrorMessage';
import LoadingButtons from 'components/LoadingButtons';
import { useAuth } from 'helpers/contexts/auth-context';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import ReactGA from 'react-ga';
import Checkbox from 'components/forms/Checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled.div`
  label {
    color: #000;
    font-weight: normal;
  }
`;

export default function Login() {
  const [isPasswordPreview, setIsPasswordPreview] = React.useState(false);
  const togglePasswordPreview = () => setIsPasswordPreview(!isPasswordPreview);
  const { signin, isLoading } = useAuth();
  const schema = yup.object({
    email_id: yup.string().required('Email is required.').email('Please enter a valid email.'),
    password: yup.string().required('Password is required.'),
    stay_signedin: yup.boolean().optional(),
  });

  const { register, handleSubmit, formState, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email_id: '',
      password: '',
      stay_signedin: false,
    },
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    ReactGA.event({
      category: 'User',
      action: 'Clicked Submit Button',
    });
    signin(data);
  };

  const { errors } = formState;

  return (
    <AuthLayout center small onlyhomebtn>
      <h1>Log in to ZehMizeh</h1>

      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel controlId="floatingInput" label="Email Address">
              <Form.Control type="email" placeholder="Email Address" {...register('email_id')} />
              <ErrorMessage>{errors.email_id?.message}</ErrorMessage>
            </FloatingLabel>
          </Col>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel controlId="floatingInput" label="Password" className="password-input">
              <span className="pointer" onClick={togglePasswordPreview}>
                <Eye className={cns('input-icon', { active: isPasswordPreview })} />
              </span>
              <Form.Control
                type={!isPasswordPreview ? 'password' : 'text'}
                placeholder="Password"
                {...register('password')}
              />
            </FloatingLabel>
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          </Col>
          <div className="d-flex mt-2 ps-3 flex-wrap">
            <StyledCheckbox>
              <Checkbox
                className="d-flex align-items-center gap-2"
                toggle={() => setValue('stay_signedin', !getValues()?.stay_signedin)}
                label="Stay Signed In"
              />
            </StyledCheckbox>
            <Link to="/forgot-password" className="forgot-password">
              Forgot password?
            </Link>
          </div>
        </Row>

        <StyledButton className="mt-5" style={{ padding: '1.125rem 5rem' }} type="submit" disabled={isLoading}>
          {isLoading ? <LoadingButtons /> : 'Log in'}
        </StyledButton>
        <h4 className="align-self-center mt-5">
          Don't have an account? Register{' '}
          <Link to="/register/employer" className="yellow-link">
            here!
          </Link>
        </h4>
      </Form>
    </AuthLayout>
  );
}
