import { useQuery } from 'react-query';
import { manageTemplate } from 'helpers/http/templates';

// This will fetch the template details
function useTemplate(post_template_id: string) {
  const { data, isLoading, refetch, isRefetching } = useQuery<
    { data: []; status: boolean; refetch: any; isRefetching: boolean },
    Error
  >('get-template-details', () =>
    manageTemplate({ action: 'get_template_detail', post_template_id })
  );
  return {
    details: data?.data ? data.data : [],
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useTemplate;
