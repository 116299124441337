import { useEffect, useMemo, useRef, useState, ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Nav, Navbar, Dropdown, CloseButton } from 'react-bootstrap';
import { HeaderWrapper, JewishText, Main } from './styled';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevronDown.svg';
import { StyledButton } from 'components/forms/Buttons';
import { useAuth } from 'helpers/contexts/auth-context';
import useLocationSearch from 'helpers/hooks/useSearchLocation';
import useResponsive from 'helpers/hooks/useResponsive';
import toast from 'react-hot-toast';
import { NotificationModal } from '../notification-dropdown/NotificationDropdown';
import cns from 'classnames';
import Pusher from 'pusher-js';
import { isUserStripeVerified, pusherApiKey, talkjsApiKey } from 'helpers/utils/helper';
import { fetchChatList } from '../../redux/slices/chatSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { ChatUser, WebhookMessageSent } from 'redux/slices/talkjs.interface';
import { fetchMyConversation, newMessageReciever, seenMessageAction } from '../../redux/slices/talkjsSlice';
import UserDropdownComp from 'components/user-dropdown/UserDropdown';
import { getNotifications } from 'helpers/http/notification';

let pusher;
const NAV_ITEMS = [
  {
    name: 'Home',
    path: '/home',
    clientPath: '',
  },
  {
    name: 'About Us',
    path: '/about-us',
    clientPath: '',
  },
];

const LOGGED_IN_NAV_ITEMS = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    clientPath: '/client/dashboard',
  },
  {
    name: 'My Projects',
    path: '/jobs',
    clientPath: '/client-jobs',
  },
  {
    name: 'Messages',
    path: '/messages-new',
    clientPath: '/messages-new',
  },
  {
    name: 'Transactions',
    path: '/payments',
    clientPath: '/payments',
  },
  {
    name: 'Help',
    path: '/support',
    clientPath: '/support',
  },
];

function SiteHeader() {
  const { isMobile, isDesktop, isLaptop, isTablet } = useResponsive();
  const { chatlist, loading, selectedConversation } = useSelector((state: RootState) => state.talkJsChat);
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [showExpanded, setShowExpanded] = useState<boolean>(false);
  const params = useLocationSearch();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (auth?.user) {
      getNotifications().then((res) => {
        const notifications = res?.notifications || [];
        const unreadCount = notifications.filter((notf) => !notf.is_seen).length;
        setNotificationCount(unreadCount);
      });
    }
  }, [auth?.user]);

  const [navOptions, setNavOptions] = useState(auth?.user?.first_name ? LOGGED_IN_NAV_ITEMS : NAV_ITEMS);

  const toggleNavbar = () => {
    !isDesktop && setShowExpanded(!showExpanded);
  };

  const displayUserName = () => {
    let finalString = '';
    if (auth?.user) {
      const firstName = auth?.user?.first_name;
      const lastName = auth?.user?.last_name;
      if (firstName?.length + lastName?.length > 12) {
        if (firstName?.length > 12) {
          finalString = firstName?.charAt(0).toLocaleUpperCase() + ' ' + lastName?.charAt(0).toLocaleUpperCase();
        } else {
          finalString = firstName;
        }
      } else {
        finalString = firstName + ' ' + lastName;
      }
    }
    return finalString;
  };

  const checkFreelancerAccountStatus = (e, user_type: string) => {
    if (user_type === 'freelancer') {
      e.preventDefault();
      if (auth?.user?.is_account_approved === null || typeof auth?.user?.is_account_approved === 'undefined') {
        toast.error('You cannot explore project details until your account is approved.');
      } else {
        toggleNavbar();
        navigate('/search?type=jobs');
      }
    } else {
      toggleNavbar();
      navigate('/search?type=freelancers');
    }
  };

  useEffect(() => {
    if (auth?.user) {
      if (auth?.user?.user_type === 'client') {
        const nav_options = [...navOptions];

        const flag = nav_options.map((nv) => nv.name).includes('Find Freelancers');
        if (!flag) {
          nav_options.splice(2, 0, {
            name: 'Find Freelancers',
            path: '/search?type=freelancers',
            clientPath: '/search?type=freelancers',
          });
          setNavOptions(nav_options);
        }
      }
    } else setNavOptions(NAV_ITEMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user]);

  //Pusher Integration

  const initiatePusher = useCallback(() => {
    if (!auth || !auth.user) return;

    if (pusher) {
      pusher.disconnect();
      pusher = null;
    }

    const pusher_api_key = pusherApiKey();
    pusher = new Pusher(pusher_api_key, {
      cluster: 'ap2',
    });

    // pusher.connection.bind('state_change', function (states) {
    //   const prevState = states.previous;
    //   const currState = states.current;
    // });

    const channel = pusher.subscribe('ZEHMIZEHCOMMON');

    channel.bind('message.sent', (payload: WebhookMessageSent) => {
      const index = chatlist.findIndex((chat) => chat.id === payload.conversationId);

      if (index === -1) {
        dispatch(fetchMyConversation(''));
      }

      if (
        index !== -1 &&
        auth.user.user_id !== payload.senderId &&
        selectedConversation?.id !== payload.conversationId
      ) {
        dispatch(newMessageReciever(payload));
      }
    });

    channel.bind('NEW_CONVERSATION', (payload: ChatUser) => {
      const { freelancerId, clientId } = payload.custom;
      if ([freelancerId, clientId].includes(auth.user.user_id)) {
        dispatch(fetchMyConversation(''));
      }
    });

    // channel.bind('message.read', (payload: WebhookMessageSent) => {
    //   const index = chatlist.findIndex((chat) => chat.id === payload.conversationId);
    //   console.log('message.read', auth.user.user_id, payload.senderId, index);
    //   if (index !== -1 && auth.user.user_id !== payload.senderId) {
    //     dispatch(seenMessageAction(payload));
    //   }
    // });

    // channel.bind('NEWMESSAGE', (newMessage: PusherNewMessage) => {
    //   if (newMessage._to_user_id === auth.user.user_id && !loading.list && !loading.message)
    //     dispatch(onNewMessage({ newMessage }));
    // });

    // channel.bind('REPLACECHATID', (chatIds: ReplaceChatId) => {
    //   const { remote_user_id } = chatIds;
    //   if (auth.user.user_id === remote_user_id) {
    //     setTimeout(() => {
    //       dispatch(replaceChatId(chatIds));
    //     }, 500);
    //   }
    // });

    // channel.bind('DELETECHATID', (data: DeleteChatId) => {
    //   const { remote_user_id, chat_id } = data;
    //   if (auth.user.user_id === remote_user_id) dispatch(deleteChatFromMessages({ chat_id }));
    // });

    // channel.bind('SEEN_MESSAGE', (data: SeenMessageResponse) => {
    //   if (auth.user.user_id !== data.seen_message_by) {
    //     dispatch(onSeenMessage(data));
    //   }
    // });
  }, [chatlist, selectedConversation]);

  useEffect(() => {
    if (!auth || !auth.user) return;

    const controller = dispatch(fetchMyConversation(''));

    return () => {
      controller.abort();
    };
  }, [auth]);

  useEffect(() => {
    initiatePusher();

    return () => {
      if (typeof pusher === 'function') {
        pusher.disconnect();
        pusher = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, chatlist, selectedConversation]);

  /*
    Freelancer filled all required details or not
    1. Headline added
    2. about me added
    3. skills added
    4. stripe verified
  */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requiredDetails = [
    !!auth?.user?.job_title,
    !!auth?.user?.about_me,
    auth?.user?.skills && auth?.user?.skills?.length > 0,
    auth?.user?.stp_account_status === 'verified',
  ];

  // Profile dropdown items based on user type
  const profileDropdownMenuItem =
    auth?.user?.user_type == 'freelancer'
      ? [
          {
            text: 'My Profile',
            link: '/freelancer/account/Profile',
          },
          { text: 'My Portfolio', link: '/freelancer/account/Portfolio' },
          { text: 'My Ratings', link: '/freelancer/account/Ratings' },
          {
            text: 'My Payment Details',
            link: '/freelancer/account/Payment%20Details',
          },
          {
            text: 'My Account Settings',
            link: '/freelancer/account/Account%20Settings',
          },
        ]
      : [
          { text: 'My Profile', link: '/client/account/profile' },
          { text: 'My Ratings', link: '/client/account/ratings' },
          { text: 'My Payment Details', link: '/client/account/payments' },
          { text: 'My Account Settings', link: '/client/account/settings' },
        ];

  const headerMessageForIncompleteProfileUI = useMemo(() => {
    // Filled out all required details and waiting for approval from admin
    if (requiredDetails.every((x) => x)) {
      return (
        <>
          <span>
            The required sections of your profile have been filled out. A ZMZ staff member will check your account for
            approval soon.
          </span>
          <span>
            If you're not approved within 1 business day, see this{' '}
            <Link
              className="text-primary"
              to={'https://intercom.help/zehmizehfaq/en/articles/9491354-why-hasn-t-my-account-been-approved-yet'}
              target="_blank"
            >
              list of reasons why accounts aren't approved
            </Link>
            . For more info on signing up, see our{' '}
            <Link className="text-primary" to="/support/faq/signing_up_as_a_freelancer">
              Signing Up FAQs
            </Link>
            .
          </span>
        </>
      );
    }

    // Some required information is missing
    if (!requiredDetails.every((x) => x)) {
      const totalCompleted = requiredDetails.filter((item) => !!item).length;
      const percentCompleted = (totalCompleted / requiredDetails.length) * 100;

      const finalRequirementArr = auth?.user?.stripe ? isUserStripeVerified(auth?.user?.stripe) : [];
      // Filled out everything but need to submit documents in stripe

      const stripeDocumentPending =
        requiredDetails.slice(0, 2).every((x) => x) &&
        finalRequirementArr?.length === 1 &&
        finalRequirementArr?.[0] === 'Document';

      const bankAccountPending =
        requiredDetails.slice(0, 2).every((x) => x) &&
        auth?.user?.stp_account_status === 'bank_account_pending' &&
        auth?.user?.stripe?.id;

      if (stripeDocumentPending) {
        return (
          <>
            <div>
              You're almost there! Upload a personal ID document to finish signing up for Stripe -{' '}
              <Link
                className="text-primary"
                to={'https://intercom.help/zehmizehfaq/en/articles/9491354-why-hasn-t-my-account-been-approved-yet'}
                target="_blank"
              >
                here!
              </Link>
            </div>
            <div className="mt-1">
              For a list of acceptable ID options, see{' '}
              <Link
                className="text-primary"
                target="_blank"
                rel="noreferrer"
                to="https://docs.stripe.com/acceptable-verification-documents"
              >
                this List.
              </Link>
            </div>
          </>
        );
      }

      // Filled out everything and finished stripe account. Just payment details are pending
      if (bankAccountPending) {
        return (
          <div>
            One last step! You need to add your bank account details so you can get paid... add them{' '}
            <Link className="text-primary" to="/freelancer/account/Payment%20Details">
              here!
            </Link>
          </div>
        );
      }

      // Profile is 75% completed and just one thing is pending
      if (percentCompleted === 75) {
        let firstLine: ReactElement;

        // Dont have headline
        if (!requiredDetails[0])
          firstLine = (
            <>
              <div>
                You're almost there! You just need to tell us what you do in your "Headline" section... fill that out{' '}
                <Link className="text-primary" to="/freelancer/account/Profile">
                  here!
                </Link>
              </div>
              <div className="mt-1">
                If you need some inspiration, you can see other freelancer profiles -{' '}
                <Link className="text-primary" to="/search?type=freelancers">
                  here!
                </Link>{' '}
                For more info on signing up, see our{' '}
                <Link className="text-primary" to="/support/faq/signing_up_as_a_freelancer">
                  Signing Up FAQs
                </Link>
                .
              </div>
            </>
          );

        // Dont have about me
        if (!requiredDetails[1])
          firstLine = (
            <>
              <div>
                You're almost there! You just need to tell us about yourself in your "About Me" section... fill that out{' '}
                <Link className="text-primary" to="/freelancer/account/Profile">
                  here!
                </Link>
              </div>
              <div className="mt-1">
                If you need some inspiration, you can see other freelancer profiles -{' '}
                <Link className="text-primary" to="/search?type=freelancers">
                  here!
                </Link>{' '}
                For more info on signing up, see our{' '}
                <Link className="text-primary" to="/support/faq/signing_up_as_a_freelancer">
                  Signing Up FAQs
                </Link>
                .
              </div>
            </>
          );

        // Dont have skills
        if (!requiredDetails[2])
          firstLine = (
            <>
              <div>
                You're almost there! You just need to tell us about your skills in your "Skills" section... fill that
                out{' '}
                <Link className="text-primary" to="/freelancer/account/Profile">
                  here!
                </Link>
              </div>
              <div className="mt-1">
                If you need some inspiration, you can see other freelancer profiles -{' '}
                <Link className="text-primary" to="/search?type=freelancers">
                  here!
                </Link>{' '}
                For more info on signing up, see our{' '}
                <Link className="text-primary" to="/support/faq/signing_up_as_a_freelancer">
                  Signing Up FAQs
                </Link>
                .
              </div>
            </>
          );

        // Dont have stripe account
        if (!requiredDetails[3])
          firstLine = (
            <div>
              You're almost there! You just need to activate your Stripe account in your "Payment Details" section...
              fill that out{' '}
              <Link className="text-primary" to="/freelancer/account/Payment%20Details">
                here!
              </Link>{' '}
              For more info on signing up, see our{' '}
              <Link className="text-primary" to="/support/faq/signing_up_as_a_freelancer">
                Signing Up FAQs
              </Link>
              .
            </div>
          );
        return firstLine;
      }
      return (
        <>
          <div>Your account is currently under review. You cannot apply for projects at this time.</div>
          <div className="mt-1">
            To be approved: Activate your Stripe account{' '}
            <Link className="text-primary" to="/freelancer/account/Payment%20Details">
              (here)
            </Link>{' '}
            and complete your profile page{' '}
            <Link className="text-primary" to="/freelancer/account/profile">
              (here).
            </Link>
          </div>
          <div className="mt-1">
            To see your progress, click the yellow 'Percentage Complete' bar in the bottom-left corner. For more info on
            signing up, see our <Link to="/support/faq/signing_up_as_a_freelancer">Signing Up FAQs</Link>.
          </div>
        </>
      );
    }
    return <></>;
  }, [auth?.user?.stp_account_status, auth?.user?.stripe, requiredDetails]);

  const unreadMessageCount = useMemo(() => {
    if (loading || chatlist.length === 0) return 0;

    return chatlist.reduce(function (a, b) {
      return a + b['unreadMessageCount'];
    }, 0);
  }, [chatlist, loading]);

  return (
    <Main>
      <HeaderWrapper>
        <Navbar expand="lg" className="navbar py-0" expanded={showExpanded} onToggle={toggleNavbar}>
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <Logo />
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="position-relative">
              {showExpanded ? (
                <CloseButton onClick={toggleNavbar} />
              ) : (
                <>
                  <span className="navbar-toggler-icon" />
                  {unreadMessageCount + notificationCount > 0 && (
                    <div
                      className="position-absolute"
                      style={{
                        top: '2px',
                        right: '2px',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#f2b420',
                      }}
                    />
                  )}
                </>
              )}
            </Navbar.Toggle>

            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="d-lg-block d-none divider" />

              {!isDesktop && (
                <div className="d-flex align-items-center justify-content-between">
                  {auth?.user && (
                    <>
                      <NotificationModal />
                      <UserDropdownComp
                        displayUserName={displayUserName}
                        profileDropdownMenuItem={profileDropdownMenuItem}
                        toggleNavbar={toggleNavbar}
                        auth={auth}
                      />
                    </>
                  )}
                  <JewishText className="jewish-text">בס"ד</JewishText>
                </div>
              )}

              {/* {/ Left side nav links /} */}
              <Nav className="me-auto">
                {navOptions.map((item) => (
                  <Link
                    to={auth?.user?.user_type == 'client' ? item?.clientPath : item?.path}
                    className={`nav-link fs-18 ${
                      window.location.pathname == item?.path || window.location.pathname == item?.clientPath
                        ? `fw-700`
                        : 'fw-400'
                    }`}
                    style={{ whiteSpace: 'nowrap' }}
                    key={item?.name}
                    onClick={toggleNavbar}
                  >
                    <div role="button">
                      <div className="d-flex align-items-center">
                        {item?.name}
                        {item.name === 'Messages' && unreadMessageCount !== 0 ? (
                          <span className="msg-count d-flex align-items-center justify-content-center ms-2 fs-sm fw-500">
                            {unreadMessageCount}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                ))}
              </Nav>

              {isDesktop && auth?.user && <NotificationModal />}

              {/* {/ Right side => If user logged in | If not logged in /} */}
              {auth?.user?.first_name ? (
                // When user is logged in
                <Nav className="loggedin-user-profile d-flex pointer align-items-center gap-2">
                  <div>
                    {auth.user.user_type === 'client' ? (
                      <Link
                        to="/post-new-job"
                        className={cns({
                          'mt-2 d-block order-1 my-2': !isDesktop && !isLaptop,
                        })}
                      >
                        <StyledButton padding={'0.875rem 2rem'} className="mx-1" variant={'primary'}>
                          Post Project
                        </StyledButton>
                      </Link>
                    ) : (
                      <Link to={params?.['client-home'] ? '/search?type=freelancers' : '/search?type=jobs'}>
                        <StyledButton
                          // nowrap
                          padding={'0.875rem 2rem'}
                          className={isMobile ? 'mt-3' : 'mx-1'}
                          variant={'primary'}
                          onClick={(e) => checkFreelancerAccountStatus(e, auth?.user?.user_type)}
                        >
                          {auth?.user?.user_type === 'freelancer' || params?.['freelancer-home']
                            ? 'Find Projects'
                            : 'Find Freelancers'}
                        </StyledButton>
                      </Link>
                    )}
                  </div>

                  {isDesktop && (
                    <UserDropdownComp
                      displayUserName={displayUserName}
                      profileDropdownMenuItem={profileDropdownMenuItem}
                      toggleNavbar={toggleNavbar}
                      auth={auth}
                    />
                  )}
                </Nav>
              ) : (
                // Todo: Need to refactor this for responsiveness
                // When user is not logged in
                <Nav onClick={toggleNavbar}>
                  <Link to="/login">
                    {isMobile ? (
                      <div className="nav-link fs-18">Log In</div>
                    ) : (
                      <StyledButton variant="transparent">Log In</StyledButton>
                    )}
                  </Link>
                  <Link to="/register/employer">
                    {isMobile ? (
                      <div className="nav-link fs-18">Sign Up</div>
                    ) : (
                      <StyledButton className={'mx-1'}>Sign Up</StyledButton>
                    )}
                  </Link>
                </Nav>
              )}
              {isDesktop && <JewishText className="jewish-text">בס"ד</JewishText>}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </HeaderWrapper>

      <div className="header-bottom-border" />

      {!!auth.user && auth.user?.user_type == 'freelancer' ? (
        <>
          {/*If account is under review then this banner will be displayed below the header */}
          {auth?.user?.is_account_approved === null || typeof auth?.user?.is_account_approved === 'undefined' ? (
            <div className="account-not-approved d-flex flex-column justify-content-center p-2">
              {/* Showing different text if freelancer has filled all required details and waiting for approval from admin */}
              {headerMessageForIncompleteProfileUI}
            </div>
          ) : null}

          {/* / If account is rejected by the admin then this banner will be displayed below the header / */}

          {auth?.user?.is_account_approved === 0 && (
            <div className="account-not-approved d-flex justify-content-center p-2 rejected">
              Your account application has been rejected by the admin. For more info, contact us{' '}
              <a className="text-primary" href="mailto: info@zehmizeh.com">
                &nbsp;here.
              </a>
              <Button variant="link" onClick={auth.signout}>
                Sign out
              </Button>
            </div>
          )}
          {auth?.user?.is_account_approved === 1 &&
          (auth?.user?.stp_account_status === 'pending' || auth.user?.stp_account_status === 'bank_account_pending') ? (
            <div className="account-not-approved d-flex flex-column justify-content-center p-2">
              <span>
                One more step! To be payable on ZehMizeh, activate your Stripe account and add your bank details -{' '}
                <Link className="text-primary" to={'/freelancer/account/Payment%20Details'}>
                  here!
                </Link>
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <>
          {(auth?.user && auth?.user?.account?.length > 0) ||
            (auth?.user?.carddata?.length === 0 && (
              <div className="account-not-approved d-flex flex-column justify-content-center p-2">
                <div>
                  For the security of our freelancers,{' '}
                  <b> clients cannot post new projects until they have added at least one payment method</b> to their
                  accounts. See your Payment Details page{' '}
                  <Link to="client/account/payments" className="faq-link text-decoration-underline">
                    here.
                  </Link>
                </div>
              </div>
            ))}
        </>
      )}
    </Main>
  );
}

export default SiteHeader;
