/*
 * This component is a dropdown of states based on the selected country
 */
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { MultiSelectCustomStyle } from './styles';

type Props = {
  placeholder?: string;
  isMulti?: boolean;
  // When putting {label: sringl value:any} here, it's not accepting it
  loadOptions: (e: string) => any;
  // This could come as string or object also
  selectedValue?: any;
  onSelect: (item: { label: string; value: any }) => void;
  getOptionValue?: (item: any) => string;
  getOptionLabel?: (item: any) => string;
};

const multiSelectProps = {
  closeMenuOnSelect: true,
  styles: MultiSelectCustomStyle,
};

const CustomSelect = ({
  placeholder,
  isMulti = true,
  loadOptions,
  selectedValue,
  onSelect,
  getOptionValue,
  getOptionLabel,
}: Props) => {
  return (
    <div>
      <AsyncSelect
        {...multiSelectProps}
        isMulti={isMulti}
        placeholder={placeholder || 'Select'}
        components={{ NoOptionsMessage }}
        loadOptions={loadOptions}
        defaultOptions
        value={selectedValue}
        onChange={(options) => onSelect(options)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    </div>
  );
};

export default CustomSelect;

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div>
        {props?.selectProps?.inputValue
          ? `No result found for '${props?.selectProps?.inputValue}'`
          : 'Search...'}
      </div>
    </components.NoOptionsMessage>
  );
};
