import styled from 'styled-components';

export const ChipContainer = styled.div<{
  $active: boolean;
  $showCursor: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$showCursor ? 'pointer' : 'default')};
  background-color: ${(props) =>
    props.$active ? props.theme.colors.primary : props.theme.colors.lightGray};
  color: #333;
  border-radius: 16px;
  padding: 4px 12px;
  margin: 6px;
  .select-btn,
  .delete-btn {
    border: none;
    background: transparent;
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
  .delete-btn {
    margin-left: 4px;
  }
  .select-btn {
    margin-right: 4px;
  }
  .label-text {
    text-transform: capitalize;
  }
`;
