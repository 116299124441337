import { breakpoints } from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

const progressBarWidth = '4px';

export const StepperContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  position: relative;
  flex-direction: row;
  margin-bottom: 3rem;
  .progress-background,
  .progress {
    background-color: ${(props) => props.theme.colors.lightGray};
    content: '';
    height: ${progressBarWidth};
    width: 100%;
    left: 0px;
    position: absolute;
    top: 16px;
    z-index: 10;
    transition: 0.4s ease;
  }
  .progress {
    background-color: ${(props) => props.theme.colors.yellow};
  }
  .step-wrapper {
    z-index: 11;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    .circle {
      align-items: center;
      background-color: ${(props) => props.theme.colors.white};
      border: 3px solid ${(props) => props.theme.colors.lightGray};
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      transition: 0.4 ease;
      width: 40px;
      &.active {
        border-color: ${(props) => props.theme.colors.yellow};
      }
    }
    .step-label {
      font-weight: bold;
      position: absolute;
      top: 44px;
      left: -40px;
      width: 300%;
      @media screen and (max-width: 450px) {
        display: none;
      }
    }
  }
  @media ${breakpoints.mobile} {
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
`;
