import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntercomProvider } from 'react-use-intercom';
import { MyGlobalStyle } from './styles/GlobalStyle';
import { myTheme } from './styles/theme';
import { AuthProvider } from 'helpers/contexts/auth-context';
import { CssUtils } from 'styles/CssUtils';
import { Outlet } from 'react-router-dom';
import AOS from 'aos';
import { useLocation } from 'react-router-dom';
import AppLayout from 'components/Layout/AppLayout';
import ReactGA from 'react-ga';
import { MetaTags } from 'components/MetaTags';
import { isStagingEnv } from 'helpers/utils/helper';
import { setCookie } from 'helpers/utils/cookieHelper';

const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

function App() {
  const location = useLocation();
  const [autoBoot, setAutoBoot] = useState<boolean>(false);

  /* START ----------------------------------------- Web spell checker scripts */
  // useEffect(() => {
  //   if (
  //     !process.env.REACT_APP_WSC_WPROOFREADER_SERVICE_ID ||
  //     process.env.REACT_APP_WSC_WPROOFREADER_SERVICE_ID === 'null'
  //   )
  //     return;

  //   const configScript = document.createElement('script');
  //   configScript.textContent = `
  //     window.WEBSPELLCHECKER_CONFIG = {
  //       autoSearch: true,
  //       autocorrect: true,
  //       autocomplete: true,
  //       serviceId: '${process.env.REACT_APP_WSC_WPROOFREADER_SERVICE_ID}',
  //     };
  //   `;
  //   document.body.appendChild(configScript);

  //   const wscBundleScript = document.createElement('script');
  //   wscBundleScript.src =
  //     'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js';
  //   document.body.appendChild(wscBundleScript);

  //   // Cleanup function to remove scripts on component unmount (optional)
  //   return () => {
  //     document.body.removeChild(configScript);
  //     document.body.removeChild(wscBundleScript);
  //   };
  // }, []);
  /* END ------------------------------------------- Web spell checker scripts */

  useEffect(() => {
    setAutoBoot(!isStagingEnv());
  }, []);

  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99,
      // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });

    if (!isStagingEnv()) {
      initGA();
    }

    logPageView();
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  /* START ----------------------------------------- Get utm params and set it to cookie */
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const utmParams = Object.keys(params).reduce((acc, key) => {
    if (key.startsWith('utm_')) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
  if (Object.keys(utmParams).length > 0) setCookie('utm_info', JSON.stringify(utmParams));
  /* END ------------------------------------------- Get utm params and set it to cookie */

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={autoBoot}>
      <MetaTags />
      <AuthProvider>
        <ThemeProvider theme={myTheme}>
          <AppLayout>
            <Outlet />
          </AppLayout>
          <MyGlobalStyle />
          <CssUtils theme={undefined} />
        </ThemeProvider>
      </AuthProvider>
    </IntercomProvider>
  );
}

export default App;
