import { Form, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
const SearchContainer = styled.div`
  max-width: 574px;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
  padding: 0rem 0.875rem;
  border-radius: 0.5rem;
  border: 1px solid #d9d9d9;
  transition: all 0.25s;
  &:hover {
    transform: scale(1.035);
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04),
      0px 0 0 1px rgba(0, 0, 0, 0.24);
    border-color: rgba(0, 0, 0, 0.24);
  }
  input.form-control {
    border: none;
    height: 40px;
    box-shadow: none !important;
    margin-top: 0;
    font-size: 1rem;
    font-weight: 400;
  }
`;
function Search({
  placeholder = 'Search',
  onChange,
  value,
  containerClassName,
  fetching,
  searchIcon,
}: {
  placeholder?: string;
  onChange?: React.ChangeEvent<HTMLInputElement> | any;
  value?: string;
  containerClassName?: string;
  fetching?: boolean;
  searchIcon?: React.ChangeEvent<HTMLInputElement> | any;
}) {
  if (onChange) {
    return (
      <SearchContainer className={containerClassName}>
        {searchIcon ? searchIcon : <SearchIcon />}
        <Form.Control
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
        />
        {fetching && <Spinner animation="border" size="sm" />}
      </SearchContainer>
    );
  }
  return (
    <SearchContainer className={containerClassName}>
      {searchIcon ? searchIcon : <SearchIcon />}
      <Form.Control placeholder={placeholder} />
    </SearchContainer>
  );
}

export default Search;
