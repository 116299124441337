import moment from 'moment';
import { StatusBadge } from 'components/styled/Badges';
import { StyledButton } from 'components/forms/Buttons';
import { changeStatusDisplayFormat } from 'helpers/utils/misc';
import { JOBS_STATUS } from '../consts';
import { ReactComponent as ApplicantsIcon } from 'assets/icons/applicants.svg';
import { useMemo } from 'react';

type Props = {
  item: any;
  onInvite: () => void;
};
const StatusAndDateSection = ({ item, onInvite }: Props) => {
  const handleInvite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onInvite();
  };

  const status = useMemo(() => {
    if (item?.status) {
      /* checking if the job got expired. */
      if (item?.attributes && item?.attributes?.isExpired && item?.status === 'deleted')
        return {
          text: 'Expired',
          color: JOBS_STATUS[item?.status]?.color,
        };

      switch (item?.status) {
        case 'active':
          return {
            text: 'Work in Progress',
            color: JOBS_STATUS[item?.status]?.color,
          };
        case 'draft':
          return { text: 'Drafts', color: JOBS_STATUS[item?.status]?.color };
        case 'prospects':
          return {
            text: 'Posted Projects',
            color: JOBS_STATUS[item?.status]?.color,
          };
        case 'deleted':
          return {
            text: 'Closed Before Hiring Freelancer',
            color: JOBS_STATUS[item?.status]?.color,
          };
        default:
          return {
            text: changeStatusDisplayFormat(item?.status),
            color: JOBS_STATUS[item?.status]?.color || 'yellow',
          };
      }
    }
    return undefined;
  }, [item?.status]);

  return (
    <div className="d-flex flex-column justify-content-between align-items-lg-end align-items-start flex-2 gap-3">
      {status && (
        <div className="status-row d-flex align-items-center flex-wrap gap-3">
          <StatusBadge color={status.color}>{status.text}</StatusBadge>

          {item?.status == 'prospects' && (
            <div className="applicants d-flex align-items-center">
              <ApplicantsIcon />
              <div className="fs-20 fw-400">{item?.applicants}</div>
              <div className="fs-1rem fw-300"> Proposals</div>
            </div>
          )}
        </div>
      )}
      {item?.status == 'closed' && (
        <div className="fs-20 fw-400 light-text">
          {moment(item?.job_start_date).format('MMM DD, YYYY') +
            ' - ' +
            moment(item?.job_end_date).format('MMM DD, YYYY')}
        </div>
      )}
      {item.status == 'active' && (
        <div className="fs-20 fw-400 light-text">{moment(item?.job_start_date).format('MMM DD, YYYY')} - Present</div>
      )}
      {(item.status === 'closed' || item.status === 'active') && (
        <StyledButton padding="1rem 2rem" variant="outline-dark" onClick={handleInvite}>
          Invite to another project
        </StyledButton>
      )}
    </div>
  );
};

export default StatusAndDateSection;
