import ReviewContent from 'components/ReviewContent';
import BlurredImage from 'components/ui/BlurredImage';
import { IClientDetails } from 'helpers/types/client.type';
import { separateValuesWithComma } from 'helpers/utils/misc';
import styled from 'styled-components';

const Wrapper = styled.div`
  .review-content {
    background: ${(props) => props.theme.colors.white};
    padding: 1.875rem;
    border-radius: 1rem;
    gap: 1.875rem;
    margin-top: 1.125rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 31px;
  }
  .client-details {
    gap: 1.25rem;
  }
  .divider {
    margin: 0rem 0.625rem;
    height: 8.5rem;
    width: 1px;
    background: ${(props) => props.theme.colors.black};
  }
  .given-ratings {
    gap: 4px;
    .stars {
      gap: 1px;
    }
    .given-rating-count {
      font-style: italic;
    }
  }
`;

type Props = {
  reviews: IClientDetails['review'];
};

export const Ratings = ({ reviews }: Props) => {
  return (
    <Wrapper>
      {reviews && reviews?.length > 0 ? (
        reviews.map((review) => (
          <div
            key={review.feedback_id}
            className="review-content d-flex flex-wrap align-items-start"
          >
            <div>
              <span className="fs-18">{review.job_title}</span>
              <div className="client-details d-flex align-items-center mt-3">
                <BlurredImage
                  src={review?.user_image || '/images/default_avatar.png'}
                  height="5.25rem"
                  width="5.25rem"
                />
                <div>
                  <div className="fs-18 fw-400 text-capitalize">
                    {review?.first_name} {review?.last_name}
                  </div>
                  <div className="client-location fs-18 fw-400 light-text">
                    {separateValuesWithComma([
                      review?.location?.state,
                      review?.location?.country_name,
                    ])}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider d-none d-lg-block" />
            <div className="flex-1">
              <ReviewContent review={review} />
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center mt-4">
          <span className="fs-18 font-weight-bold">
            It appears there are no ratings for you at this time.
          </span>
        </div>
      )}
    </Wrapper>
  );
};
