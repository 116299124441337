import { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import InfoEditModal from './edit-info/InfoEditModal';
import EditPictureModal from 'components/ui/EditPictureModal';
import { separateValuesWithComma } from 'helpers/utils/misc';
import { editUser } from 'helpers/http/auth';
import { transition } from 'styles/transitions';
import { ReactComponent as LocationIcon } from 'assets/icons/location-blue.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EditBlueIcon } from 'assets/icons/edit-blue-outline.svg';

const StyledBanner = styled.div`
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  margin: 2.25rem 0rem 0rem 0rem;
  border-radius: 12px;
  word-break: break-word;
  .client-profile-name--company {
    word-break: break-word;
  }
  .profile__img {
    height: 9.5625rem;
    width: 9.5625rem;
    min-width: 9.5625rem;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray5};
    position: relative;
  }
  .img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .edit-picture-btn {
    position: absolute;
    background: #f7faff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    bottom: 0px;
    right: 0px;
    ${() => transition()}
  }
  .client-profile--company {
    color: #999999;
  }
  .client-profile--contact {
    opacity: 0.8;
  }
  .divider {
    width: 1px;
    background: #d6d6d6;
    height: 20px;
  }
  .client-profile--location {
    background-color: ${(props) => props.theme.colors.body2};
    border-radius: 2rem;
    padding: 0.375rem 0.875rem;
    float: left;
  }
  .edit-btn {
    border: 1px solid #f2b420;
    padding: 0.75rem 1.25rem;
    border-radius: 1.75rem;
    background: #f2b420;
    color: white;
    ${() => transition()};
  }
`;

const ProfileBanner = ({
  data,
  refetch,
}: {
  data: any;
  refetch: () => void;
}) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showEditPictureModal, setShowEditPictureModal] =
    useState<boolean>(false);

  const toggleEditModal = () => {
    /* This function will toggle the edit modal */
    setShowEditModal(!showEditModal);
  };

  const onUpdate = () => {
    /* This function will close the modal and refetch the profile data */
    refetch();
  };

  const togglePictureModal = () => {
    setShowEditPictureModal(!showEditPictureModal);
  };

  /** @function updateUserProfile
   * This function will update user's profile picture
   * @param: {string} url - url of the image
   */
  const updateUserProfile = (url: string) => {
    const body: any = {
      user_image: url,
    };
    const promise = editUser(body);
    toast.promise(promise, {
      loading: 'Updating your details - please wait...',
      success: (res) => {
        setShowEditPictureModal(false);
        refetch();
        return res.message;
      },
      error: (err) => {
        return err?.response?.data?.message || 'error';
      },
    });
  };

  return (
    <StyledBanner className="p-2">
      <Row className="p-4 g-3">
        <Col md="10">
          <Row className="g-3">
            <Col
              md="3"
              className="d-flex justify-content-center align-items-center"
            >
              <div
                className="profile__img pointer"
                onClick={togglePictureModal}
              >
                <img
                  className="img"
                  src={data?.user_image || '/images/default_avatar.png'}
                  alt="freelancer-profile"
                />
                <div className="edit-picture-btn d-flex align-items-center justify-content-center">
                  <EditBlueIcon />
                </div>
              </div>
            </Col>

            <Col md="9">
              <div className="client-profile-name--company">
                <div className="fs-28 fw-400 text-capitalize">
                  {data?.first_name} {data?.last_name}
                </div>
                <div className="client-profile--company fs-18 fw-400 mt-2">
                  {data?.company_name ? <div>{data?.company_name}</div> : null}
                </div>
              </div>

              {(data?.location?.state || data?.location?.country_name) && (
                <div className="client-profile--location mt-3 d-flex align-items-center gap-1">
                  <LocationIcon />
                  <div className="fs-1rem fw-400">
                    {separateValuesWithComma([
                      data?.location?.state,
                      data?.location?.country_name,
                    ])}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>

        <Col>
          {/* Edit profile button */}
          <div className="d-flex justify-content-md-end">
            <div
              className="edit-btn d-flex align-items-center gap-2 pointer"
              onClick={toggleEditModal}
            >
              <EditIcon stroke="#fff" />
              <div className="fs-1rem fw-400">Edit</div>
            </div>
          </div>
        </Col>
      </Row>

      <EditPictureModal
        show={showEditPictureModal}
        onUpdate={updateUserProfile}
        onClose={togglePictureModal}
        profilePic={data?.user_image}
      />

      {/* Edit profile modal */}
      <InfoEditModal
        show={showEditModal}
        onClose={toggleEditModal}
        data={data}
        onUpdate={onUpdate}
      />
    </StyledBanner>
  );
};

export default ProfileBanner;
