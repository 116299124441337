export const MultiSelectCustomStyle = (borderColor: string) => {
  return {
    control: (base: any) => ({
      ...base,
      minHeight: 60,
      border: `1px solid ${borderColor ? borderColor : 'ligthgrey'}`,
      borderRadius: '7px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    multiValue: () => {
      return {
        margin: '5px 10px 5px 0px',
        borderRadius: 6,
        backgroundColor: 'rgba(209, 229, 255, 0.4)',
        display: 'flex',
      };
    },
    multiValueLabel: () => ({
      margin: 5,
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'rgba(209, 229, 255,1)',
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10,
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: state.isSelected ? 'rgba(209, 229, 255,1)' : 'white',
      color: '#000',
      padding: '1rem 1rem',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'rgba(209, 229, 255,1)',
      },
    }),
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '8px',
      },
      '::-webkit-scrollbar-thumb': {
        height: '50px',
      },
    }),
  };
};
