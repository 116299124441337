import { MainLayout, Card, NewJobOption } from './postJob.styled';
import { ChooseTemplateOrDraft } from './steps/ChooseTemplateOrDraft';
import ProposalExistsModal from 'components/invite-flow-modals/ProposalExistsModa';
import { Col, Row } from 'react-bootstrap';
import { POST_JOB_OPTIONS } from 'helpers/const/postJobOptions';
import { usePostJobContext } from './context';
import { ProjectDescription } from './steps/ProjectDescription';
import { ProjectPayment } from './steps/ProjectPayment';
import { ProjectPreferences } from './steps/ProjectPreferences';
import { ProjectTiming } from './steps/ProjectTiming';
import { Skills } from './steps/Skills';
import { FooterButtons } from './partials/FooterButtons';
import { POST_JOB_STEPS } from 'helpers/const/constants';
import { useParams } from 'react-router-dom';
import { TEditJobTemplatePathParams } from 'helpers/types/pathParams.type';
import { Stepper } from 'components/Stepper';
import useResponsive from 'helpers/hooks/useResponsive';
import BackButton from 'components/ui/BackButton';
import classNames from 'classnames';

export default function PostJob() {
  const { id, type } = useParams<TEditJobTemplatePathParams>();
  const {
    step,
    setStep,
    selectedOption,
    setSelectedOption,
    proposalExistModal,
    setProposalExistModal,
    selectedPost,
    setSelectedPost,
  } = usePostJobContext();

  const { isMobile } = useResponsive();

  const ChooseOption = (
    <div className="container text-center">
      <h1>What would you like to do?</h1>
      <div className="d-flex justify-content-center flex-wrap">
        <Row>
          {POST_JOB_OPTIONS.map((item) => (
            <Col key={item.id} lg={4} className="d-flex justify-content-center align-items-center">
              <NewJobOption
                active={selectedOption === item.key}
                onClick={() => {
                  setSelectedOption(item.key);
                }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon-style">{item.icon}</div>
                <div className="option-title fw-400">{item.label}</div>
              </NewJobOption>
            </Col>
          ))}
        </Row>
      </div>
      <FooterButtons />
    </div>
  );

  const StepUI = () => {
    switch (step) {
      case POST_JOB_STEPS.CHOOSE_TEMPLATE_OR_DRAFT.number:
        return <ChooseTemplateOrDraft />;
      case POST_JOB_STEPS.PROJECT_DESCRIPTION.number:
        return <ProjectDescription />;
      case POST_JOB_STEPS.SKILLS.number:
        return <Skills />;
      case POST_JOB_STEPS.PROJECT_TIMING.number:
        return <ProjectTiming />;
      case POST_JOB_STEPS.PROJECT_PREFERENCES.number:
        return <ProjectPreferences />;
      case POST_JOB_STEPS.PROJECT_PAYMENT.number:
        return <ProjectPayment />;
      default:
        return ChooseOption;
    }
  };

  const HeaderUI = () => {
    const heading = <h1>{Object.values(POST_JOB_STEPS).find((x) => x.number === step)?.label || ''}</h1>;
    if (step <= 0) return <></>;

    const headingWrapper = (text: string, subText: string = '') => {
      return (
        <div>
          {heading}
          {!!subText && <p>{subText}</p>}
        </div>
      );
    };

    if (id || type) {
      // if it has job and id then it means its for edit
      if (type === 'job' && id) {
        return headingWrapper(`Edit Project Details - Page ${step}/5`);
      }
      // only template sends type create
      if (type === 'create') {
        return headingWrapper(`Create Template - Page ${step}/5`);
      }
      // if it has template and id then edit template
      if (type === 'template' && id) {
        return headingWrapper(`Edit Template - Page ${step}/5`);
      }
    }
    if (selectedOption === 'new-job') {
      return headingWrapper(`Post Project - Page ${step}/5`);
    }
    if (selectedOption === 'draft') {
      return headingWrapper(
        `Edit Draft - Page ${step}/5`,
        'Review the draft. Make any adjustments necessary for your project.'
      );
    }
    if (selectedOption === 'template') {
      return headingWrapper(
        `Post Template - Page ${step}/5`,
        "Review the template's default settings. Make any adjustments necessary for your project."
      );
    }
  };

  const steps = { ...POST_JOB_STEPS };
  delete steps.CHOOSE_TEMPLATE_OR_DRAFT;

  return (
    <MainLayout>
      <BackButton className={classNames({ 'ms-3': isMobile })} />
      <Card small={false} className="text-center">
        {step > 0 && (
          <Stepper activeStep={step} setActiveStep={(value) => setStep(value)} steps={Object.values(steps)} />
        )}
        {HeaderUI()}
        {StepUI()}
      </Card>

      {/* START ----------------------------------------- Proposal exist modal */}
      <ProposalExistsModal
        job_post_id={selectedPost?.job_post_id}
        show={proposalExistModal}
        toggle={() => {
          setSelectedPost(undefined);
          setProposalExistModal((prev) => !prev);
        }}
      />
      {/* END ------------------------------------------- Proposal exist modal */}
    </MainLayout>
  );
}
