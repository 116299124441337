import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  h1.title {
    font-size: 2rem;
    font-weight: 700;
  }
  .heading {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .email-link {
    color: ${(props) => props.theme.colors.yellow};
  }
  .img-wrapper {
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 1rem;
  }
  .img {
    max-width: 50%;
  }
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
  min-height: 70vh;
`;

function CustomerSupport() {
  useStartPageFromTop();
  return (
    <HeaderWrapper>
      <Wrapper>
        <div className="mt-5 pb-5">
          <h1 className="title mb-5">Customer Support</h1>
          <div className="d-flex justify-content-center img-wrapper">
            <img src="/images/support.png" className="img" alt="support" />
          </div>
          <div className="heading mt-5">We'd love to hear from you!</div>

          <p className="mt-2 fs-18 fw-400">
            Please don't hesitate to reach out to{' '}
            <a
              className="email-link"
              href="mailto:info@zehmizeh.com"
              target="blank"
            >
              info@zehmizeh.com
            </a>{' '}
            with any questions.
          </p>
          <p className="mt-2 fs-18 fw-400">
            If you already have an account, you can contact the staff by
            submitting a general inquiry. Just sign in and navigate to the
            “Help” page from the menu at the top of the page. Submit the details
            of your question on the “General Inquiry” page… ZMZ staff should get
            back to you soon!
          </p>
          {/* <p className="mt-2 fs-18 fw-400">
            Please send us an email at{' '}
            <a
              className="email-link"
              href="mailto:info@zehmizeh.com"
              target="blank"
            >
              info@zehmizeh.com
            </a>{' '}
            for all your inquiries.
          </p> */}
        </div>
      </Wrapper>
    </HeaderWrapper>
  );
}

export default CustomerSupport;
