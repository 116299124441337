/*
 * This component will display the reviews given to the freelancer by other clients
 */

import styled from 'styled-components';
import { ReactComponent as StarIcon } from 'assets/icons/star-yellow.svg';
import BlurredImage from 'components/ui/BlurredImage';
import { convertToTitleCase } from 'helpers/utils/misc';
import { useQueryData } from 'helpers/hooks/useQueryData';
import { useParams } from 'react-router-dom';
import { TViewFreelancerProfilePathParams } from 'helpers/types/pathParams.type';
import { queryKeys } from 'helpers/const/queryKeys';
import { IFreelancerDetails } from 'helpers/types/freelancer.type';
import { useState } from 'react';
import { StyledButton } from 'components/forms/Buttons';

const ReviewsWrapper = styled.div`
  padding: 3.25rem;
  border-radius: 0.875rem;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.04);
  margin-top: 28px;
`;

const JobItemWrapper = styled.div`
  padding: 2.25rem;
  border-radius: 1.25rem;
  border: ${(props) => `1px solid ${props.theme.colors.gray5}`};
  margin-top: 1.25rem;
  .job-description {
    font-style: italic;
    opacity: 0.8;
  }
  .job-details-attribute {
    margin-right: 4.6875rem;
  }
  .attribute-label {
    opacity: 0.5;
    margin-bottom: 3px;
  }
  .line-height-160 {
    line-height: 160%;
  }
  .ratings {
    background: ${(props) => props.theme.colors.body};
    width: max-content;
    padding: 0.875rem 1.25rem;
    border-radius: 2rem;
  }
  .job-client-details {
    gap: 0.75rem;
  }
  .elements-gap {
    gap: 1.5rem;
  }
  .light-text {
    opacity: 0.5;
  }
`;

/* Main component of the file */

export const JobRatings = () => {
  const { freelancerId } = useParams<TViewFreelancerProfilePathParams>();
  const { data: freelancerData } = useQueryData<IFreelancerDetails>(queryKeys.getFreelancerDetails(freelancerId)) || {};

  return (
    <ReviewsWrapper id="profile-ratings">
      <div className="profile-detail-block">
        <div className="title fs-28 fw-400">
          Ratings {freelancerData?.completedJobDetail?.length === 0 && '(None Yet)'}
        </div>

        {/* List of completed jobs by thr freelancer */}
        {freelancerData?.completedJobDetail?.length > 0 && <Jobs jobsData={freelancerData.completedJobDetail} />}
      </div>
    </ReviewsWrapper>
  );
};

const Jobs = ({ jobsData }: { jobsData: IFreelancerDetails['completedJobDetail'] }) => {
  const initialCount = 2;
  const [count, setCount] = useState(initialCount);

  const toggleCount = () => {
    setCount(count === initialCount ? jobsData?.length : initialCount);
  };

  return (
    <>
      {jobsData?.slice(0, count)?.map((item) => (
        <JobItemWrapper key={item.job_post_id}>
          <div className="job-title line-height fs-20 fw-700">{convertToTitleCase(item.job_title)}</div>
          <div className="d-flex justify-content-between align-items-center flex-wrap elements-gap mt-4">
            <div className="ratings">
              <Ratings ratings={item.rate} />
            </div>
            <div className="job-client-details d-flex align-items-center">
              <div className="light-text">From: </div>
              <BlurredImage
                src={item?.user_image || '/images/default_avatar.png'}
                height="38px"
                width="38px"
                allowToUnblur={false}
              />
              <div className="text-capitalize">
                {item?.first_name} {item?.last_name}
              </div>
            </div>
          </div>
          <div className="job-description line-height fs-18 fw-300 mt-4">"{item.description}"</div>
        </JobItemWrapper>
      ))}
      {jobsData?.length > initialCount && (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <StyledButton variant="outline-dark" onClick={toggleCount}>
            See {count !== initialCount ? 'Less' : 'More'}
          </StyledButton>
        </div>
      )}
    </>
  );
};

const Ratings = ({ ratings }: { ratings: number }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {Array(Math.ceil(ratings))
        .fill(null)
        .map((_, i) => (
          <StarIcon key={i} />
        ))}
      <div className="mx-1">{ratings?.toFixed(1)}</div>
    </div>
  );
};
