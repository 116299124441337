import { useNavigate, useParams } from 'react-router-dom';
import * as S from './style';
import { useEffect, useState } from 'react';
import { getProposalDetails } from 'helpers/http/proposals';
import Loader from 'components/Loader';
import { Form } from 'react-bootstrap';
import { VscSend } from 'react-icons/vsc';
import { getJobDetails } from 'helpers/http/jobs';
import BackButton from 'components/ui/BackButton';
import { goBack } from 'helpers/utils/goBack';

const ProposalDiscussion = () => {
  const { proposal_id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const fetchProposalDetails = async () => {
    // fetching proposal details
    try {
      const payload: any = {};
      setLoading(true);
      const { data: propdt, status } = await getProposalDetails(proposal_id);
      if (!status) return;
      payload.name = `${propdt.first_name} ${propdt.last_name}`;

      // const { data: userdt } = await getUserDetailsById(propdt.user_id);
      // payload.user = userdt;

      const { data: jobdt } = await getJobDetails(propdt._job_post_id);
      payload.job_title = jobdt.job_title;

      setData(payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (proposal_id) fetchProposalDetails();
  }, [proposal_id]);

  if (loading) return <Loader />;

  return (
    <S.Wrapper>
      <BackButton className="mb-2" onBack={() => goBack(navigate)} />
      <S.ChatBoxWrapper>
        <S.ChatHeader>
          <p>{data?.name}</p>
          <p>{data?.job_title}</p>
          <div className="actions">
            <p>Accept</p>
            <p>Decline</p>
          </div>
        </S.ChatHeader>
        <S.MessageWrapper></S.MessageWrapper>
        <S.ChatInputWrapper>
          <Form.Control
            placeholder="Write your message..."
            className="form-control"
          />
          <VscSend size={100} />
        </S.ChatInputWrapper>
      </S.ChatBoxWrapper>
    </S.Wrapper>
  );
};

export default ProposalDiscussion;
