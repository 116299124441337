import { useMemo } from 'react';
import moment from 'moment';
import { ReactComponent as StarIcon } from 'assets/icons/star-yellow.svg';
import BlurredImage from 'components/ui/BlurredImage';
import { numberWithCommas, showFormattedBudget } from 'helpers/utils/misc';

const ListingFooter = ({ item }: any) => {
  const dateLabel = useMemo(() => {
    const status = item.status;
    if (['prospects', 'deleted'].includes(status)) {
      return 'Posted on: ';
    } else if (status === 'draft') {
      return 'Created on: ';
    } else if (status == 'active' || status == 'closed') {
      return 'Paid: ';
    } else return '';
  }, [item?.status]);

  return (
    <div>
      <div className="listing__item-other-details d-flex align-items-end flex-wrap justify-content-between">
        <div className="budget-details d-flex align-items-center flex-wrap gap-3">
          <div className="d-flex align-items-center g-1 flex-wrap">
            <label className="budget-label fs-20 fw-400">Budget:</label>
            <div className="budget-value fs-20 fw-400">
              {item?.status === 'active' || item?.status === 'closed' ? (
                <>
                  {numberWithCommas(item?.proposal?.approved_budget?.amount, 'USD')}
                  {item?.proposal?.approved_budget?.type === 'hourly' ? '/hr' : ''}{' '}
                </>
              ) : item?.budget?.isProposal ? (
                'Open To Proposals'
              ) : item?.budget ? (
                showFormattedBudget(item?.budget)
              ) : (
                '-'
              )}
            </div>
          </div>

          <Divider />

          {/* START ----------------------------------------- Started on */}
          <div className="d-flex align-items-center g-1 flex-wrap">
            <div className="budget-label fs-20 fw-400">{dateLabel}</div>
            <div className="budget-value fs-20 fw-400">
              {item?.status == 'active' || item?.status == 'closed'
                ? item?.paid
                  ? numberWithCommas(item?.paid, 'USD')
                  : '$0'
                : moment(item?.date_created).format('MMM DD, YYYY')}
            </div>
          </div>
          {/* END ------------------------------------------- Started on */}

          {/* START ----------------------------------------- Closed on */}
          {item?.status === 'deleted' && item?.date_modified && (
            <>
              <Divider />
              <div className="d-flex align-items-center g-1 flex-wrap">
                <div className="budget-label fs-20 fw-400">Closed On: </div>
                <div className="budget-value fs-20 fw-400">{moment(item.date_modified).format('MMM DD, YYYY')}</div>
              </div>
            </>
          )}
          {/* END ------------------------------------------- Closed on */}

          {['prospects', 'active'].includes(item.status) && (
            <>
              <Divider />

              <div className="d-flex align-items-center g-1 flex-wrap">
                <div className="budget-label fs-20 fw-400">Due Date:</div>
                <div className="budget-value fs-20 fw-400">
                  {item?.due_date ? moment(item?.due_date).format('MMM DD, YYYY') : '-'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {item?.status == 'active' || item?.status == 'closed' ? (
        <div className="freelancer-details d-flex align-items-center">
          <BlurredImage
            src={item?.userdata?.user_image || '/images/default_avatar.png'}
            height="47px"
            width="47px"
            allowToUnblur={false}
            type="small"
          />
          <div className="name-w-ratings d-flex align-items-center flex-wrap">
            <div className="fs-18 fw-400 text-capitalize">
              {item?.userdata?.first_name} {item?.userdata?.last_name} <span className="light-text">(Hired)</span>
            </div>
            {item?.status == 'closed' && item?.is_completed == 1 && item?.rate ? (
              <div className="d-flex align-items-center g-1">
                <Stars ratings={item?.rate} />
                <div className="fs-18 fw-400 ratings-italic">{item?.rate?.toFixed(1)}</div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListingFooter;

export const Stars = ({ ratings }: { ratings: number }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {Array(ratings)
        .fill(null)
        .map((_, i) => (
          <StarIcon key={i} />
        ))}
    </div>
  );
};

const Divider = () => {
  return <div className="d-none d-lg-block opacity-50">|</div>;
};
