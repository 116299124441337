/*
 * This component serves a list of invites received
 */

import { Link } from 'react-router-dom';
import { ProposalWrapper, TabContent } from './proposals.styled';
import Loader from 'components/Loader';
import NoDataFound from 'components/ui/NoDataFound';
import BlurredImage from 'components/ui/BlurredImage';
import useProposals from './use-proposals';
import { convertToTitleCase, showFormattedBudget } from 'helpers/utils/misc';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';

const InviteReceived = () => {
  const { proposals, isLoading, isRefetching } = useProposals('invite');

  return (
    <TabContent>
      {isLoading || isRefetching ? (
        <Loader />
      ) : proposals.length > 0 ? (
        proposals.map((item) => (
          <Link
            to={`/offer-details/${item.job_post_id}`}
            key={item.invite_id}
            className="no-hover-effect"
          >
            <ProposalWrapper className="mt-3 d-flex flex-column pointer">
              <div className="fs-18 fw-400 break-word">
                {convertToTitleCase(item.job_title)}
              </div>
              <div className="proposal__details d-flex align-items-center flex-wrap">
                {/* Client details */}

                <div className="d-flex align-items-center">
                  <BlurredImage
                    src={item?.user_image || '/images/default_avatar.png'}
                    className="proposal__client-profile-img"
                    height="2.625rem"
                    width="2.625rem"
                    allowToUnblur={false}
                    type="small"
                  />
                  <div>
                    <div className="proposal__client-detail-label fs-sm fw-400">
                      Sent by:
                    </div>
                    <div className="fs-1rem fw-400 text-capitalize">
                      {item?.first_name} {item?.last_name}
                    </div>
                  </div>
                </div>

                {/* Divider */}
                <div className="divider d-none d-lg-block" />

                {/* Budget */}
                <div className="proposal__budget d-flex width-fit-content align-items-center flex-wrap">
                  <DollarCircleIcon />
                  <div className="proposal__budget-value fs-1rem fw-400 d-flex">
                    {item?.budget?.isProposal
                      ? 'Open To Proposals'
                      : showFormattedBudget(item?.budget)}
                  </div>
                  {item?.budget?.type === 'fixed' &&
                    !item?.budget?.isProposal && (
                      <div className="proposal__budget-grey-label fs-1rem fw-400">
                        Budget
                      </div>
                    )}
                </div>
              </div>
            </ProposalWrapper>
          </Link>
        ))
      ) : (
        <NoDataFound />
      )}
    </TabContent>
  );
};

export default InviteReceived;
