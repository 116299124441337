/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { StepperContainer } from './style';
import classNames from 'classnames';
import { IconType } from 'react-icons/lib';
import { myTheme } from 'styles/theme';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

type Props = {
  steps: {
    number?: number;
    label: string;
    icon?: IconType;
  }[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<Props['activeStep']>>;
};

export const Stepper = ({ steps, activeStep, setActiveStep }: Props) => {
  const isSmallMobile = useMediaQuery({ minWidth: 450 });
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(Number(((activeStep - 1) / (steps.length - 1)) * 100));
  }, [activeStep, steps.length]);

  return (
    <StepperContainer>
      <div className="progress-background" />
      <div className="progress" style={{ width: `${width}%` }} />
      {steps?.length > 0 &&
        steps.map((step, index) => {
          const Icon = step?.icon;
          const isActive = step.number <= activeStep;
          return (
            <OverlayTrigger
              rootCloseDisabled={isSmallMobile}
              key={step?.number ?? index}
              placement="bottom"
              overlay={(props) =>
                isSmallMobile ? (
                  <></>
                ) : (
                  <Tooltip id="stepper-icon-tooltip" {...props}>
                    {step.label}
                  </Tooltip>
                )
              }
            >
              <div
                className="step-wrapper"
                onClick={() => setActiveStep(step.number)}
              >
                <div
                  className={classNames('circle', {
                    active: step.number <= activeStep,
                  })}
                >
                  {step?.icon ? (
                    <Icon
                      color={
                        isActive ? myTheme.colors.yellow : myTheme.colors.black
                      }
                    />
                  ) : (
                    step?.number ?? index
                  )}
                </div>
                <span className="step-label">{step.label}</span>
              </div>
            </OverlayTrigger>
          );
        })}
    </StepperContainer>
  );
};
