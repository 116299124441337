/*
 * This component serves the general details of the job
 */

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { showFormattedBudget } from 'helpers/utils/misc';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import AttachmentPreview from 'components/ui/AttachmentPreview';

const DetailStyledItem = styled.div`
  margin: auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 0.75rem;
  .description-text {
    opacity: 0.5;
  }
  .line-height-2rem {
    line-height: 2rem;
  }
  .job-detail-item-value {
    margin-top: 1.375rem;
  }
`;

const Details = ({ data }: any) => {
  return (
    <Row>
      <Col md="12">
        {/* Job description */}
        <DetailsItem
          title="Project Description"
          atributeValue={
            <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
              <StyledHtmlText
                id={`template_${data.post_template_id}`}
                htmlString={data.job_description}
                needToBeShorten={true}
              />
            </div>
          }
        />
      </Col>

      {/* START ----------------------------------------- Reference links and reference attachments */}
      {(data?.reference_links?.length > 0 || data?.reference_attachments?.length > 0) && (
        <Col lg="12">
          <DetailsItem
            title="Style Samples"
            atributeValue={
              <div>
                <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
                  {data?.reference_links?.length > 0 &&
                    data.reference_links.map((referenceLink) => {
                      return (
                        <div key={referenceLink}>
                          <a className="text-primary" href={referenceLink} target="_blank" rel="noreferrer">
                            {referenceLink}
                          </a>
                        </div>
                      );
                    })}
                </div>
                {data?.reference_attachments?.length > 0 && (
                  <div className="d-flex align-items-center gap-3 flex-wrap mt-3">
                    {data?.reference_attachments?.map((item: string) => (
                      <div key={item} className="d-flex">
                        <AttachmentPreview
                          uploadedFile={item}
                          removable={false}
                          shouldShowFileNameAndExtension={false}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            }
          />
        </Col>
      )}
      {/* END ------------------------------------------- Reference links and reference attachments */}

      <Col md="6">
        <DetailsItem
          title="Budget"
          atributeValue={
            <div className="job-detail-item-value fw-400 fs-18 mt-3">
              {data?.budget?.isProposal ? 'Open To Proposals' : showFormattedBudget(data.budget)}
            </div>
          }
        />

        {/* Payment Structure: Fixed | Hourly | unsure */}
        <DetailsItem
          title="Payment Structure"
          atributeValue={
            <div className="job-detail-item-value fs-18 fw-400 description-text">
              {data.budget?.type == 'fixed' ? 'Project-Based' : data.budget?.type == 'hourly' ? 'Hourly' : 'Unsure'}
            </div>
          }
        />

        {/* START ----------------------------------------- Skills */}
        {data.skills?.length > 0 && (
          <DetailsItem
            title="Skills"
            atributeValue={
              <div className="d-flex align-items-center mt-3 flex-wrap">
                {data.skills?.map((item, index: number) => (
                  <div className="description-text line-height-2rem fw-300 fs-18" key={item.id}>
                    {item.name}
                    {index < data?.skills?.length - 1 ? ',' : ''}&nbsp;
                  </div>
                ))}
              </div>
            }
          />
        )}
        {/* END ------------------------------------------- Skills */}
      </Col>

      {data?.expected_delivery_date && (
        <Col md="6">
          <DetailsItem
            title="Delivery Time"
            atributeValue={
              <div className="job-detail-item-value">
                <div className="d-flex align-items-center g-1 mt-3">
                  <div className="description-text fs-20 fw-400">Duration:</div>
                  <div className="fs-20 fw-400">{data.expected_delivery_date}</div>
                </div>
              </div>
            }
          />
        </Col>
      )}
    </Row>
  );
};

export default Details;

/* This component is a common wrapper for each section in the details of the template
 * This will take as string title and attribute value as React child
 */

const DetailsItem = ({ title, atributeValue }: { title: string; atributeValue: React.ReactNode }) => {
  return (
    <DetailStyledItem className="mb-4">
      <div className="line-height-2rem fs-24 fw-400">{title}</div>
      {atributeValue}
    </DetailStyledItem>
  );
};
