/*
 * This is the proposal card that display received proposal details
 */

import { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
// import toast from 'react-hot-toast';
import { transition } from 'styles/transitions';
import { StyledButton } from 'components/forms/Buttons';
import BlurredImage from 'components/ui/BlurredImage';
// import { acceptProposal } from 'helpers/http/proposals';
import { convertToTitleCase, numberWithCommas } from 'helpers/utils/misc';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 0.5rem;
  padding: 2.25rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  .divider {
    height: 2rem;
    width: 1px;
    background-color: #d9d9d9;
  }
  .light-text {
    opacity: 0.5;
  }
  .budget {
    background-color: ${(props) => props.theme.colors.body2};
    border-radius: 1rem;
    padding: 0.375rem 0.75rem;
  }
  ${() => transition()}
`;

type Props = {
  data: any;
  onSelect: () => void;
  refetch: () => void;
};

const ProposalCard = ({ data, onSelect }: Props) => {
  const [loading] = useState<boolean>(false);

  // const onAcceptProposal = (e: any) => {
  //   /* This function will be called when accept proposal is clicked from the card */

  //   e.stopPropagation();
  //   setLoading(true);
  //   const body = {
  //     status: 'accept',
  //     proposal_id: data?.proposal_id,
  //     approved_budget: {
  //       start_date: moment(new Date()).format('DD-MM-YYYY'),
  //     },
  //   };

  //   // Accept proposal api call
  //   const promise = acceptProposal(body);

  //   toast.promise(promise, {
  //     loading: 'Please wait...',
  //     success: (res) => {
  //       setLoading(false);
  //       /* After accepting this will refetch (Props function) the proposals */
  //       refetch();
  //       return res.message;
  //     },
  //     error: (err) => {
  //       setLoading(false);
  //       return err?.message || 'error';
  //     },
  //   });
  // };

  return (
    <Wrapper className="mt-4 d-flex justify-content-between flex-wrap gap-3">
      <div>
        <div className="fs-24 fw-400">
          {convertToTitleCase(data?.job_title)}
        </div>

        <div className="d-flex align-items-center gap-4 flex-wrap mt-3">
          {/* Freelacer image and name */}
          <div className="d-flex align-items-center gap-2">
            <BlurredImage
              src={data?.user_image || '/images/default_avatar.png'}
              height="58px"
              width="58px"
              allowToUnblur={false}
            />
            <div>
              <div className="light-text fs-1rem fw-400">Proposed by:</div>
              <div className="fs-18 fw-400 text-capitalize">
                {data?.first_name} {data?.last_name}
              </div>
            </div>
          </div>

          <div className="divider" />

          <div className="d-flex align-items-center gap-1 fs-1rem fw-400 budget">
            <DollarCircleIcon />
            <div>
              {numberWithCommas(data?.proposed_budget?.amount, 'USD')}
              {data?.proposed_budget?.type == 'fixed' ? (
                <span className="mx-1 light-text">
                  {data?.proposed_budget?.type == 'fixed'
                    ? 'Cost estimation'
                    : '/hr'}
                </span>
              ) : (
                <span className="light-text">/hr</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Right side - accept proposal button and received on date */}
      <div className="d-flex flex-column justify-content-between gap-3 align-items-md-end">
        <div>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <StyledButton onClick={onSelect}>View Proposal</StyledButton>
          )}
        </div>
        <div>
          Received on:{' '}
          {data?.applied_on
            ? moment(data?.applied_on).format('MMM DD, YYYY')
            : '-'}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProposalCard;
