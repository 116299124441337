import styled from 'styled-components';
export const FooterMain = styled.div``;

export const GradientBorder = styled.div`
  background: #f2b420;
  height: 1px;
  margin: 3rem 0rem 2rem 0rem;
`;

export const FooterWrapper = styled.div<{
  showTopBorder?: boolean;
}>`
  background-color: #fff;
  border-top: ${(props) =>
    props?.showTopBorder == false
      ? 'none'
      : `1px solid ${props.theme.colors.yellow}`};
  padding: 4rem 8.75rem 0rem 8.75rem;
  @media (max-width: 768px) {
    padding: 4rem 1rem 0rem 1rem;
  }
  .content {
    gap: 2rem;
  }
  .section-title {
    font-weight: 700;
    font-size: 1.25rem;
    color: #000;
    margin-bottom: 0.5rem;
    @media (max-width: 680px) {
      font-size: 1rem;
    }
  }
  .nav-link {
    font-weight: 400;
    font-size: 1rem;
    color: #000;
    @media (max-width: 680px) {
      font-size: 0.8rem;
    }
    /* :hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.02);
    } */
  }
  .go-to-top-arrow {
    border-radius: 100%;
    &:hover {
      transform: translateY(-5px) scale(1.025);
    }
  }
  .go-to-top-text {
    writing-mode: tb-rl;
    margin: auto;
    margin-top: 0.75rem;
    line-height: 2.5rem;
  }
`;

export const EmailTextBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cbcbca;
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  .email-input {
    border: none;
    outline: none;
    background-color: transparent;
  }
`;

export const BottomLinks = styled.div`
  padding: 0rem 0rem 2rem 0rem;
  .bottom-link {
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    opacity: 0.7;
    font-family: ${({ theme }) => theme.font.primary};
    @media (max-width: 680px) {
      font-size: 0.6rem;
    }
  }
`;
