/*
 * This is component will display the edit and delete button
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { transition } from 'styles/transitions';
import DeletePropmpt from 'components/ui/DeletePropmpt';
import { manageTemplate } from 'helpers/http/templates';
import { ReactComponent as EditBlueIcon } from 'assets/icons/edit-blue.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-orange.svg';
import { goBack } from 'helpers/utils/goBack';

const Wrapper = styled.div`
  .edit-delete-btn {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.04);
    border-radius: 60px;
    ${() => transition()}
  }
  .delete {
    height: 52px;
    width: 52px;
  }
`;

const QuickOptions = ({ id }: { id: string }) => {
  const navigate = useNavigate();

  const [showDeletePrompt, setShowDeletePrmopt] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleDeletePropmpt = () => {
    /* This function will toggle delete prompt
    when delete button is clicked or that prompt is closed (Are you sure...delete?) */
    setShowDeletePrmopt(!showDeletePrompt);
  };

  const onDelete = () => {
    // Delete template api call

    setLoading(true);

    const promise = manageTemplate({
      action: 'delete_template',
      post_template_id: id,
    });

    toast.promise(promise, {
      loading: 'Please wait...',
      success: (res) => {
        /* After successfully deletion it will close the prompt and navigate the user one step back
        as now this job does not exist */
        setLoading(false);
        toggleDeletePropmpt();
        goBack(navigate);
        return res.response;
      },
      error: (err) => {
        setLoading(false);
        return err?.response || 'error';
      },
    });
  };

  const onEdit = () => {
    // This will take the client to edit tamplate page with the selected template id
    navigate(`/edit/template/${id}`);
  };

  return (
    <Wrapper className="d-flex align-items-center my-4 justify-content-center justify-content-md-end">
      {/* Edit Template button */}
      <div
        onClick={onEdit}
        className="edit-delete-btn d-flex align-items-center p-3 mx-3 pointer"
      >
        <EditBlueIcon stroke="#0067FF" fill="#0067FF" /> &nbsp;&nbsp;
        <span className="fs-18 fw-400">Edit Template</span>
      </div>

      {/* Delete template button */}
      <div
        onClick={toggleDeletePropmpt}
        className="edit-delete-btn delete pointer d-flex justify-content-center align-items-center"
      >
        <TrashIcon />
      </div>

      {/* Delete prompt */}
      <DeletePropmpt
        show={showDeletePrompt}
        toggle={toggleDeletePropmpt}
        onDelete={onDelete}
        loading={loading}
        text="Are you sure you want to delete this template? This cannot be undone."
      />
    </Wrapper>
  );
};

export default QuickOptions;
