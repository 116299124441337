import FreelancerProfileBanner from '../Partials/FreelancerProfileBanner';
import { useQueryData } from 'helpers/hooks/useQueryData';
import { useParams } from 'react-router-dom';
import FreelancerJobs from '../Partials/FreelancerJobs';
import FreelancerOtherDetails from '../Partials/FreelancerOtherDetails';
import { TViewFreelancerProfilePathParams } from 'helpers/types/pathParams.type';
import { queryKeys } from 'helpers/const/queryKeys';
import { IFreelancerDetails } from 'helpers/types/freelancer.type';

export const Profile = () => {
  const { freelancerId } = useParams<TViewFreelancerProfilePathParams>();
  const { data } = useQueryData<IFreelancerDetails>(
    queryKeys.getFreelancerDetails(freelancerId)
  );

  return (
    <div>
      <FreelancerProfileBanner data={data} />
      {/* Freelancer's Jobs */}

      {data?.activeJobsClient?.length > 0 && (
        <FreelancerJobs
          jobs={data?.activeJobsClient}
          freelancerName={data?.first_name + ' ' + data?.last_name}
        />
      )}

      {/* Freelancer other details */}
      <FreelancerOtherDetails data={data} />
    </div>
  );
};
