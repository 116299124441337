import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spinner, Row, Col, Form } from 'react-bootstrap';
import ProfileBanner from './partials/ProfileBanner';
import * as C from './client-profile.styled';
import AccountClosureModal from 'components/profile/AccountClosureModal';
import BackButton from 'components/ui/BackButton';
import Loader from 'components/Loader';
import { StyledButton } from 'components/forms/Buttons';
import PaymentInfo from './partials/PaymentInfo';
import BankAccounts from './partials/BankAccounts';
import useClientProfile from 'controllers/useClientProfile';
import { useAuth } from 'helpers/contexts/auth-context';
import {
  accountClosure,
  cancelAccountClosure,
  editUser,
} from 'helpers/http/auth';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import ProfileDetailSection from 'pages/freelancer-profile-settings/partials/ProfileDetailSection';
import { getFreelancerDetails } from 'helpers/http/freelancer';
import { useQuery } from 'react-query';
import AboutUsEditModal from 'pages/freelancer-profile-settings/edit-modals/AboutUsEditModal';
import NewPaymentInfoModal from './partials/NewPaymentInfoModal';
import Tooltip from 'components/ui/Tooltip';
import { goBack } from 'helpers/utils/goBack';
import ClientProfileTabs from './ClientProfileTabs';
import { StyledFormGroup } from './partials/edit-info/info-edit.styled';
import { myTheme } from 'styles/theme';
import { ReactComponent as EditIcon } from 'assets/icons/edit-blue.svg';
import EmailEditModal from 'components/profile/EmailEditModal';
import PhoneInputWrapper from 'components/styled/PhoneInputWrapper';
import PhoneNumberInput from 'components/forms/phone-number-input';
import { Ratings } from 'components/Ratings';
import CountryDropdown from 'components/forms/country-dropdown/CountryDropdown';
import StateDropdown from 'components/forms/state-picker/StatePicker';
import { TEditUserRequest } from 'helpers/types/apiRequestResponse';
import { onlyCharacters } from 'helpers/validation/common';
import { getYupErrors } from 'helpers/utils/misc';
import ErrorMessage from 'components/ui/ErrorMessage';
import { clientAccountProfileValidation } from 'helpers/validation/clientAccountProfileValidation';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray-18.svg';
import AsyncSelect from 'react-select/async';
import { MultiSelectCustomStyle } from './partials/edit-info/multiSelectCustomStyle';
import { CONSTANTS } from 'helpers/const/constants';
import { TClientProfilePathParams } from 'helpers/types/pathParams.type';
import { IClientDetails } from 'helpers/types/client.type';
import AccountClosureDescriptionModal from 'components/profile/AccountClosureDescriptionModal';
import { queryKeys } from 'helpers/const/queryKeys';
import { useRefetch } from 'helpers/hooks/useQueryData';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const myWindow: Window = window;

type TInputFieldLoading =
  | 'phone number'
  | 'first name'
  | 'last name'
  | 'country'
  | 'state/region'
  | 'message email notification'
  | '';

type TFormData = Pick<
  IClientDetails,
  | 'first_name'
  | 'last_name'
  | 'location'
  | 'formatted_phonenumber'
  | 'phone_number'
  | 'new_message_email_notification'
>;

const singleSelectProps = {
  closeMenuOnSelect: true,
  isMulti: false,
  styles: MultiSelectCustomStyle,
};

const ClientProfile = () => {
  const { refetch: cardExpirationRefetch } = useRefetch(
    queryKeys.checkCardExpiration
  );
  const { refetch: paymentMethodRefetch } = useRefetch(
    queryKeys.clientHasPaymentMethod
  );

  /* This will fetch the client profile details */
  const [loading, setLoading] = useState<boolean>(false);
  const [showClosureModal, setShowClosureModal] = useState<boolean>(false);
  const [showClosureDescriptionModal, setShowClosureDescriptionModal] =
    useState<boolean>(false);
  const [showEditModalState, setEditModdalState] = useState<{
    modal: string;
  } | null>(null);
  const [isNewPayInfoModal, setIsNewPayInfoModal] = useState(false);
  const [showEditEmailModal, setShowEditEmailModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<TFormData>({
    first_name: '',
    last_name: '',
    location: null,
    formatted_phonenumber: '',
    phone_number: '',
    new_message_email_notification: 0,
  });
  const [inputFieldLoading, setInputFieldLoading] =
    useState<TInputFieldLoading>('');
  const [errors, setErrors] = useState<TFormData>(undefined);

  const { clientId } = useParams<TClientProfilePathParams>();

  const { profileData, isLoading, refetchData, isRefetching } =
    useClientProfile();

  const { setUser, user } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const toggleEditModal = () => {
    // This function will toggle the Add / Edit modal
    setShowEditEmailModal(!showEditEmailModal);
  };

  const newMessageEmailOptions = async () =>
    await CONSTANTS.NEW_MESSAGE_EMAIL_OPTIONS;

  useEffect(() => {
    if (user) {
      myWindow.intercomSettings = {
        appId: INTERCOM_APP_ID,
        email: user?.u_email_id,
        user_id: user?.user_id,
        name: user?.first_name + ' ' + user?.last_name,
        avatar: {
          type: 'avatar',
          image_url: user.user_image,
        },
      };
    }
  }, [user]);

  const onBack = () => {
    location.state?.fromRegister
      ? navigate('/')
      : goBack(
          navigate,
          user.user_type === 'freelancer' ? '/dashboard' : '/client/dashboard'
        );
  };

  useEffect(() => {
    if (profileData) {
      setUser(profileData);
      setFormData((prev) => ({
        ...prev,
        first_name: profileData?.first_name,
        last_name: profileData?.last_name,
        location: profileData?.location,
        formatted_phonenumber: profileData?.formatted_phonenumber,
        phone_number: profileData?.phone_number,
        new_message_email_notification:
          CONSTANTS.NEW_MESSAGE_EMAIL_OPTIONS.find(
            (dt) => dt.value === profileData?.new_message_email_notification
          )?.value,
      }));
    }
  }, [profileData, setUser]);

  useEffect(() => {
    /* TODO: Here the #element_id was not working proeprly, I tried lot for that but taking too much time
     * so for now I have added this thing, and working perfectly, if this is not correct will see in e2e testing
     */
    if (!isLoading) {
      const currentLocation = window.location.href;
      const hasCommentAnchor = currentLocation.includes('/#');
      if (hasCommentAnchor) {
        const anchorCommentId = `${currentLocation.substring(
          currentLocation.indexOf('#') + 1
        )}`;
        const anchorComment = document.getElementById(anchorCommentId);
        if (anchorComment) {
          anchorComment.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [isLoading]);

  const toggleClosureModal = () => {
    setShowClosureModal(!showClosureModal);
  };

  const toggleClosureDescriptionModal = () => {
    setShowClosureModal(!showClosureModal);
    setShowClosureDescriptionModal(!showClosureDescriptionModal);
  };

  const toggleClosureDescriptionEditModal = () => {
    setShowClosureDescriptionModal(!showClosureDescriptionModal);
  };

  const onConfirmAccountDeletion = (message) => {
    const body = {
      message: message,
    };
    const promise = accountClosure(body);
    setLoading(true);
    toast.promise(promise, {
      loading: 'Please wait...',
      success: (res) => {
        setLoading(false);
        toggleClosureDescriptionEditModal();
        refetchData();
        return res.message;
      },
      error: (err) => {
        setLoading(false);
        toggleClosureDescriptionEditModal();
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const handleCancelDeletionRequest = () => {
    const promise = cancelAccountClosure();
    setLoading(true);
    toast.promise(promise, {
      loading: 'Please wait...',
      success: (res) => {
        setLoading(false);
        refetchData();
        return res.message;
      },
      error: (err) => {
        setLoading(false);
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const { data, refetch } = useQuery(
    ['clientdetails', user?.user_id],
    () =>
      getFreelancerDetails(user?.user_id).catch((err) => {
        throw err;
      }),
    { enabled: !!user?.user_id }
  );

  const onUpdate = () => {
    /*
     * This funciton will be called after anything is edited / added
     * This will close the modal and refetch the profile
     */
    setEditModdalState(null);
    refetch();
  };

  const firstPaymentInfoModalHandler = () => {
    const { account = [], carddata = [] } = profileData;
    if (account.length === 0 && carddata.length === 0)
      setIsNewPayInfoModal(true);
  };

  const handleEditUser = (
    loadingFieldName: TInputFieldLoading,
    data: TEditUserRequest
  ) => {
    clientAccountProfileValidation
      .validate(data, { context: { exist: false } })
      .then(() => {
        setErrors(undefined);
        setInputFieldLoading(loadingFieldName);
        const promise = editUser(data);
        toast.promise(promise, {
          loading: `Updating ${loadingFieldName}`,
          success: (res) => {
            refetchData();
            setInputFieldLoading('');
            return res.message;
          },
          error: (err) => {
            refetchData();
            setInputFieldLoading('');
            return err.message ?? err.toString();
          },
        });
      })
      .catch((error) => {
        const errors = getYupErrors({ inner: [error] });
        setErrors({ ...errors });
      });
  };

  useEffect(() => {
    if (!['settings', 'profile', 'ratings', 'payments'].includes(clientId))
      navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const SaveButtonUI = (
    loadingKey: typeof inputFieldLoading,
    dataKey: keyof typeof formData,
    top?: number,
    additionalPayload: TEditUserRequest = {}
  ) => {
    return (
      <div
        className="position-absolute end-0 me-4 pointer"
        style={top ? { top: `${top}%` } : { top: '50%' }}
        onClick={() => {
          inputFieldLoading !== loadingKey &&
            handleEditUser(loadingKey, {
              [dataKey]: formData[dataKey],
              ...additionalPayload,
            });
        }}
      >
        {profileData?.[dataKey] !== formData[dataKey] && (
          <div className="fs-1rem fw-400">
            {inputFieldLoading === loadingKey ? (
              <Spinner animation="border" size="sm" className="me-1" />
            ) : (
              'Save'
            )}
          </div>
        )}
      </div>
    );
  };

  const refetchAfterPaymentDetailsAdded = () => {
    refetchData();
    cardExpirationRefetch();
    paymentMethodRefetch();
  };

  return (
    <C.ClientProfileWrapper>
      <ClientProfileTabs currentTab={clientId} />
      <C.ClientContent>
        <C.Wrapper className="content-hfill mt-2">
          <BackButton onBack={onBack}>
            {isRefetching ? (
              <Spinner animation="border" size="sm" className="ms-1" />
            ) : null}
          </BackButton>

          {isLoading && <Loader />}

          {!isLoading && (
            <>
              {clientId === 'profile' && (
                <>
                  {/* Profile details banner */}
                  {profileData && (
                    <ProfileBanner data={profileData} refetch={refetchData} />
                  )}

                  <Row className="my-4">
                    {/* About Me */}
                    <Col lg="12" className="mb-5">
                      <ProfileDetailSection
                        onEdit={() => setEditModdalState({ modal: 'about_me' })}
                        title={
                          <div className="d-flex align-items-center gap-1">
                            {'About Me'}
                            <Tooltip>
                              The "About Me" section is the primary place to
                              introduce yourself to freelancers. You can share
                              whatever you like - your work history, your style,
                              your special preferences... whatever you think
                              would be helpful for freelancers to know!
                            </Tooltip>
                          </div>
                        }
                        details={
                          <div>
                            {data?.data?.about_me && (
                              <div className="about-me fs-18 fw-400">
                                <StyledHtmlText
                                  htmlString={data?.data?.about_me}
                                  needToBeShorten={true}
                                  minLines={5}
                                  id="about-me"
                                />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              {clientId === 'ratings' && (
                <Ratings reviews={profileData?.review} />
              )}
              {clientId === 'payments' && (
                <>
                  {/* START ----------------------------------------- Payment details */}
                  <div className="title fs-24 fw-400 mt-4 mb-3">
                    Payment Details
                  </div>
                  <Row>
                    {/* Payment info (Saved cards) */}
                    <Col
                      md="12"
                      lg={6}
                      className={
                        profileData?.location?.country_short_name === 'US'
                          ? 'mb-4'
                          : 'm-auto'
                      }
                    >
                      <PaymentInfo
                        onNewAdded={firstPaymentInfoModalHandler}
                        paymentData={profileData?.carddata}
                        refetch={refetchAfterPaymentDetailsAdded}
                      />
                    </Col>
                    {profileData?.location?.country_short_name === 'US' && (
                      <Col md="12" lg={6} className="mb-4" id="bank-accounts">
                        <BankAccounts
                          onNewAdded={firstPaymentInfoModalHandler}
                          paymentData={profileData?.account}
                          refetch={refetchAfterPaymentDetailsAdded}
                          userCountry={
                            profileData?.location?.country_short_name
                          }
                        />
                      </Col>
                    )}
                  </Row>
                  {/* END ------------------------------------------- Payment details */}
                </>
              )}
              {clientId === 'settings' && (
                <>
                  {/* Reset password */}
                  <div className="title fs-24 fw-400 mt-4 mb-2">
                    Account Details
                  </div>
                  <C.ContentBox className="pb-5">
                    {/* START ----------------------------------------- First name and last name */}
                    <Row>
                      <Col md="12" lg="6">
                        <StyledFormGroup className="position-relative">
                          <div className="fs-sm fw-400">
                            First Name<span className="mandatory">&nbsp;*</span>
                          </div>
                          <Form.Control
                            placeholder="Enter your first name"
                            className="form-input"
                            value={formData?.first_name}
                            maxLength={35}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                first_name: onlyCharacters(e.target.value),
                              }))
                            }
                          />
                          {SaveButtonUI('first name', 'first_name')}
                        </StyledFormGroup>
                        {errors?.first_name && (
                          <ErrorMessage message={errors.first_name} />
                        )}
                      </Col>
                      <Col md="12" lg="6">
                        <StyledFormGroup className="position-relative">
                          <div className="fs-sm fw-400">
                            Last Name<span className="mandatory">&nbsp;*</span>
                          </div>
                          <Form.Control
                            placeholder="Enter your last name"
                            className="form-input"
                            value={formData?.last_name}
                            maxLength={35}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                last_name: onlyCharacters(e.target.value),
                              }))
                            }
                          />
                          {SaveButtonUI('last name', 'last_name')}
                        </StyledFormGroup>
                        {errors?.last_name && (
                          <ErrorMessage message={errors.last_name} />
                        )}
                      </Col>
                    </Row>
                    {/* END ------------------------------------------- First name and last name */}

                    {/* START ----------------------------------------- Country */}
                    <Row>
                      <Col md={12} lg={4}>
                        <StyledFormGroup className="position-relative">
                          <div className="fs-sm fw-400 mb-1">
                            Country<span className="mandatory">&nbsp;*</span>
                          </div>
                          <CountryDropdown
                            selectedCountry={formData?.location}
                            onSelectCountry={(item) => {
                              const newLocation = {
                                ...item,
                              };
                              setFormData((prev) => ({
                                ...prev,
                                location: newLocation,
                              }));
                              handleEditUser('country', {
                                location: newLocation,
                              });
                            }}
                          />
                          {errors?.location?.country_name && (
                            <ErrorMessage
                              message={errors?.location?.country_name}
                            />
                          )}
                        </StyledFormGroup>
                      </Col>
                      {/* END ------------------------------------------- Country */}

                      {/* START ----------------------------------------- State / region */}
                      {!CONSTANTS.COUNTRIES_SHORT_NAME_WITHOUT_STATE.includes(
                        formData?.location?.country_short_name
                      ) && (
                        <Col md={12} lg={4}>
                          <StyledFormGroup>
                            <div className="fs-sm fw-400 mb-1">
                              State/Region
                              <span className="mandatory">&nbsp;*</span>
                            </div>
                            <StateDropdown
                              countryCode={
                                formData?.location?.country_short_name
                              }
                              onSelectState={(item) => {
                                const newLocation = {
                                  ...formData.location,
                                  state: item,
                                };
                                setFormData((prev) => ({
                                  ...prev,
                                  location: newLocation,
                                }));
                                handleEditUser('state/region', {
                                  location: newLocation,
                                });
                              }}
                              selectedState={
                                formData?.location?.state
                                  ? {
                                      label: formData?.location?.state,
                                      value: formData?.location?.state,
                                    }
                                  : null
                              }
                            />
                            {errors?.location?.state && (
                              <ErrorMessage message={errors.location.state} />
                            )}
                          </StyledFormGroup>
                        </Col>
                      )}
                      {/* END ------------------------------------------- State / region */}

                      {/* START ----------------------------------------- Phone number */}
                      <Col md={12} lg={4}>
                        <StyledFormGroup className="form-group-wapper d-flex flex-column">
                          <div className="fs-sm fw-400">
                            Phone<span className="mandatory">&nbsp;*</span>
                          </div>
                          <PhoneInputWrapper className="phone-input-wrapper flex-1 w-100">
                            <PhoneNumberInput
                              initialValue={profileData?.formatted_phonenumber}
                              onChange={(phone, formattedValue) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  phone_number: phone,
                                  formatted_phonenumber: formattedValue,
                                }));
                              }}
                            />
                            {SaveButtonUI(
                              'phone number',
                              'formatted_phonenumber',
                              30,
                              {
                                phone_number: formData.phone_number,
                              }
                            )}
                          </PhoneInputWrapper>
                        </StyledFormGroup>
                        {errors?.formatted_phonenumber && (
                          <ErrorMessage
                            message={errors.formatted_phonenumber}
                          />
                        )}
                      </Col>
                      {/* END ------------------------------------------- Phone number */}
                    </Row>

                    <Row>
                      {/* START ----------------------------------------- Email */}
                      <Col md={12} lg={6}>
                        <StyledFormGroup
                          className="form-group-wapper d-flex align-items-center"
                          style={{ gap: '4rem' }}
                        >
                          <div className="email-input-wrapper flex-1">
                            <div className="fs-sm fw-400">
                              Email
                              <span className="mandatory">&nbsp;*</span>
                            </div>
                            <Form.Control
                              placeholder="Enter your email"
                              className="form-input email-input"
                              value={profileData?.u_email_id}
                              disabled={true}
                            />
                            <div
                              className="edit-button d-flex align-items-center gap-2 pointer top-50"
                              onClick={toggleEditModal}
                            >
                              <EditIcon
                                stroke={myTheme.colors.primary}
                                fill={myTheme.colors.primary}
                              />
                              <div className="fs-1rem fw-400">Edit</div>
                            </div>
                          </div>
                        </StyledFormGroup>
                      </Col>
                      {/* END ------------------------------------------- Email */}

                      {/* START ----------------------------------------- Password */}
                      <Col md={12} lg={6}>
                        <StyledFormGroup
                          className="form-group-wapper d-flex align-items-center"
                          style={{ gap: '1.7rem' }}
                        >
                          <div className="email-input-wrapper flex-1">
                            <div className="fs-sm fw-400">
                              Password
                              <span className="mandatory">&nbsp;*</span>
                            </div>
                            <Form.Control
                              placeholder="Enter your email"
                              className="form-input email-input"
                              value={'***'}
                              disabled={true}
                            />
                            <div
                              className="edit-button d-flex align-items-center gap-2 pointer top-50"
                              onClick={() => navigate('/change-password')}
                            >
                              <EditIcon
                                stroke={myTheme.colors.primary}
                                fill={myTheme.colors.primary}
                              />
                              <div className="fs-1rem fw-400">Reset</div>
                            </div>
                          </div>
                        </StyledFormGroup>
                      </Col>
                      {/* END ------------------------------------------- Password */}
                    </Row>
                    <Row>
                      {/* START ----------------------------------------- Unread Messages Notifications Settings */}
                      <Col>
                        <StyledFormGroup>
                          <div className="d-flex align-items-center fs-sm fw-400 mb-1">
                            Unread Messages Notifications Settings
                            <Tooltip
                              customTrigger={<InfoIcon className="ms-1" />}
                              className="d-inline-block"
                            >
                              Clients are notified by email when a new message
                              is received on ZMZ. You can set here how often you
                              would like to receive these emails.
                            </Tooltip>
                          </div>
                          <AsyncSelect
                            {...singleSelectProps}
                            placeholder="New Message Email"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            loadOptions={newMessageEmailOptions as any}
                            onChange={(options) => {
                              const value = (
                                options as (typeof CONSTANTS.NEW_MESSAGE_EMAIL_OPTIONS)[0]
                              ).value;
                              setFormData((prev) => ({
                                ...prev,
                                new_message_email_notification: Number(value),
                              }));
                              handleEditUser('message email notification', {
                                new_message_email_notification: Number(value),
                              });
                            }}
                            value={CONSTANTS.NEW_MESSAGE_EMAIL_OPTIONS.find(
                              (x) =>
                                x.value ===
                                formData?.new_message_email_notification
                            )}
                            defaultOptions={true}
                          />
                        </StyledFormGroup>
                      </Col>
                      {/* END ------------------------------------------- Unread Messages Notifications Settings */}
                    </Row>
                  </C.ContentBox>

                  {/* START ----------------------------------------- Account status */}
                  <Row className="mt-5">
                    <div className="title fs-24 fw-400 mb-2">
                      Account Status
                    </div>
                    <div>
                      {profileData?.deletion_requested == 1 ? (
                        <StyledButton
                          className="fs-18 fw-400 pointer"
                          variant="primary"
                          onClick={handleCancelDeletionRequest}
                          disabled={loading}
                        >
                          Cancel Account Closure Request
                        </StyledButton>
                      ) : (
                        <StyledButton
                          className="close-account-btn fs-18 fw-400 pointer"
                          onClick={toggleClosureModal}
                        >
                          Close My ZehMizeh Account
                        </StyledButton>
                      )}
                    </div>
                  </Row>
                  {/* END ------------------------------------------- Account status */}
                </>
              )}
            </>
          )}
        </C.Wrapper>

        <AccountClosureModal
          show={showClosureModal}
          toggle={toggleClosureModal}
          clousureToggle={toggleClosureDescriptionModal}
          loading={loading}
        />

        <AccountClosureDescriptionModal
          show={showClosureDescriptionModal}
          toggle={toggleClosureDescriptionModal}
          onConfirm={onConfirmAccountDeletion}
          loading={loading}
        />

        <AboutUsEditModal
          show={showEditModalState?.modal == 'about_me'}
          data={{
            is_agency: !!data?.data?.is_agency,
            aboutMe: data?.data?.about_me,
            portfolioLink: data?.data?.portfolio_link,
          }}
          onClose={() => setEditModdalState(null)}
          onUpdate={onUpdate}
          user_type={user?.user_type}
        />

        <NewPaymentInfoModal
          show={isNewPayInfoModal}
          onClose={() => setIsNewPayInfoModal(false)}
        />

        <EmailEditModal
          show={showEditEmailModal}
          existingEmail={profileData?.u_email_id}
          onClose={toggleEditModal}
          onUpdateEmail={() => refetchData()}
        />
      </C.ClientContent>
    </C.ClientProfileWrapper>
  );
};

export default ClientProfile;
