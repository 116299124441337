import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Loader from 'components/Loader';
import AddPortfolioModal from 'components/portfolio/AddPortfolioModal';
import NoPortfolioView from 'components/portfolio/NoPortfolioView';
import PortfolioListItem from 'components/portfolio/PortfolioListItem';
import { transition } from 'styles/transitions';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-yellow.svg';
import { useQueryData, useRefetch } from 'helpers/hooks/useQueryData';
import { useParams } from 'react-router-dom';
import { TViewFreelancerProfilePathParams } from 'helpers/types/pathParams.type';
import { queryKeys } from 'helpers/const/queryKeys';
import { StyledButton } from 'components/forms/Buttons';

const AddNewBlock = styled.div`
  background: ${(props) => props.theme.colors.white};
  height: 100%;
  color: ${(props) => props.theme.colors.gray8};
  border-radius: 0.5rem;
  border: 1.5px dashed ${(props) => props.theme.colors.gray8};
  ${() => transition()}
  min-height: 150px;
`;

export const Portfolio = ({ allowEdit = true }: { allowEdit?: boolean }) => {
  const { freelancerId } = useParams<TViewFreelancerProfilePathParams>();
  const [showAddPortfolioModal, setShowAddPortfolioModal] = useState<boolean>(false);

  const {
    data: portfolioData,
    isLoading,
    isRefetching,
  } = useQueryData<Record<string, unknown>[]>(queryKeys.getFreelancerPortfolio(freelancerId)) || {};
  const { refetch } = useRefetch(queryKeys.getFreelancerPortfolio(freelancerId));
  const initialCount = 6;
  const [count, setCount] = useState(initialCount);

  const toggleAddPortfolioModal = () => {
    setShowAddPortfolioModal(!showAddPortfolioModal);
  };

  const toggleCount = () => {
    setCount(count === initialCount ? portfolioData?.length : initialCount);
  };

  return (
    <div>
      <div className="title fs-28 fw-400">Portfolio {portfolioData?.length === 0 && '(Not Added)'}</div>
      {isLoading && <Loader />}

      {allowEdit && (
        <AddPortfolioModal show={showAddPortfolioModal} onClose={toggleAddPortfolioModal} onUpdate={refetch} />
      )}

      {portfolioData?.length === 0 && <NoPortfolioView allowEdit={allowEdit} onAddProject={toggleAddPortfolioModal} />}

      <Row className={portfolioData?.length > 0 ? 'mt-4' : ''}>
        {allowEdit && !isLoading && portfolioData?.length > 0 && (
          <Col md={4} xs={12} className="mb-4">
            <AddNewBlock
              className="d-flex flex-column align-items-center justify-content-center fs-1rem fw-400 pointer"
              onClick={toggleAddPortfolioModal}
            >
              <PlusIcon className="mb-3" />
              Add New Portfolio Album
            </AddNewBlock>
          </Col>
        )}
        {!isLoading &&
          portfolioData?.slice(0, count).map((item: any) => (
            <Col md={4} xs={12} key={item.portfolio_id} className="mb-4">
              <PortfolioListItem data={item} onUpdate={refetch} allowEdit={allowEdit} />
            </Col>
          ))}
      </Row>
      {portfolioData?.length > initialCount && (
        <div className="d-flex align-items-center justify-content-center mt-2">
          <StyledButton variant="outline-dark" onClick={toggleCount}>
            See {count !== initialCount ? 'Less' : 'More'}
          </StyledButton>
        </div>
      )}
    </div>
  );
};
