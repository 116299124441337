import { useQuery } from 'react-query';
import { getReceivedProposals } from 'helpers/http/client';

/* This custom hook will fetch the received proposals for the logged in client */
function useProposals({
  page,
  limit,
  keyword,
}: {
  page: number;
  limit: number;
  keyword?: string;
}) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-received-proposals', page],
    () => getReceivedProposals({ page, limit, keyword }),
    {
      keepPreviousData: true,
    }
  );
  return {
    proposals: data?.data || [],
    totalResults: data?.total || 0,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useProposals;
