/*
 * This component serves the stats the logged in freelancer
 */

import { Spinner, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as StarIcon } from 'assets/icons/starYellow.svg';
import { numberWithCommas } from 'helpers/utils/misc';
import { breakpoints } from 'helpers/hooks/useResponsive';

const StatBlock = styled.div`
  height: 7.75rem;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.colors.white};
  padding: 1.5rem;
  .stat-label {
    opacity: 0.6;
    line-height: 21.6px;
  }
  .stat-value {
    line-height: 38.4px;
    letter-spacing: 0.03em;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 2rem;
    .stat-value {
      font-size: 1.25rem;
    }
  }
`;

type Props = {
  dashboardStats: any;
  isLoading: boolean;
};

const DashboardStats = ({ dashboardStats, isLoading }: Props) => {
  return (
    <Row className="mt-3 d-flex justify-content-center">
      <Col lg={4} md={6} xs={6}>
        <StatBlock className="d-flex flex-column justify-content-between">
          <div className="stat-label fs-18 fw-400">Total Projects Done</div>
          {!isLoading ? (
            <div className="stat-value fs-32 fw-700">
              {numberWithCommas(dashboardStats?.donejob)}
            </div>
          ) : (
            <div className="mt-3">
              <Spinner animation="border" />
            </div>
          )}
        </StatBlock>
      </Col>
      <Col lg={4} md={6} xs={6}>
        <StatBlock className="d-flex flex-column justify-content-between">
          <div className="stat-label fs-18 fw-400">Total Earnings</div>
          {!isLoading ? (
            <div className="stat-value fs-32 fw-700">
              {numberWithCommas(dashboardStats?.totalEarnings, 'USD')}
            </div>
          ) : (
            <div className="mt-3">
              <Spinner animation="border" />
            </div>
          )}
        </StatBlock>
      </Col>
      <Col>
        <StatBlock className="d-flex flex-column justify-content-between">
          <div className="stat-label fs-18 fw-400">Ratings</div>
          {!isLoading ? (
            <div className="stat-value d-flex align-items-center g-1">
              <StarIcon />
              <div className="fs-32 fw-700">
                {dashboardStats?.ratings?.average
                  ? dashboardStats?.ratings?.average?.toFixed(1)
                  : 0}
              </div>
              <div className="fs-18 fw-400">
                (
                {dashboardStats?.ratings?.count
                  ? numberWithCommas(dashboardStats?.ratings?.count)
                  : 0}{' '}
                reviews)
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <Spinner animation="border" />
            </div>
          )}
        </StatBlock>
      </Col>
    </Row>
  );
};

export default DashboardStats;
