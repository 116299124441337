import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Wrapper = styled(Container)`
  max-width: 970px;
`;

export const BannerWrapper = styled.div`
  position: relative;
  padding: 2.25rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  gap: 1.25rem;
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  .label {
    opacity: 0.5;
  }
  .divider {
    background: #d1d1d1;
    height: 22px;
    width: 1px;
  }
`;
